function get_Cookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function extractHostname(url) {
  var hostname;
  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }
  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];
  return hostname;
}

export function getUtm() {
  var referrer = "";
  var utm_data = "";
  let _shopify_sa_p = document.cookie.split("; ").find((row) => row.startsWith("_shopify_sa_p="));
  if (_shopify_sa_p && _shopify_sa_p !== "") {
    _shopify_sa_p = _shopify_sa_p.split("=")[1];
  }

  if (typeof _shopify_sa_p !== "undefined" && _shopify_sa_p.trim() !== "") {
    var url_val_1 = decodeURIComponent(_shopify_sa_p).split("&");
    var utm_source = "";
    var utm_campaign = "";
    url_val_1.forEach(function (v, i) {
      var url_val_2 = v.split("=");
      if (url_val_2[0] === "utm_source") {
        utm_source = url_val_2[1];
      } else if (url_val_2[0] === "utm_campaign") {
        utm_campaign = url_val_2[1];
      }
    });

    utm_data = utm_source + (utm_source !== "" ? " || " : "") + utm_campaign;
    if (utm_data === "") {
      utm_data = _shopify_sa_p;
    }
  }

  referrer = get_Cookie("nh_referrer"); //document.referrer;
  referrer = referrer === "" ? "" : "referrer=" + extractHostname(referrer);
  var utm_reff = utm_data;
  if (utm_data !== "" && referrer !== "") {
    utm_reff = utm_data + " || " + referrer;
  } else if (utm_data === "" && referrer !== "") {
    utm_reff = referrer;
  }
  return { utm_campaign, utm_reff, utm_source, utm_data };
}
