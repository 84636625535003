import React, { useCallback, useState } from "react";
import { useCookies } from "react-cookie";

import { makePublicURL } from "../helpers";
import { WEBSITE_URL, NEW_WEBSITE_URL } from "../helpers/constants";

export default function Header() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [cookies, setCookie] = useCookies();

  const headerRef = useCallback(
    (node) => {
      if (node) {
        if (window.matchMedia("(max-width: 767px)").matches) {
          setTimeout(() => {
            let absoluteBottomPosition = node.getBoundingClientRect().bottom;
            window.scrollBy({
              top: absoluteBottomPosition,
              left: 0,
              behavior: "smooth",
            });
          }, 1000);
        }
      }
    },
    [imageLoaded]
  );
  return (
    <div
      className="logo-wrap"
      ref={headerRef}
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 60 }}
    >
      <a href={cookies.homeUrl == "new.nathabit.in" ? NEW_WEBSITE_URL : WEBSITE_URL} className="logo">
      {/* <a href={cookies.website_type === "B" ? NEW_WEBSITE_URL : WEBSITE_URL} className="logo"> */}
        {/* <picture> */}
        {/* <source media="(max-width:767px)" srcset={makePublicURL("img/logo-mobile.webp")} style={{ width: 20 }} /> */}
        <img
          src={makePublicURL("img/logo.png")}
          srcSet={makePublicURL("img/logo-mobile.webp")}
          alt="Nathabit"
          onLoad={() => setImageLoaded(true)}
          className="header-image"
          // height="30"
        />
        {/* </picture> */}
      </a>
    </div>
  );
}
