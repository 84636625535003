import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

export default function ProductEntry(props) {
  const {
    productName,
    productCategory,
    productQuantity,
    productPrice,
    productThumbnail,
    isPanIndia,
    productDeliverabilityError,
    variantTitle,
    addressError,
    isFreeProduct,
    expiredAt,
    Mrp,
    applyDiscountOnProduct,
    productDisplayMessage,
    promotionPrice,
    promotionQty,
  } = props.productDetails;
  const isDiscountCode = props.isDiscountCode;
  const { onDelete } = props;
  // let formatedProductName = productName.replace("<i>", "");
  let formatedProductName = productName.split("<br>");
  formatedProductName = formatedProductName[0];

  const { addressDeliverabilityError } = useSelector((state) => state.cart);
  const { orderID } = useSelector((state) => state.checkout);
  const [timer, setTimer] = useState("");
  const Ref = useRef(null);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e + "Z") - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(total / 1000 / 60 / 60);
    if (total <= 0 && Ref.current && expiredAt) clearInterval(Ref.current);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
    const id = setInterval(() => {
      let { total, hours, minutes, seconds } = getTimeRemaining(e);
      if (total >= 0) {
        setTimer(
          (hours > 9 ? hours : "0" + hours) +
            ":" +
            (minutes > 9 ? minutes : "0" + minutes) +
            ":" +
            (seconds > 9 ? seconds : "0" + seconds)
        );
      }
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 5);
    return deadline;
  };

  useEffect(() => {
    if (!orderID && isFreeProduct && expiredAt) {
      // startTimer(getDeadTime());
      startTimer(new Date(expiredAt));
    }
  }, []);

  if (!orderID && isFreeProduct && expiredAt && timer && timer === "00:00:00") return null;

  return (
    <>
      <div className="cartitem-info">
        <div className="product-image">
          <span className="product-thumbnail">
            <img src={productThumbnail} alt={productName} />
          </span>
        </div>
        <div className="product-description">
          <span className="product-title text-left">
            <div dangerouslySetInnerHTML={{ __html: formatedProductName }} />
            {/* {props.showQuantityBesideName ? `(${productQuantity})` : ""} */}
          </span>

          {variantTitle !== "Default Title" ? (
            <span className="subtitle product-subtitle justify-content-start">{variantTitle}</span>
          ) : null}
          <span className="subtitle product-subtitle justify-content-start">{`Quantity: ${productQuantity}`}</span>

          {/* <span className="justify-content-start" style={{ display: "flex", color: "#a2adbe" }}>
            <i className="fa fa-truck" aria-hidden="true"></i>
            <span className="subtitle product-subtitle">
              <span className="subtitle product-subtitle" style={{ paddingLeft: 5 }}></span>
              {isPanIndia ? "Shipped across India" : "Shipped only in DEL-NCR"}
            </span>
          </span> */}

          {promotionQty && promotionQty !== 0 ? (
            <>
              <div
                style={{
                  paddingInline: "8px",
                  borderRadius: "2px",
                  background: "#707AAE",
                  color: "#fff",
                  fontSize: "10px",
                  lineHeight: "2",
                  letterSpacing: ".26px",
                  fontWeight: "bold",
                  width: "fit-content",
                  marginBlock: "4px",
                }}
              >
                ✨ {promotionQty} quantity @ ₹{promotionPrice}
              </div>

              {props.isDiscountApplied ? (
                <span
                  style={{
                    color: "#a2adbe",
                    fontSize: "10px",
                    marginBottom: "10px",
                    lineHeight: "1.7",
                    fontStyle: "italic",
                  }}
                >
                  * Extra discount not eligible for {promotionQty} qty
                </span>
              ) : null}
            </>
          ) : null}

          {isDiscountCode && !applyDiscountOnProduct ? (
            <span className="subtitle product-subtitle justify-content-start not-discount-applicable">
              * Additional discount not applicable
            </span>
          ) : null}

          {productDisplayMessage ? (
            <span className="subtitle product-subtitle justify-content-start not-discount-applicable">
              {`* ${productDisplayMessage}`}
            </span>
          ) : null}

          {!orderID && isFreeProduct && expiredAt && timer ? (
            <span
              className="product-title"
              style={{
                marginTop: 5,
                marginBottom: 5,
                color: "rgb(174, 95, 117)",
                fontSize: 12,
                width: "120%",
              }}
            >
              {timer === "00:00:00" ? "Free Item Expired" : `This Item Expires In ${timer}`}
            </span>
          ) : null}
        </div>

        <div className="product-price" style={{ display: "flex", flexDirection: "column" }}>
          {isFreeProduct ? (
            <span
              style={{
                display: "flex",
                // flex: 0.7,
                width: 60,
                // color: "#38A176",
                border: "1px dotted #b4cec4",
                borderRadius: 3,
                padding: 2,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#d5f3e7",
              }}
            >
              Free
            </span>
          ) : (
            <div>
              <span style={{ display: "flex", flex: 0.7, width: 60, flexDirection: "column" }}>{`Rs. ${Mrp}`}</span>
              {/* <span style={{ display: "flex", flex: 0.7, width: 60, flexDirection: "column" }}>
              {`Rs. ${productPrice}`}
            </span>
            <span style={{ display: "flex", flex: 0.7, width: 60, flexDirection: "column", color: "#a2adbe", textDecoration: "line-through" }}>
              {`Rs. ${Mrp}`}
            </span> */}
            </div>
          )}

          {/* <span className="priceItemJs">{productPrice}</span> */}
          {productDeliverabilityError && addressDeliverabilityError ? (
            <div style={{ display: "flex", alignSelf: "flex-end", flex: 0.1 }}>
              <button
                style={{ backgroundColor: "#fff" }}
                type="button"
                onClick={() => onDelete(props.productDetails, -props.productDetails.productQuantity)}
              >
                <i className="fa fa-trash-o" style={{ color: "#CD5555" }} aria-hidden="true"></i>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
