import React, { useEffect } from "react";
import { cartAPI } from "../../helpers/api";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const VerifyOrder = (props) => {
  const { checkoutToken, checkoutID } = props.match.params;
  const antIcon = <LoadingOutlined style={{ fontSize: 70, color: "green" }} spin />;

  const verifyOrder = async () => {
    let finalToken = "";
    let finalId = "";

    if (checkoutToken.includes("cart_token")) {
      finalToken = checkoutToken.split("=")[1];
    } else {
      finalToken = checkoutToken;
    }
    if (checkoutID.includes("receipt_number")) {
      finalId = checkoutID.split("=")[1];
    } else {
      finalId = checkoutID;
    }
    const formData = {
      checkoutToken: finalToken,
      checkoutID: finalId,
    };
    // const { statusCode } = await cartAPI.post("/new/checkout/verify/order/", formData);
    const { statusCode } = await cartAPI.post("/api/v1/checkout/verify/order/", formData);
    if (statusCode === 200) {
      if (!window.location.href.includes("/order/placed"))
        window.location.assign(`/order/placed/thank_you?cart_id=${finalToken}`);
    } else {
    }
  };

  useEffect(() => {
    verifyOrder(checkoutToken, checkoutID);
  }, [checkoutToken, checkoutID]);

  return (
    <div
      style={{ display: "flex", textAlign: "center", marginTop: "15%", marginBottom: "15%", flexDirection: "column" }}
    >
      <Spin indicator={antIcon} />
      <h4 style={{ paddingTop: 20 }}>Confirming your Order</h4>
    </div>
  );
};

export default VerifyOrder;
