import React, { Fragment, useEffect, useRef, useState, useCallback } from "react";
import { useCookies } from "react-cookie";

import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from "antd";
import AddAddressStepComponent from "../../components/address/AddAddressStepComponent";
import EditableCartSidebar from "../../components/cart/EditableCartSidebar";
import Layout from "../../components/Layout";
import PaymentModeStepComponent from "../../components/payment/PaymentModeStepComponent";
import SuccessCartSidebar from "../../components/cart/SuccessCartSidebar";
import SuccessPaymentResponse from "../../components/response/SuccessPaymentResponse";
import VerifyPhoneStepComponent from "../../components/login/VerifyPhoneStepComponent";
import { addClass, makePublicURL, removeClass, useQuery, debounce } from "../../helpers";
import {
  ACTIVATE_ADDRESS,
  ACTIVATE_PAYMENT,
  BACK_TO_ADDRESS,
  CHANGE_ADDRESS,
  CHANGE_ADDRESS_BACK_BUTTON,
  PAYMENT_DONE,
  PROCEED_PAY_STEP,
  CLOSE_MODAL,
} from "../../store/checkout/actions";
import { updateCosting } from "../../store/cart/actions";

import "../../assets/scss/base.scss";
import "../../assets/scss/form.scss";
import { sendEvent } from "../../helpers/ga";
import LoadingBlock from "../../components/checkout/LoadingBlock";
import InvalidCheckoutBlock from "../../components/checkout/InvalidCheckoutBlock";
import EmptyCartRoutine from "../../components/cart/EmptyCartRoutine";
import AddressModal from "../../components/address/AddressModal";
import { WEBSITE_URL, NEW_WEBSITE_URL } from "../../helpers/constants";

const qs = require("querystring");

export default function CheckoutPage(props) {
  const [cookies, setCookie] = useCookies();
  const dispatch = useDispatch();
  const location = useQuery();
  const checkoutSelector = useSelector((state) => state.checkout);
  const { showModal } = checkoutSelector;
  const addressSelector = useSelector((state) => state.address);
  const cartSelector = useSelector((state) => state.cart);
  const { newCartId } = cartSelector;
  const [warningModal, setWarningModal] = useState({
    loading: false,
    visible: false,
  });
  const overlayClassRef = useRef();
  const loginRegisterStepRef = useRef();
  const desktopSuccessCartRef = useRef();
  const modalBackReff = useRef();
  let cartId = location.get("cart_id");
  // console.log("cartId", cartId);
  if (cartId && cartId.includes("cart_token")) {
    // const checkout_token = "FXzedGUJEq3ESQX1TuLu";
    window.location.href = `?cart_id=${cartId.split("=")[1]}`;
    window.location.href = `?cart_id=${cartId}`;
  }

  const handleOk = () => {
    setWarningModal({
      ...warningModal,
      loading: true,
    });
    window.location.href = cookies.homeUrl === "new.nathabit.in" ? NEW_WEBSITE_URL : WEBSITE_URL;
    // window.location.href = cookies.website_type === "B" ? NEW_WEBSITE_URL : WEBSITE_URL;
  };

  const handleCancel = () => {
    setWarningModal({
      ...warningModal,
      visible: false,
      loading: false,
    });
  };

  const params = useQuery();

  const handleResize = (e) => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      addClass(desktopSuccessCartRef, "d-none");
    } else {
      removeClass(desktopSuccessCartRef, "d-none");
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    sendEvent("checkoutPageView", "View Checkout Page", "View");
  }, []);

  useEffect(() => {
    if (newCartId) {
      const source_ref = params.get("ref");
      source_ref && updateCartRef(newCartId, source_ref);
    }
  }, [newCartId]);

  const updateCartRef = useCallback(
    debounce((checkoutId, source_ref) => {
      // updateCartInfoV1(checkoutId, source_ref);
      dispatch(updateCosting(checkoutId, { referrer: source_ref }));
    }, 2000)
  );

  const handlePopState = (e) => {
    if (showModal) {
      e.preventDefault();
      modalBackReff.current.click();
      // dispatch({ type: CLOSE_MODAL });
    } else {
      e.preventDefault();
      setWarningModal({
        ...warningModal,
        visible: true,
      });
      window.history.pushState(null, null, document.URL);
    }
  };
  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.addEventListener("popstate", handlePopState);
    };
  }, [showModal]);

  useEffect(() => {
    if (checkoutSelector.currentState === ACTIVATE_ADDRESS) {
      addClass(loginRegisterStepRef, "step-successful-login");
      removeClass(loginRegisterStepRef, "change-address-step");
      removeClass(loginRegisterStepRef, "add-new-address");
      removeClass(loginRegisterStepRef, "choose-delivery-step");
      removeClass(loginRegisterStepRef, "delivery-address-step");
    }
    if (checkoutSelector.currentState === ACTIVATE_PAYMENT) {
      addClass(loginRegisterStepRef, "step-successful-login");
      addClass(loginRegisterStepRef, "choose-delivery-step");
      removeClass(loginRegisterStepRef, "add-new-address");
    }
    if (checkoutSelector.currentState === CHANGE_ADDRESS) {
      addClass(loginRegisterStepRef, "add-new-address");
      removeClass(loginRegisterStepRef, "change-address-step");
      removeClass(loginRegisterStepRef, "proceed-pay-step");
      removeClass(loginRegisterStepRef, "choose-delivery-step");
      removeClass(loginRegisterStepRef, "delivery-address-step");
    }
    if (checkoutSelector.currentState === PROCEED_PAY_STEP) {
      addClass(loginRegisterStepRef, "proceed-pay-step");
      removeClass(loginRegisterStepRef, "add-new-address");
      removeClass(loginRegisterStepRef, "change-address-step");
      removeClass(loginRegisterStepRef, "choose-delivery-step");
      removeClass(loginRegisterStepRef, "delivery-address-step");
    }

    if (checkoutSelector.currentState === CHANGE_ADDRESS_BACK_BUTTON) {
      addClass(loginRegisterStepRef, "change-address-step");
      removeClass(loginRegisterStepRef, "add-new-address");
      removeClass(loginRegisterStepRef, "proceed-pay-step");
      removeClass(loginRegisterStepRef, "choose-delivery-step");
      removeClass(loginRegisterStepRef, "delivery-address-step");
    }

    if (checkoutSelector.currentState === PAYMENT_DONE) {
      addClass(loginRegisterStepRef, "success-step");
      removeClass(loginRegisterStepRef, "add-new-address");
      removeClass(loginRegisterStepRef, "proceed-pay-step");
      removeClass(loginRegisterStepRef, "choose-delivery-step");
      removeClass(loginRegisterStepRef, "change-address-step");
      removeClass(loginRegisterStepRef, "delivery-address-step");
    }

    if (checkoutSelector.currentState === BACK_TO_ADDRESS) {
      // $(".form-main").addClass('delivery-address-step').removeClass("add-new-address").removeClass("proceed-pay-step").removeClass('choose-delivery-step').removeClass('change-address-step');
      addClass(loginRegisterStepRef, "delivery-address-step");
      removeClass(loginRegisterStepRef, "add-new-address");
      removeClass(loginRegisterStepRef, "proceed-pay-step");
      removeClass(loginRegisterStepRef, "change-address-step");
    }
  }, [checkoutSelector]);

  return (
    <Layout>
      <Fragment>
        {!cartSelector.emptyCart ? (
          <>
            <div
              className={`main ${
                checkoutSelector.pageInitialLoaded && checkoutSelector.validCheckout ? "" : "d-none"
              }`}
            >
              <div ref={loginRegisterStepRef} className={`form-main form-checkout login-register-step`}>
                <div className="steps-bar">
                  {/* 1 */}
                  <VerifyPhoneStepComponent />
                  {/* 2 */}
                  <AddAddressStepComponent />
                  {/* 3 */}
                  <PaymentModeStepComponent cartID={cartId} />
                  {/* make payment */}
                  <SuccessPaymentResponse />
                </div>
                {/* cart */}
                {/* right sidebar */}
                <EditableCartSidebar cartID={location.get("cart_id")} />
                {/* success right-sidebar */}
                <div ref={desktopSuccessCartRef}>
                  <SuccessCartSidebar />
                </div>
              </div>
              <div ref={overlayClassRef} className={`overlay ${checkoutSelector.removeOverlay ? "d-none" : ""}`} />
            </div>
            {!checkoutSelector.validCheckout ? <InvalidCheckoutBlock /> : <></>}
            {!checkoutSelector.pageInitialLoaded ? <LoadingBlock /> : <></>}
            <AddressModal
              key={checkoutSelector.modalKey}
              addressDetails={addressSelector.candidateAddress}
              closeRef={modalBackReff}
            />
          </>
        ) : (
          <EmptyCartRoutine />
        )}
        <Modal
          visible={warningModal.visible}
          title="Warning"
          maskClosable={true}
          zIndex={10000}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <button
              key="ContinueShopping"
              className="form-btn stepBtn m-1 px-2"
              style={{ width: "fit-content" }}
              loading={warningModal.loading}
              onClick={handleOk}
            >
              {warningModal.loading ? (
                <i className="fa fa-circle-o-notch fa-spin ml-2" style={{ marginRight: 10 }}></i>
              ) : null}
              Continue Shopping
            </button>,
            <button
              key="StayHere"
              className="form-btn stepBtn green-btn px-2 m-1"
              style={{ width: "fit-content" }}
              onClick={handleCancel}
            >
              Back
            </button>,
          ]}
        >
          <p>Are you sure you want to leave here ?</p>
        </Modal>
      </Fragment>
    </Layout>
  );
}
