const checkoutFunnel = {
  1: "Checkout Page Load",
  2: "Customer Verification",
  3: "Address",
  4: "Product Deliverability Error",
  5: "Payment",
  6: "Order Placed",
};

export const sendEvent = (eventName, label, action, data) => {
  // window.gtag("event", eventName, {
  // 	href: window.location.href,
  // 	label,
  // 	action,
  // 	...data,
  // });
};

export const sendCheckoutLoadGoogleAnalytics = (step, checkoutOption, productData = []) => {
  console.log("first");
  let finalProducts = productData.checkout_items.map((item) => {
    return {
      name: item.cms_product_title, // Name or ID is required.
      id: item.product_id,
      price: item.per_unit_sp,
      brand: "Nat Habit",
      variant: item.cms_variant_name,
      quantity: item.quantity,
    };
  });
  let finalProductsGa4 = productData.checkout_items.map((item, i) => {
    return {
      item_name: item.cms_product_title, // Name or ID is required.
      item_id: item.product_id,
      index: i,
      price: item.per_unit_sp,
      item_brand: "Nat Habit",
      item_variant: item.cms_variant_name,
      quantity: item.quantity,
      item_category: "Beauty",
    };
  });
  window.dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: { step: step, option: checkoutOption },
        products: finalProducts,
      },
    },
  });

  // window.dataLayer.push({
  //   event: "begin_checkout",
  //   ecommerce: {
  //     currency: "INR",
  //     value: productData.costs.total,
  //     items: finalProductsGa4,
  //   },
  // });
};

export const sendCheckoutOptionGoogleAnalytics = (step, checkoutOption, productData = []) => {
  let finalProducts = productData.map((item) => {
    return {
      name: item.cmsProductName, // Name or ID is required.
      id: item.productId,
      price: item.productOriginalPrice,
      brand: "Nat Habit",
      variant: item.cmsVariantTitle,
      quantity: item.productQuantity,
    };
  });
  window.dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: { step: step, option: checkoutOption },
        products: finalProducts,
      },
    },
  });
};

export const sendShippingDataGoogleAnalytics = (totalPrice, productData = []) => {
  let finalProducts = productData.map((item) => {
    return {
      name: item.cmsProductName, // Name or ID is required.
      id: item.productId,
      price: item.productOriginalPrice,
      brand: "Nat Habit",
      variant: item.cmsVariantTitle,
      quantity: item.productQuantity,
    };
  });
  // dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "add_shipping_info",
    ecommerce: {
      currency: "INR",
      value: totalPrice,
      items: finalProducts,
    },
  });
};

export const sendTransactionDataGoogleAnalytics = (
  productData,
  totalPrice,
  shippingCharge,
  transactionId,
  discountCode = ""
) => {
  let gaEventData = {
    event: "transactionData",
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId, // Transaction ID. Required for purchases and refunds.
          revenue: totalPrice, // Total transaction value (incl. tax and shipping)
          shipping: shippingCharge,
          coupon: discountCode,
          currency: "INR",
        },
        products: [],
      },
    },
  };
  let finalProductsGa4 = productData.map((item, i) => {
    return {
      item_name: item.cmsProductName, // Name or ID is required.
      item_id: item.productId,
      index: i,
      price: item.productOriginalPrice,
      item_brand: "Nat Habit",
      item_variant: item.cmsVariantTitle,
      quantity: item.productQuantity,
      item_category: "Beauty",
      currency: "INR",
    };
  });

  let finalProducts = productData.map((item) => {
    return {
      name: item.cmsProductName, // Name or ID is required.
      id: item.productId,
      price: item.productOriginalPrice,
      brand: "Nat Habit",
      variant: item.cmsVariantTitle,
      quantity: item.productQuantity,
    };
  });
  gaEventData.ecommerce.purchase.products = finalProducts;
  window.dataLayer.push(gaEventData);

  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      transaction_id: transactionId, // Transaction ID. Required for purchases and refunds.
      value: totalPrice, // Total transaction value (incl. tax and shipping)
      shipping: shippingCharge,
      coupon: discountCode,
      currency: "INR",
      items: finalProductsGa4,
    },
  });
};

export const sendPaymentDataGoogleAnalytics = (productData, totalPrice, discountCode = "", paymentMode = "COD") => {
  let finalProductsGa4 = productData.map((item, i) => {
    return {
      item_name: item.cmsProductName, // Name or ID is required.
      item_id: item.productId,
      index: i,
      price: item.productOriginalPrice,
      item_brand: "Nat Habit",
      item_variant: item.cmsVariantTitle,
      quantity: item.productQuantity,
      item_category: "Beauty",
    };
  });
  // dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "add_payment_info",
    ecommerce: {
      currency: "INR",
      value: totalPrice,
      coupon: discountCode,
      payment_type: paymentMode,
      items: finalProductsGa4,
    },
  });
};
export const fbPurchaseDataAnalytics = (product, value) => {
  let fbEventData = {
    value: value * 0.9,
    currency: "INR",
    content_ids: [],
    content_type: "product_group",
    num_items: 0,
  };
  let productIds = [];
  let numberOfItem = 0;
  product.map((item) => {
    productIds.push(item.productId);
    numberOfItem += item.productQuantity;
    return {};
  });
  fbEventData.content_ids = productIds;
  fbEventData.num_items = numberOfItem;
  // debugger;
  window.fbq("track", "Purchase", fbEventData);
};

export const fbSubscribeDataAnalytics = (value) => {
  let fbEventData = {
    value: value * 0.9,
    currency: "INR",
  };
  window.fbq("track", "Subscribe", fbEventData);
};

export const sendSubCheckoutOptionGA = (step, checkoutOption) => {
  window.dataLayer.push({
    event: "checkoutOption",
    ecommerce: {
      checkout_option: {
        actionField: { step: step, option: checkoutOption },
      },
    },
  });
};

export const sendAddressSelectionToMoengage = (address, type = "pre-selected") => {
  try {
    window.Moengage.track_event("Address Selected", {
      full_name: address.fullName,
      nearest_location: address.addressLocation,
      address: address.apartmentName,
      city: address.city,
      state: address.state,
      pincode: address.zip,
      referrer_section: "checkout",
      referrer_page: "checkout",
      type,
    });
  } catch (err) {
    console.log(err);
  }
};
export const sendOrderConfirmationToMoengage = (
  productData = [],
  total,
  shipping,
  orderName,
  discountCode,
  discountPrice,
  paymentMode,
  PaymentCharges
) => {
  // No. of Items
  // Product Name
  // Product ID
  // Price
  // Variant ID
  // Quantity
  // Gift Order
  let itemQuantity = 0;
  let finalProducts = productData.map((item) => {
    itemQuantity = itemQuantity + item.productQuantity;
    return {
      product_name: item.productName
        .split("<br>")[0]
        .replace("<strong>", "")
        .replace("</strong>", "")
        .replace("<i>", "")
        .replace("</i>", ""), // Name or ID is required.
      product_id: item.productId,
      price: item.productOriginalPrice,
      variant_id: item.variantId,
      quantity: item.productQuantity,
      isFree: item.isFreeProduct,
    };
  });
  try {
    window.Moengage.track_event("Order Confirmed", {
      order_id: orderName,
      total_amount: total,
      discount_code: discountCode,
      total_discount: discountPrice,
      payment_method: paymentMode,
      shipping,
      payment_method_charges: paymentMode === "online" ? PaymentCharges.online_charges : PaymentCharges.cod_charges,
      referrer_section: "checkout",
      referrer_page: "checkout",
      products: finalProducts,
      no_of_items: itemQuantity,
    });
  } catch (err) {
    console.log(err);
  }
};
