import React, { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { addClass, makePublicURL, removeClass } from "../../helpers";
import { CARD_PAYMENT, COD } from "../../helpers/constants";
import { BACK_TO_ADDRESS } from "../../store/checkout/actions";
import { UPDATE_OPEN_PAYMENT, updateCosting } from "../../store/cart/actions";
import { PAYMENT_MODE } from "../../store/payment/actions";
import { cartAPI, razorpayKey, newPaymentFlow } from "../../helpers/api";

import "./scss/payment.scss";
import {
  sendCheckoutOptionGoogleAnalytics,
  sendTransactionDataGoogleAnalytics,
  sendPaymentDataGoogleAnalytics,
  sendSubCheckoutOptionGA,
  fbPurchaseDataAnalytics,
  fbSubscribeDataAnalytics,
  sendOrderConfirmationToMoengage,
} from "../../helpers/ga";
import { getUtm } from "../../helpers/utmHelper";
import { sendDataToSlack } from "../../helpers/sendDatatoSlack";
import { storeAddressAgainstOrder, deleteAddressData } from "../../helpers/addressStorage";
import { useCookies } from "react-cookie";

import { deleteCookie } from "../../helpers/cookies";
import ErrorMessage from "./ErrorMessage";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export default function PaymentModeStepComponent({ cartID }) {
  const [attempt, setAttempt] = useState(0);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentHeading, setPaymentHeading] = useState("");
  const [paymentSubtitle, setPaymentSubtitle] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [paymentBg, setPaymentBg] = useState("#eafaf5");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [tryAgainEnabled, setTryAgainEnabled] = useState(false);
  const [timerVal, setTimerVal] = useState("");
  const [intervalVal, setIntervalVal] = useState(null);
  const [timerInterval, setTimerInterval] = useState(null);
  const [placeOrderBtnDisabled, setPlaceOrderBtnDisabled] = useState(false);
  const [displayTryAgain, setDisplayTryAgain] = useState(false);
  const [verifyAttempt, setVerifyAttempt] = useState(false);
  const [paymentGateway, setPaymentGateway] = useState("razorpay");
  const [orderErrorMessage, setOrderErrorMessage] = useState("");

  const dispatch = useDispatch();

  const startTimer = () => {
    var timer2 = "3:00";
    // if (!interval) {
    if (suggestion === "") {
      var interval = setInterval(function () {
        var timer = timer2.split(":");
        //by parsing integer, I avoid all extra string processing
        var minutes = parseInt(timer[0], 10);
        var seconds = parseInt(timer[1], 10);
        --seconds;
        minutes = seconds < 0 ? --minutes : minutes;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 0 ? 59 : seconds;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        //minutes = (minutes < 10) ?  minutes : minutes;
        setTimerVal(minutes + ":" + seconds);
        setSuggestion("You can try again in ");
        setTimerInterval(interval);
        setDisplayTryAgain(true);
        //check if both minutes and seconds are 0
        if (seconds <= 0 && minutes <= 0) {
          // removeClass(resendBtnRef, "disable");
          // removeClass(timerValueJsRef, "is-active");
          setTimerVal("");
          clearInterval(interval);
          setTryAgainEnabled(true);
          setPaymentLoader(false);
          dispatch({ type: UPDATE_OPEN_PAYMENT, payload: false });
          clearInterval(intervalVal);
          setPlaceOrderBtnDisabled(false);
        }
        timer2 = minutes + ":" + seconds;
      }, 1000);
    }
    // }}
  };

  async function displayRazorpay(formData, windowReference = null) {
    // creating a new order

    window.fbq("track", "AddPaymentInfo", {
      currency: "INR",
      value: finalTotalPrice * 0.9,
    });
    try {
      // const { data, statusCode } = await cartAPI.post("/v1/checkout/v2/view", formData);
      // const { data, statusCode } = await cartAPI.post("/new/checkout/place/order", formData);
      const { data, statusCode } = await cartAPI.post("/api/v1/checkout/place/order", formData);

      if (statusCode === 201) {
        sendSubCheckoutOptionGA(6, "orderCreationBackendSuccess");
        if (Object.keys(data.shp_data).length !== 0 && Object.keys(data.r_pay).length === 0) {
          afterOrderPlaceFunctions(data);
          return;
        } else {
          if (paymentGateway === "razorpay") {
            const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
            if (!res) {
              alert("Razorpay SDK failed to load. Are you online?");
              return;
            }
            const result = data.r_pay;

            const { amount, id: order_id, currency } = result;

            const options = {
              key: razorpayKey, // Enter the Key ID generated from the Dashboard
              amount: amount.toString(),
              currency: currency,
              name: "Nat Habit",
              description: `Nat Habit - #${checkoutId}`,
              order_id: order_id,
              modal: {
                ondismiss: () => {
                  setPlaceOrderButtonLoading(false);
                  setPlaceOrderBtnDisabled(false);
                  try {
                    window.Moengage.track_event("Payment Failed", {
                      payment_method: paymentMode,
                      final_amount: finalTotalPrice,
                      failure_type: "payment_window_closed",
                    });
                  } catch (err) {
                    console.log(err);
                  }
                },

                escape: false,
              },
              handler: async function (response) {
                sendSubCheckoutOptionGA(5, "onlinePaymentSuccess");
                const formDataa = {
                  orderCreationId: order_id,
                  razorpayPaymentId: response.razorpay_payment_id,
                  razorpayOrderId: response.razorpay_order_id,
                  razorpaySignature: response.razorpay_signature,

                  receipt: result.receipt,
                  paymentGateway,
                };
                verifyPayment(formDataa, formData);
              },
              prefill: {
                contact: phoneNumber,
              },
              theme: {
                color: "#38a176",
              },
            };
            if (email && email !== `${phoneNumber}@nathabit.in`) {
              options.prefill.email = email;
            }
            if (customerEmail && customerEmail !== "") options.prefill.email = customerEmail;

            const paymentObject = new window.Razorpay(options);
            paymentObject.on("payment.failed", function (response) {
              sendSubCheckoutOptionGA(5, "onlinePaymentFailed");
              try {
                window.Moengage.track_event("Payment Failed", {
                  payment_method: paymentMode,
                  final_amount: finalTotalPrice,
                  failure_type: "razorpay_error",
                });
              } catch (err) {
                console.log(err);
              }
            });
            paymentObject.open();
            if (paymentObject.metadata.openedAt) {
              let data = {
                payment_modal: true,
              };
              setPaymentModalOpen(true);
              // cartAPI.patch(`/new/checkout/add/${checkoutId}/`, data);
              dispatch(updateCosting(checkoutId, data));
            }
          } else if (paymentGateway === "paytm") {
            let userAgent = window.navigator.userAgent;
            let browserName;
            function osBrowserTest(regExpOS, regExpBrowser) {
              return regExpOS.test(userAgent) && regExpBrowser.test(userAgent);
            }
            switch (true) {
              case osBrowserTest(/android/i, /Chrome/i) &&
                !/wv/i.test(userAgent) &&
                !/SamsungBrowser/i.test(userAgent) &&
                !/MiuiBrowser/i.test(userAgent):
                browserName = "googlechrome";
                break;
              case osBrowserTest(/iPad|iPhone|iPod/i, /Safari/i):
                browserName = "safari";
                break;
              default:
                browserName = "googlechrome";
                break;
            }
            const result = data.r_pay;

            const { orderId, txnToken, amount } = result;
            const uri = `https://securegw.paytm.in/theia/api/v2/showPaymentPage?mid=Naturo24771185107831&orderId=${orderId}&txnToken=${txnToken}&amount=${amount}&sourceName=${browserName}&sourceUrl=https://securegw.paytm.in/theia/api/v1/showPaymentPage&isType=offus`;

            windowReference.location = uri;
          }
          // else if (paymentGateway === "paytm") {
          //   const res = await loadScript(
          //     "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/Naturo24771185107831.js"
          //   );

          //   if (!res) {
          //     alert("Razorpay SDK failed to load. Are you online?");
          //     return;
          //   }
          //   const result = data.r_pay;
          //   const { orderId, txnToken, amount } = result;
          //   var config = {
          //     root: "",
          //     flow: "DEFAULT",
          //     data: {
          //       orderId: orderId /* update order id */,
          //       token: txnToken,
          //       tokenType: "TXN_TOKEN",
          //       amount: amount /* update amount */,
          //     },
          //     merchant: {
          //       redirect: false,
          //     },
          //     handler: {
          //       notifyMerchant: function (eventName, data) {
          //         if (eventName === "APP_CLOSED") {
          //           setPlaceOrderButtonLoading(false);
          //           setPlaceOrderBtnDisabled(false);
          //         }
          //         console.log("notifyMerchant handler function called");
          //         console.log("eventName => ", eventName);
          //         console.log("data => ", data);
          //       },
          //       transactionStatus: function (data) {
          //         sendSubCheckoutOptionGA(5, "onlinePaymentSuccess");

          //         console.log(data);
          //         const { STATUS, ORDERID } = data;
          //         const formDataa = { orderId: ORDERID, status: STATUS, paymentGateway, receipt: checkoutId };
          //         window.Paytm.CheckoutJS.close();
          //         verifyPayment(formDataa, formData);
          //       },
          //     },
          //   };
          //   if (window.Paytm && window.Paytm.CheckoutJS) {
          //     window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
          //       window.Paytm.CheckoutJS.init(config)
          //         .then(function onSuccess() {
          //           window.Paytm.CheckoutJS.invoke();
          //         })
          //         .catch(function onError(error) {
          //           console.log("error => ", error);
          //         });
          //     });
          //   }
          // }
        }
      } else {
        if (data.message) {
          setOrderErrorMessage(data.message);
        }
        setPlaceOrderButtonLoading(false);
        setPlaceOrderBtnDisabled(false);
        sendDataToSlack(formData, data, statusCode);
        sendSubCheckoutOptionGA(6, "orderCreationBackendFailure");
        try {
          window.Moengage.track_event("Payment Failed", {
            payment_method: paymentMode,
            final_amount: finalTotalPrice,
          });
        } catch (err) {
          console.log(err);
        }
        if (statusCode === 400) {
          if (data.message === "Checkout already processed") {
            if (!window.location.href.includes("/order/placed")) {
              window.location.assign(`/order/placed/thank_you?cart_id=${cartID}`);
            }
          }
        }
      }
    } catch (error) {
      setPlaceOrderButtonLoading(false);
      setPlaceOrderBtnDisabled(false);
      sendDataToSlack(formData, "Internal Server Error", 500);
    }
  }

  const makePaymentRef = useRef();
  const CODModeRef = useRef();
  const razorpayModeRef = useRef();
  const paytmModeRef = useRef();
  const makePaymentRefMobile = useRef();
  const CODModeRefMobile = useRef();
  const razorpayModeRefMobile = useRef();
  const paytmModeRefMobile = useRef();

  const [cookies, setCookie, removeCookie] = useCookies(["affiliate"]);

  const paymentBackBtnRef = useRef();

  const [paymentMode, setPaymentMode] = useState("online");
  const [paymentButtonClicked, setPaymentClicked] = useState(true);

  const { phoneNumber, shpID, customerEmail, email, mode, guestUserId } = useSelector((state) => state.login);

  const { selectedAddress } = useSelector((state) => state.address);
  const paymentSelector = useSelector((state) => state.payment);

  const {
    productDetails,
    discountPrice,
    discountType,
    discountCode,
    discountPercentage,
    shipping,
    currency,
    isDiscountTypeReferral,
    checkoutId,
    paymentCharges,
    paymentOpen,
    outstation,
    tempIsGift,
    tempSender,
    tempRecepient,
    tempIsgiftMessage,
    tempSendInvoice,
    additionalOff,
    natCashApplied,
    finalTotalPrice,
    isLoading,
    paymentChargesObj,
    checkoutState,
  } = useSelector((state) => state.cart);

  const { currentState } = useSelector((state) => state.checkout);
  // const [total, setTotal] = useState(finalTotalPrice);
  const [placeOrderButtonLoading, setPlaceOrderButtonLoading] = useState(false);

  // useEffect(() => {
  //   setTotal(getTotal(productDetails, discountPrice, shipping, paymentSelector.paymentMode, PaymentCharges));
  // });

  useEffect(() => {
    razorpayModeRef.current.checked = true;
    razorpayModeRefMobile.current.checked = true;
    dispatch({ type: PAYMENT_MODE, payload: "online" });
    setPaymentMode("online");
    addClass(makePaymentRef, "is-active");
    addClass(makePaymentRefMobile, "is-active");
  }, []);

  const checkPaymentMode = () => {
    let paymentMode = "";
    if (CODModeRef.current.checked || CODModeRefMobile.current.checked) {
      dispatch({ type: PAYMENT_MODE, payload: "cod" });
      setPaymentMode("cod");
      setPaymentGateway("manual");
      addClass(makePaymentRef, "is-active");
      addClass(makePaymentRefMobile, "is-active");
      setPaymentClicked(true);
      paymentMode = "COD";
    } else if (razorpayModeRef.current.checked || razorpayModeRefMobile.current.checked) {
      dispatch({ type: PAYMENT_MODE, payload: "online" });
      setPaymentMode("online");
      setPaymentGateway("razorpay");
      addClass(makePaymentRef, "is-active");
      addClass(makePaymentRefMobile, "is-active");
      setPaymentClicked(true);
      paymentMode = "ONLINE";
    } else if (paytmModeRef.current.checked) {
      dispatch({ type: PAYMENT_MODE, payload: "paytm" });
      setPaymentMode("online");
      setPaymentGateway("paytm");
      addClass(makePaymentRef, "is-active");
    } else {
      dispatch({ type: PAYMENT_MODE, payload: "" });
      removeClass(makePaymentRef, "is-active");
      removeClass(makePaymentRefMobile, "is-active");
      setPaymentClicked(false);
    }
    sendPaymentDataGoogleAnalytics(productDetails, finalTotalPrice, discountCode, paymentMode);
  };

  useEffect(() => {
    if (
      checkoutId &&
      checkoutState &&
      checkoutState !== "orderPlaced" &&
      !window.location.href.includes("order/placed")
    ) {
      // dispatch(updatePaymentMode(checkoutId, { payment_mode: paymentMode }));
      dispatch(updateCosting(checkoutId, { payment_mode: paymentMode }));
    }
  }, [checkoutId, paymentMode, checkoutState, dispatch]);

  const handlePaymentModeSelectionClick = (e, ref) => {
    ref.current.click();
    checkPaymentMode();
  };

  const handlePaymentBackBtnClick = (e) => {
    dispatch({ type: BACK_TO_ADDRESS });
  };

  const makePayment = (e) => {
    var windowReference;
    if (paymentGateway === "paytm") {
      windowReference = window.open("", "_blank");
    } else {
      windowReference = {};
    }
    try {
      window.Moengage.track_event("Make Payment Clicked", { payment_method: paymentMode });
    } catch (err) {
      console.log(err);
    }
    let data = {
      checkout_state: "attempted",
    };
    // cartAPI.patch(`/new/checkout/add/${checkoutId}/`, data);
    dispatch(updateCosting(checkoutId, data));
    setPlaceOrderButtonLoading(true);
    setPlaceOrderBtnDisabled(true);
    setTryAgainEnabled(false);
    setSuggestion("");
    e.preventDefault();
    const utmData = getUtm();
    const formData = {
      checkout_token: cartID,
      fullName: selectedAddress.fullName,
      // mobileNumber: selectedAddress.mobileNumber,
      address_id: selectedAddress.addressID,
      customer_id: mode === "verifiedUser" ? shpID : guestUserId,
      pincode: selectedAddress.pincode,
      phoneNumber,
      payment: paymentMode,
      payment_gateway: paymentGateway,
      ...utmData,

      // ...selectedAddress,
      // shp_id: shpID,
      // phoneNumber,
      // product: cartID,
      // payment: paymentMode,
      // discountPrice,
      // discountCode,
      // discount_type: discountType,
      // discount_value: discountPercentage,
      // shipping,
      // outstation,
      // isReferral: isDiscountTypeReferral,
      // ...utmData,
      // payment_gateway: paymentGateway,
    };

    if (mode === "guestUser" && !formData.customer) {
      formData.customer = cookies.userId;
    }
    if (cookies.affiliate && cookies.affiliate !== "null") formData.affiliate = cookies.affiliate;
    // if (formData.enableEdit) delete formData.addressID;

    sendCheckoutOptionGoogleAnalytics(5, paymentMode, productDetails);
    displayRazorpay(formData, windowReference);
  };

  async function verifyPayment(formDataa, formData) {
    setPaymentModalOpen(false);
    try {
      const payload = formDataa;
      // const { data, statusCode } = await cartAPI.post("/new/checkout/payment/update", formDataa);
      const { data, statusCode } = await cartAPI.post("/api/v1/checkout/payment/update", formDataa);
      if (statusCode === 201) {
        sendSubCheckoutOptionGA(5, "paymentMarkingSucess");
        afterOrderPlaceFunctions(data);
      } else {
        if (statusCode === 400) {
          if (data.message === "Cart already processed") {
            if (!window.location.href.includes("/order/placed")) {
              setPlaceOrderButtonLoading(false);
              setPlaceOrderBtnDisabled(false);
              window.location.assign(`/order/placed/thank_you?cart_id=${cartID}`);
            }
          }
        }
        if (data.retry && attempt < 3) {
          setAttempt(attempt + 1);
          setTimeout(() => {
            verifyPayment(payload, formData);
          }, 1000);
        } else {
          sendSubCheckoutOptionGA(5, "paymentMarkingFailure");
          sendDataToSlack(formDataa, data, statusCode);
          sendDataToSlack(formData, "Error in Order Creation", 500);
        }
      }
    } catch (error) {
      setPlaceOrderButtonLoading(false);
      setPlaceOrderBtnDisabled(false);
      sendDataToSlack(formDataa, "Internal Server Error", 500);
    }
  }

  const attemptVerifyPayment = (r_pay_order) => {
    // setPlaceOrderButtonLoading(true);
    // sendSubCheckoutOptionGA(5, "paymentMarkingSucess");
    // afterOrderPlaceFunctions(data);
    const formDataa = {
      // orderCreationId: order_id,
      // razorpayPaymentId: response.razorpay_payment_id,
      razorpayOrderId: r_pay_order,
      // razorpaySignature: response.razorpay_signature,

      receipt: checkoutId,
    };
    if (!verifyAttempt) {
      setVerifyAttempt(true);
      verifyPayment(formDataa, { close_payment_flow: true, receipt: checkoutId, r_pay_id: r_pay_order });
    }
  };

  useEffect(() => {
    if (newPaymentFlow && paymentOpen && (currentState === "activatePayment" || currentState === "proceedPayStep")) {
      // closePayment();
      setPaymentBg("#eafaf5");
      setPaymentHeading("Verifying your payment");
      setPaymentSubtitle("This might take few moments");
      setPaymentLoader(true);
      setPlaceOrderBtnDisabled(true);
      var attempt = 0;
      let closePaymentInterval = setInterval(async () => {
        attempt += 1;
        const formData = { token: cartID };
        const { data, statusCode } = await cartAPI.post("/v1/checkout/close/payment", formData);
        if (statusCode === 201) {
          clearInterval(closePaymentInterval);
          if (data.create_order) attemptVerifyPayment(data.r_pay_order);
        } else if (statusCode === 400) {
          setPlaceOrderBtnDisabled(false);
          setPaymentLoader(false);
          setPaymentHeading(data.message);
          setPaymentSubtitle(data.subtitle);
          setPaymentBg(data.bg);
          clearInterval(closePaymentInterval);
          clearInterval(timerInterval);
          setTimerVal("");
        } else {
          startTimer();
        }
        if (attempt >= 36) {
          clearInterval(closePaymentInterval);
        }
      }, 5000);
      setIntervalVal(closePaymentInterval);
      return () => clearInterval(closePaymentInterval);
    }
  }, [paymentOpen, currentState, suggestion]);

  useEffect(() => {
    if (newPaymentFlow && paymentModalOpen && !placeOrderButtonLoading) {
      //check for payment open
      // if payment opens return no active payment = show nothing
      // if payment shows error, show an error
      // if order creation failed, show customer support, but payment has been recieved successfuly
      dispatch({ type: UPDATE_OPEN_PAYMENT, payload: true });
    } else if (paymentModalOpen && placeOrderButtonLoading) {
      dispatch({ type: UPDATE_OPEN_PAYMENT, payload: false });
    }
  }, [paymentModalOpen, placeOrderButtonLoading]);

  // const closePayment = () => {
  //   setInterval(async () => {
  //     const formData = { token: cartID };
  //     const { data, statusCode } = await cartAPI.post("/v1/checkout/close/payment", formData);
  //     if (statusCode === 201) {
  //       sendSubCheckoutOptionGA(5, "paymentMarkingSucess");
  //       afterOrderPlaceFunctions(data);
  //     }
  //   }, 5000);
  // };

  const afterOrderPlaceFunctions = (data) => {
    sessionStorage.removeItem("discount");
    deleteAddressData();
    sendCheckoutOptionGoogleAnalytics(6, "orderPlaced", productDetails);
    // sendOrderConfirmationToMoengage(
    //   productDetails,
    //   finalTotalPrice,
    //   shipping,
    //   data.shp_data.order_name,
    //   discountCode,
    //   discountPrice,
    //   paymentMode,
    //   paymentCharges
    // );

    const shouldSkipAnalytics = paymentMode === "cod" && finalTotalPrice >= 10000;
    if (!shouldSkipAnalytics) {
      sendTransactionDataGoogleAnalytics(productDetails, finalTotalPrice, shipping, checkoutId, discountCode);
      fbPurchaseDataAnalytics(productDetails, finalTotalPrice);

      if (data.shp_data.is_new_customer) {
        fbSubscribeDataAnalytics(finalTotalPrice);
      }
    }

    storeAddressAgainstOrder(data.shp_data.order_name, selectedAddress);
    deleteCookie("affiliate");

    if (!window.location.href.includes("/order/placed")) {
      setTimeout(() => {
        setPlaceOrderButtonLoading(false);
        setPlaceOrderBtnDisabled(false);
        window.location.assign(`/order/placed/thank_you?cart_id=${cartID}`);
      }, 2000);
    }
  };

  const tryAgainPayment = () => {
    clearInterval(intervalVal);
    clearInterval(timerInterval);
    // setDisplayMessage(false);
    setPlaceOrderBtnDisabled(false);
    dispatch({ type: UPDATE_OPEN_PAYMENT, payload: false });
  };

  return (
    <div className="wrap-step-payment stepPaymentJs">
      <form className="step-item step-payment" autoComplete="off">
        {/* <span class="step-number hide-mobile">STEP <span class="stepNumberJs">3</span></span> */}
        <span className="step-number hide-mobile">STEP 3</span>

        <span
          className="cart-title back-btn payment-back-btn paymentBackBtn"
          ref={paymentBackBtnRef}
          onClick={handlePaymentBackBtnClick}
        >
          {" "}
          <img src={makePublicURL("img/back-arrow.png")} alt="" className="back-arrow" />
          <span>
            <span>Payment</span>
            {/* <span className="subtitle">
              {currency} {total}
            </span> */}
          </span>
        </span>
        <div className="payment-options-desktop">
          <div
            className="step-body"
            style={{
              paddingTop: "0px",
            }}
          >
            {" "}
            <span className="step-title">Choose a payment option</span>
            <div className="step-choose-payment stepChoosePaymentJs">
              <ul className="payment-list">
                <li
                  className="payment-item"
                  onClick={(e) => handlePaymentModeSelectionClick(e, razorpayModeRefMobile)}
                >
                  {" "}
                  <span className="step-content">
                    <img src={makePublicURL("img/cards.png")} alt="" />{" "}
                    <span className="address-wrap">
                      <span className="payment-system">
                        <span className="address-title">
                          Cards, Netbanking, Wallets and UPI &nbsp;
                          {paymentChargesObj.online_charges && paymentChargesObj.online_charges !== 0 ? (
                            <label className="payment-label">
                              <strong>Extra Rs.{paymentChargesObj.online_charges * -1} OFF</strong>
                            </label>
                          ) : null}
                        </span>
                        {/* {paymentMode === "online" ? (
                          <div className="payment-charge">
                            <span className="payment-label-mobile">
                              <label className="online-banner">
                                <strong>Extra Rs.{paymentCharges * -1} OFF</strong>
                                <br></br>
                                Pay only{" "}
                                <span className="strike-amount">
                                  {" "}
                                  {currency}
                                  {finalTotalPrice - paymentCharges}
                                  {paymentMode === "online"
                                    ? finalTotalPrice - PaymentCharges.online_charges
                                    : finalTotalPrice - PaymentCharges.cod_charges}
                                </span>
                                &nbsp; {currency}
                                {finalTotalPrice}
                                {paymentMode === "online"
                                  ? finalTotalPrice
                                  : finalTotalPrice - paymentCharges + paymentCharges}
                              </label>
                            </span>
                          </div>
                        ) : null} */}
                        <span className="subtitle">Powered by Razorpay</span>
                        <span className="cards-description">
                          <span className="card-ico">
                            <img src={makePublicURL("img/visa.png")} />{" "}
                          </span>
                          <span className="card-ico">
                            <img src={makePublicURL("img/master_card.png")} />{" "}
                          </span>
                          <span className="card-ico">
                            <img src={makePublicURL("img/rupay.png")} />{" "}
                          </span>
                        </span>
                        {newPaymentFlow &&
                        paymentOpen &&
                        (currentState === "activatePayment" || currentState === "proceedPayStep") ? (
                          <div className="loadingCtn" style={{ backgroundColor: paymentBg }}>
                            <span className="h">
                              {paymentHeading}
                              {paymentLoader && (
                                <>
                                  <span className="loader__dot">.</span>
                                  <span className="loader__dot">.</span>
                                  <span className="loader__dot">.</span>
                                </>
                              )}
                            </span>
                            <span className="subtitle">{paymentSubtitle}</span>
                            <span className="subtitle">{`${suggestion} ${timerVal}`}</span>
                            {displayTryAgain && placeOrderBtnDisabled ? <span className="dummyText">or</span> : null}

                            {displayTryAgain && placeOrderBtnDisabled ? (
                              <button className="subtitle tryAgain" onClick={tryAgainPayment} type="button">
                                Try Again Now
                              </button>
                            ) : null}
                            {/* <span className="subtitle">If debited, your payment is secure with us</span> */}
                          </div>
                        ) : null}
                        {tryAgainEnabled ? (
                          <div className="loadingCtn" style={{ backgroundColor: paymentBg }}>
                            <span className="h">We could not verify your payment!</span>
                            <span className="subtitle">If the amount is debited, please contact our support</span>
                            <span className="subtitle">otherwise you can try again</span>
                            {/* <span className="subtitle">If debited, your payment is secure with us</span> */}
                          </div>
                        ) : null}
                      </span>
                      <label className="radio-wrap">
                        <input
                          ref={razorpayModeRefMobile}
                          type="radio"
                          name="payment-type-radio-desktop"
                          // checked={paymentMode == "online" ? true : false}
                        />{" "}
                        <span className="radio-checkmark" />
                      </label>
                    </span>
                  </span>
                </li>
                {/* <li className="payment-item" onClick={(e) => handlePaymentModeSelectionClick(e, paytmModeRef)}>
                {" "}
                <span className="step-content">
                  <img src={makePublicURL("img/cards.png")} alt="" />{" "}
                  <span className="address-wrap">
                    <span className="payment-system">
                      <span className="address-title">
                        Paytm &nbsp;
                        {PaymentCharges.online_charges && PaymentCharges.online_charges !== 0 ? (
                          <label className="payment-label">
                            <strong>Extra Rs.{PaymentCharges.online_charges * -1} OFF</strong>
                          </label>
                        ) : null}
                      </span>
                      {PaymentCharges.online_charges && PaymentCharges.online_charges !== 0 ? (
                        <div className="payment-charge">
                          <span className="payment-label-mobile">
                            <label className="online-banner">
                              <strong>Extra Rs.{PaymentCharges.online_charges * -1} OFF</strong>
                              <br></br>
                              Pay only{" "}
                              <span className="strike-amount">
                                {" "}
                                {currency}
                                {paymentMode == "online"
                                  ? total - PaymentCharges.online_charges
                                  : total - PaymentCharges.cod_charges}
                              </span>
                              &nbsp; {currency}
                              {paymentMode == "online"
                                ? total
                                : total - PaymentCharges.cod_charges + PaymentCharges.online_charges}
                            </label>
                          </span>
                        </div>
                      ) : null}
                      <span className="subtitle">Pay via your saved cards/wallets/UPI on PayTM App</span>
                      <span className="cards-description">
                        <span className="card-ico">
                          <img
                            alt="Paytm Logo (standalone)"
                            src={makePublicURL("img/paytm.svg")}
                            style={{ height: 32 }}
                          />
                        </span> */}
                {/* <span className="card-ico">
                          <img src={makePublicURL("img/master_card.png")} />{" "}
                        </span>
                        <span className="card-ico">
                          <img src={makePublicURL("img/rupay.png")} />{" "}
                        </span> */}
                {/* </span>
                      {newPaymentFlow &&
                      paymentOpen &&
                      (currentState === "activatePayment" || currentState === "proceedPayStep") ? (
                        <div className="loadingCtn" style={{ backgroundColor: paymentBg }}>
                          <span className="h">
                            {paymentHeading}
                            {paymentLoader && (
                              <>
                                <span class="loader__dot">.</span>
                                <span class="loader__dot">.</span>
                                <span class="loader__dot">.</span>
                              </>
                            )}
                          </span>
                          <span className="subtitle">{paymentSubtitle}</span>
                          <span className="subtitle">{`${suggestion} ${timerVal}`}</span>
                          {displayTryAgain && placeOrderBtnDisabled ? <span className="dummyText">or</span> : null}

                          {displayTryAgain && placeOrderBtnDisabled ? (
                            <button className="subtitle tryAgain" onClick={tryAgainPayment} type="button">
                              Try Again Now
                            </button>
                          ) : null} */}
                {/* <span className="subtitle">If debited, your payment is secure with us</span> */}
                {/* </div>
                      ) : null}
                      {tryAgainEnabled ? (
                        <div className="loadingCtn" style={{ backgroundColor: paymentBg }}>
                          <span className="h">We could not verify your payment!</span>
                          <span className="subtitle">If the amount is debited, please contact our support</span>
                          <span className="subtitle">otherwise you can try again</span> */}
                {/* <span className="subtitle">If debited, your payment is secure with us</span> */}
                {/* </div>
                      ) : null}
                    </span>
                    <label className="radio-wrap">
                      <input ref={paytmModeRef} type="radio" name="payment-type-radio" />{" "}
                      <span className="radio-checkmark" />
                    </label>
                  </span>
                </span>
              </li> */}

                <li className="payment-item" onClick={(e) => handlePaymentModeSelectionClick(e, CODModeRefMobile)}>
                  {" "}
                  <span className="step-content">
                    <img src={makePublicURL("img/cash.png")} alt="" />{" "}
                    <span className="address-wrap">
                      <span className="payment-system">
                        <span className="address-title">
                          Cash on delivery (COD) &nbsp;
                          {paymentChargesObj.cod_charges && paymentChargesObj.cod_charges !== 0 ? (
                            <label className="payment-label cod-label ">
                              <strong>COD charges Rs.{paymentChargesObj.cod_charges}</strong>
                            </label>
                          ) : null}
                        </span>
                        {/* {paymentCharges && paymentCharges !== 0 ? (
                          <div className="payment-charge">
                            <span className="payment-label-mobile cod-label">
                              <label className="online-banner">
                                <strong>COD charges Rs.{paymentCharges}</strong>
                                <br></br>
                                Pay {currency}
                                {finalTotalPrice}
                                {paymentMode == "online"
                                  ? finalTotalPrice +
                                    PaymentCharges.cod_charges -
                                    PaymentCharges.online_charges -
                                    additionalOff -
                                    natCashApplied
                                  : finalTotalPrice - additionalOff - natCashApplied}
                              </label>
                            </span>
                          </div>
                        ) : null} */}
                        {/* {
                        PaymentCharges.cod_charges && PaymentCharges.cod_charges !== 0 ?
                          <span className="subtitle">COD charges Rs.{PaymentCharges.cod_charges}</span>
                          :
                          <span className="subtitle">Please keep exact change handy to help us serve you better</span>
                      } */}

                        <span className="subtitle">Please keep exact change handy to help us serve you better</span>
                      </span>
                      <label className="radio-wrap">
                        <input
                          ref={CODModeRefMobile}
                          type="radio"
                          name="payment-type-radio-desktop"
                          disabled={placeOrderBtnDisabled}
                          // checked={paymentMode == "cod" ? true : false}
                        />{" "}
                        <span className="radio-checkmark" />
                      </label>
                    </span>
                  </span>
                </li>
              </ul>

              <div className="wrap-btn">
                <button
                  ref={makePaymentRefMobile}
                  className="form-btn changePaymentJs d-inline"
                  onClick={makePayment}
                  disabled={placeOrderButtonLoading || placeOrderBtnDisabled || isLoading}
                  style={{
                    backgroundColor: placeOrderButtonLoading || placeOrderBtnDisabled || isLoading ? "grey" : "",
                  }}
                >
                  {" "}
                  {paymentMode == "online" ? "Make Payment" : "Place Order"}
                  {placeOrderButtonLoading ? (
                    <i
                      className="fa fa-circle-o-notch fa-spin ml-2"
                      style={{
                        marginRight: 10,
                      }}
                    ></i>
                  ) : null}
                </button>

                <ErrorMessage message={orderErrorMessage} setOrderErrorMessage={setOrderErrorMessage} />
              </div>
            </div>
          </div>
        </div>

        <div className="payment-options-mobile">
          <div
            className="step-body"
            style={{
              paddingTop: "0px",
            }}
          >
            {" "}
            <div className="step-choose-payment stepChoosePaymentJs">
              <span className="step-title">Preferred Payment Option</span>

              <ul className="payment-list">
                <li className="payment-item">
                  <span
                    className="payment-mode-mobile step-content"
                    onClick={(e) => handlePaymentModeSelectionClick(e, razorpayModeRef)}
                  >
                    <img src={makePublicURL("img/cards.png")} alt="" />{" "}
                    <span className="address-wrap">
                      <span className="payment-system">
                        <span className="address-title">Cards, Netbanking, Wallets and UPI &nbsp;</span>
                        {paymentChargesObj.online_charges && paymentChargesObj.online_charges !== 0 ? (
                          <div className="payment-charge">
                            <span className="payment-label-mobile">
                              <label className="online-banner">
                                <strong>Extra Rs.{paymentChargesObj.online_charges * -1} OFF</strong>
                                <br></br>
                                Pay only{" "}
                                <span className="strike-amount">
                                  {" "}
                                  {currency}
                                  {finalTotalPrice}
                                  {/* {paymentMode == "online"
                                    ? finalTotalPrice - PaymentCharges.online_charges
                                    : finalTotalPrice - PaymentCharges.cod_charges} */}
                                </span>
                                &nbsp; {currency}
                                {finalTotalPrice - paymentCharges}
                                {/* {paymentMode === "online"
                                  ? finalTotalPrice
                                  : finalTotalPrice - paymentCharges + paymentCharges} */}
                              </label>
                            </span>
                          </div>
                        ) : null}
                        <span className="subtitle">Powered by Razorpay</span>
                        <span className="cards-description">
                          <span className="card-ico">
                            <img src={makePublicURL("img/visa.png")} />{" "}
                          </span>
                          <span className="card-ico">
                            <img src={makePublicURL("img/master_card.png")} />{" "}
                          </span>
                          <span className="card-ico">
                            <img src={makePublicURL("img/rupay.png")} />{" "}
                          </span>
                        </span>
                      </span>

                      <label className="radio-wrap">
                        <input ref={razorpayModeRef} type="radio" name="payment-type-radio" />{" "}
                        <span className="radio-checkmark" />
                      </label>
                    </span>
                  </span>
                  {paymentMode == "online" ? (
                    <div className="payment-btn-design">
                      <button
                        ref={makePaymentRef}
                        className={
                          paymentButtonClicked
                            ? "form-btn is-active changePaymentJs d-inline"
                            : "form-btn changePaymentJs d-inline"
                        }
                        onClick={makePayment}
                        disabled={placeOrderButtonLoading || placeOrderBtnDisabled || isLoading}
                        style={{
                          backgroundColor:
                            placeOrderButtonLoading || placeOrderBtnDisabled || isLoading ? "grey" : "#38A176",
                          maxWidth: "70%",
                        }}
                      >
                        <span style={{ fontSize: "14px" }}>Make Payment</span>
                        {placeOrderButtonLoading ? (
                          <i
                            className="fa fa-circle-o-notch fa-spin ml-2"
                            style={{
                              marginRight: 10,
                            }}
                          ></i>
                        ) : null}
                      </button>
                    </div>
                  ) : null}
                </li>
              </ul>
              <span className="step-title">More Payment Options</span>

              <ul className="payment-list ">
                <li className="payment-item">
                  {" "}
                  <span
                    className="payment-mode-mobile step-content"
                    onClick={(e) => handlePaymentModeSelectionClick(e, CODModeRef)}
                  >
                    <img src={makePublicURL("img/cash.png")} alt="" />{" "}
                    <span className="address-wrap">
                      <span className="payment-system">
                        <span className="address-title">Cash on delivery (COD) &nbsp;</span>
                        {paymentChargesObj.cod_charges && paymentChargesObj.cod_charges !== 0 ? (
                          <div className="payment-charge">
                            <span className="payment-label-mobile cod-label">
                              <label className="online-banner">
                                <strong>COD charges Rs. {paymentChargesObj.cod_charges}</strong>
                                <br></br>
                                {paymentMode === "cod" ? (
                                  <div>
                                    {isLoading ? (
                                      <div className="cod-payment-loader"></div>
                                    ) : (
                                      <div>
                                        Pay {currency}
                                        {finalTotalPrice}
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                                {/* {paymentMode == "online"
                                  ? finalTotalPrice +
                                    PaymentCharges.cod_charges -
                                    PaymentCharges.online_charges -
                                    additionalOff -
                                    natCashApplied
                                  : finalTotalPrice - additionalOff - natCashApplied} */}
                              </label>
                            </span>
                          </div>
                        ) : null}

                        <span className="subtitle">Please keep exact change handy to help us serve you better</span>
                      </span>
                      <label className="radio-wrap">
                        <input
                          ref={CODModeRef}
                          type="radio"
                          name="payment-type-radio"
                          disabled={placeOrderBtnDisabled}
                        />{" "}
                        <span className="radio-checkmark" />
                      </label>
                    </span>
                  </span>
                  {paymentMode == "cod" ? (
                    <div className="payment-btn-design">
                      <button
                        ref={makePaymentRef}
                        className={
                          paymentButtonClicked
                            ? "form-btn is-active changePaymentJs d-inline"
                            : "form-btn changePaymentJs d-inline"
                        }
                        onClick={makePayment}
                        disabled={placeOrderButtonLoading || placeOrderBtnDisabled || isLoading}
                        style={{
                          backgroundColor:
                            placeOrderButtonLoading || placeOrderBtnDisabled || isLoading ? "grey" : "#38A176",
                          maxWidth: "70%",
                        }}
                      >
                        {" "}
                        <span style={{ fontSize: "14px" }}>Place Order</span>
                        {placeOrderButtonLoading ? (
                          <i
                            className="fa fa-circle-o-notch fa-spin ml-2"
                            style={{
                              marginRight: 10,
                            }}
                          ></i>
                        ) : null}
                      </button>
                    </div>
                  ) : null}
                </li>
              </ul>

              <ErrorMessage message={orderErrorMessage} setOrderErrorMessage={setOrderErrorMessage} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
