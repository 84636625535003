import React, { useState, useRef, useEffect } from "react";
import { useCookies } from "react-cookie";

import { useSelector, useDispatch } from "react-redux";
import { addClass, hasClass, makePublicURL, mobileNumberValidator, removeClass } from "../../helpers";
import { WEBSITE_URL, NEW_WEBSITE_URL } from "../../helpers/constants";
import { TOGGLE_PAGE_LOADING_STATE } from "../../store/checkout/actions";
import ContactBlock from "../ContactBlock";

export default function EmptyCartRoutine() {
  const dispatch = useDispatch();
  const cartSelector = useSelector((state) => state.cart);
  const timerVal = 10;
  const [timer, setTimer] = useState(timerVal);
  const [cookies, setCookie] = useCookies();

  const countDownTimer = (timerVal) => {
    var x = setInterval(() => {
      timerVal--;
      setTimer(timerVal);
      if (timerVal <= 1) {
        clearInterval(x);
        window.location.href = cookies.homeUrl == "new.nathabit.in" ? NEW_WEBSITE_URL : WEBSITE_URL;
      }
    }, 1000);
  };

  useEffect(() => {
    dispatch({ type: TOGGLE_PAGE_LOADING_STATE });
    if (cartSelector.emptyCart) {
      countDownTimer(timerVal);
    }
    dispatch({ type: TOGGLE_PAGE_LOADING_STATE });
  }, [cartSelector.emptyCart]);

  return (
    <div className={`form-main success-step mt-3 w-75 mx-auto d-block`}>
      <div className="step-success stepMakePaymentJs">
        <div className="step-item failure-success success-section">
          <div className="success-row title-row">
            <div id="success_state">
              <div className="img-wrap">
                <img
                  src={makePublicURL("img/empty-cart.png")}
                  alt=""
                  className="phone-img"
                  style={{ width: "160px" }}
                />
              </div>
              <div className="description mt-2">
                <p className="h3">Your cart is currently empty</p>
                <p className="h6">Redirecting you to store in {timer}</p>
              </div>
            </div>
          </div>
          <ContactBlock />
        </div>
      </div>
    </div>
  );
}
