import "./App.css";
import Routes from "./routes/Routes";

import { Provider } from "react-redux";
import store from "./store";
import * as Sentry from "@sentry/react";

function App() {

  function getPageType(param = null) {
    let pathname;
    if (param) {
      pathname = param;
    } else {
      pathname = window.location.pathname;
    }

    if (pathname === "/" || pathname[1] === "?") return "homepage";
    const pathSplit = pathname.split("/");

    if (pathSplit.includes("products")) return "product";
    else if (pathSplit.includes("collections")) return "collection";
    else if (pathSplit.includes("blogs")) return "blog";
    else if (pathSplit.includes("pages")) return "shopifyPage";
    else if (pathSplit.includes("account")) return "account";
  }

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function getPageValue(param = null) {
    let pathname;
    if (param) {
      pathname = param;
    } else {
      pathname = window.location.pathname;
    }
    // const pathname = param ? param :
    if (pathname === "/" || pathname[1] === "?") return "Homepage";
    const pathSplit = pathname.split("/");
    return toTitleCase(pathSplit[pathSplit.length - 1].replaceAll("-", " "));
    // if (pathSplit.includes("products")) return "product";
    // else if (pathSplit.includes("collections")) return "collection";
    // else if (pathSplit.includes("blogs")) return "blog";
    // else if (pathSplit.includes("pages")) return "shopifyPage";
  }

  function getUrlParam(paramName) {
    const urlParams = new URLSearchParams(window.location.search);
    const paramVal = urlParams.get(paramName);
    return paramVal;
  }


  window.getUrlParam = getUrlParam;
  window.getPageValue = getPageValue;
  window.getPageType = getPageType;
  window.toTitleCase = toTitleCase;

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
