import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makePublicURL, useQuery } from "../../helpers";
import SuccessCartSidebar from "../cart/SuccessCartSidebar";
import ContactBlock from "../ContactBlock";

export default function InvalidCheckoutBlock() {
	const checkoutSelector = useSelector((state) => state.checkout);
	const queryParams = useQuery();
	return (
		<div className={`form-main success-step mt-3 w-75 mx-auto`}>
			<div className="step-success stepMakePaymentJs m-auto">
				<div className="step-item failure-success success-section">
					<div className="success-row title-row">
						{checkoutSelector.checkoutId ? (
							<div id="success_state">
								<img
									src={makePublicURL("img/success-img.png")}
									alt=""
									className="main-img"
								/>
								<span className="subtitle">
									Order Id : {checkoutSelector.checkoutId}
								</span>
								<span className="step-title">
									{checkoutSelector.checkoutErrorMessage}
								</span>
							</div>
							
						) : checkoutSelector.checkoutId == null ? (
							<div id="404_state">
								<img
									src={makePublicURL("img/404-img.png")}
									alt=""
									className="main-img"
								/>
								<span className="step-title">
									{checkoutSelector.checkoutErrorMessage}
								</span>
							</div>
						) : (
							<></>
						)}
					</div>				
					<ContactBlock />
				</div>
				
			</div>
		</div>
	);
}
