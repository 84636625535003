import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addClass, makePublicURL, removeClass } from "../../helpers";
import SuccessCartSidebar from "../cart/SuccessCartSidebar";
import VerifyPhoneStepComponent from "../login/VerifyPhoneStepComponent";
import "./scss/response.scss";
import VerifyGuestPhone from "./VerifyGuestPhone";
import { COD, GUEST_USER, VERIFIED_USER } from "../../helpers/constants";
import ContactBlock from "../ContactBlock";
import { getAddressAgainstOrder } from "../../helpers/addressStorage";

export default function SuccessPaymentResponse() {
  const checkoutSelector = useSelector((state) => state.checkout);
  const cartSelector = useSelector((state) => state.cart);
  const loginSelector = useSelector((state) => state.login);
  const paymentSelector = useSelector((state) => state.payment);
  const { orderID } = checkoutSelector;
  const { firstName } = loginSelector;
  const { outstation } = cartSelector;
  const remindBtnRef = useRef();
  const mobileSuccessCartRef = useRef();
  const successStateRef = useRef();
  const pendingStateRef = useRef();
  const verificationRef = useRef();
  const storedAddressRef = useRef();
  const { paymentMode } = paymentSelector;
  const storeAddress = getAddressAgainstOrder(orderID);

  const handleReminderSetClick = () => {
    addClass(remindBtnRef, "is-disabled");
    removeClass(remindBtnRef, "green-btn");
  };

  const handleResize = (e) => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      removeClass(mobileSuccessCartRef, "d-none");
    } else {
      addClass(mobileSuccessCartRef, "d-none");
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  async function loadPaytmScript() {
    const res = await loadScript("https://evt.paytm.com/pixel/frontend/pixel-1P.js?token=257D3E2E96834953");
  }

  useEffect(() => {
    if (window.location.href.includes("/order/placed/thank_you")) {
      loadPaytmScript();
    }
  }, []);

  useEffect(() => {
    if (checkoutSelector.activeCheckout) {
      if (loginSelector.mode === GUEST_USER) {
        addClass(successStateRef, "d-none");
        removeClass(pendingStateRef, "d-none");
        removeClass(verificationRef, "d-none");
        addClass(storedAddressRef, "d-none");
      } else {
        removeClass(successStateRef, "d-none");
        addClass(pendingStateRef, "d-none");
        addClass(verificationRef, "d-none");
        removeClass(storedAddressRef, "d-none");
      }
    } else {
      if (paymentMode === "online" || loginSelector.mode === VERIFIED_USER) {
        removeClass(successStateRef, "d-none");
        addClass(pendingStateRef, "d-none");
        addClass(verificationRef, "d-none");
        removeClass(storedAddressRef, "d-none");
      } else {
        addClass(storedAddressRef, "d-none");
        addClass(successStateRef, "d-none");
        removeClass(pendingStateRef, "d-none");
        removeClass(verificationRef, "d-none");
      }
    }
  }, [loginSelector.mode, checkoutSelector.activeCheckout, paymentMode]);

  return (
    <div className="step-success stepMakePaymentJs">
      <div className="step-item failure-success success-section">
        <div ref={successStateRef} className="success-row title-row">
          <div id="success_state" className="success-container">
            <div className="order-section text-left">
              {/* <img src={makePublicURL("img/success-img.png")} alt="" className="main-img" style={{ maxWidth: 70 }} /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                // width="16"
                // height="16"
                fill="#38a176"
                className="bi bi-check2-circle main-img"
                viewBox="0 0 16 16"
                style={{ maxWidth: 75 }}
              >
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
              </svg>
              <div style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}>
                <div className="order-name-container">
                  Order &nbsp;<strong className="order-name">{orderID}</strong>
                </div>
                <div className="step-title">{`Thank You${
                  /\d/.test(firstName) && firstName !== "" ? "" : ` ${firstName}`
                }!`}</div>
              </div>
            </div>
            <div className="order-conditional-msg-container">
              <div className="order-conditional-msg">
                <div className="order-info confirmed">Your Order is Confirmed</div>
                <div className="order-info">
                  {outstation
                    ? "Your Order will be shipped in 2-3 days and will be delivered in 4-5 days."
                    : "Order will be delivered to you in 2-3 days."}
                </div>
                <div className="order-info">
                  {paymentMode === "online" ? "" : "Please pay on delivery of the product to the courier partner."}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={pendingStateRef} id="pending_state" style={{ paddingTop: 30 }}>
          {paymentMode === "online" ? (
            <>
              <img src={makePublicURL("img/success-img.png")} alt="" className="main-img" />
              <span className="step-title">Order Confirmed</span>
            </>
          ) : (
            <>
              <img
                src={"https://img.icons8.com/ios/452/one-time-password.png"}
                alt=""
                className="main-img"
                style={{ height: "100px" }}
              />
              <span className="step-title">Verify Phone Number</span>
              <span className="subtitle">Now just verify your phone number to finalize the order</span>
            </>
          )}

          <span className="subtitle">
            Please use Order ID: &nbsp;
            <strong style={{ color: "#344254" }}>{orderID}</strong>
            &nbsp;&nbsp;for future reference
          </span>
          <img
            style={{ width: "35px" }}
            src={"https://whataftercollege.com/wp-content/uploads/2019/12/wac-down-arrow-gif-2.gif"}
          />
        </div>
        {storeAddress && Object.keys(storeAddress).length !== 0 ? (
          <div className="success-row title-row" ref={storedAddressRef}>
            <div className="container-fluid p-0 p-md-3">
              <div className="row">
                {/* <div className="col-md-6 my-2">
                <span className="step-title justify-content-start text-left">
                  Customer Information
								</span>
                <span className="text-left text-muted d-block">
                  Mahesh Medam
								</span>
                <span className="text-left text-muted d-block">
                  +91 9932272424
								</span>
                <span className="text-left text-muted d-block">
                  maheshfreelance0@gmail.com
								</span>
              </div> */}
                <div className="col-md-12 my-2">
                  <span className="step-title justify-content-start text-left">Shipping Address</span>
                  <span className="text-left text-muted d-block" style={{ paddingTop: 5 }}>
                    {storeAddress.fullName}
                  </span>
                  <span className="text-left text-muted d-block">{storeAddress.apartmentName}</span>
                  <span className="text-left text-muted d-block">{storeAddress.addressLocation}</span>
                  <span className="text-left text-muted d-block">{`${storeAddress.city} - ${storeAddress.pincode}`}</span>
                  <span className="text-left text-muted d-block">{storeAddress.mobileNumber}</span>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div ref={verificationRef} className="success-row subtitle-row">
          <VerifyGuestPhone storedAddress={storeAddress} />
        </div>

        <div ref={mobileSuccessCartRef} className="cart-responsive cartResponsiveJs">
          <SuccessCartSidebar />
        </div>
        <ContactBlock />

        {/* <div className="success-row subtitle-row">
          <span className="wrap-img">
            <img src={makePublicURL("img/alarm-ico.png")} alt="" />
            Send me reminders to use the product regularly
          </span>
          <button ref={remindBtnRef} type="button" className="form-btn green-btn remindBtsJs">
            <span onClick={handleReminderSetClick} className="active-btn">
              Remind me
            </span>
            <span className="disabled-btn">
              <span className="checkmark" />
              <span>Reminder set</span>
            </span>
          </button>
        </div> */}
      </div>
    </div>
  );
}
