var testObject = { one: 1, two: 2, three: 3 };

// Put the object into storage
localStorage.setItem("testObject", JSON.stringify(testObject));

export const storeAddressAgainstOrder = (orderName, address) => {
  const addressData = { orderName: orderName, address: JSON.stringify(address) };
  const retrievedObject = JSON.parse(localStorage.getItem("adddressObject"));
  let fd = [];
  if (retrievedObject) {
    fd = [...retrievedObject, addressData];
  } else {
    fd = [addressData];
  }
  localStorage.setItem("adddressObject", JSON.stringify(fd));
};

export const getAddressAgainstOrder = (order) => {
  var retrievedObject = JSON.parse(localStorage.getItem("adddressObject"));
  var finalData = {};
  if (retrievedObject) {
    retrievedObject.forEach((element) => {
      if (element.orderName === order) {
        finalData = JSON.parse(element.address);
      }
    });
  }
  return finalData;
};

export const saveNewAddressInLocalStorage = (data) => {
  const addressData = { ...data };
  const retrievedObject = JSON.parse(localStorage.getItem("newAddressStorage"));
  let fd = [];
  if (retrievedObject) {
    fd = [...retrievedObject, addressData];
  } else {
    fd = [addressData];
  }

  localStorage.setItem("newAddressStorage", JSON.stringify(fd));
};
export const getSavedAddressFromLocalStorage = () => {
  var retrievedObject = JSON.parse(localStorage.getItem("newAddressStorage"));
  if (retrievedObject) {
    return retrievedObject;
  } else {
    return [];
  }
};

export const deleteAddressData = () => {
  localStorage.removeItem("newAddressStorage");
  localStorage.removeItem("guestAddressesID")
};

export const updateAddressInLocalStorage = (data) => {
  var retrievedObject = JSON.parse(localStorage.getItem("newAddressStorage"));
  let sliceIndex = null;
  if (retrievedObject) {
    retrievedObject.forEach((element, index) => {
      if (element.addressID === data.addressID) {
        sliceIndex = index;
      }
    });
    retrievedObject.splice(sliceIndex, 1, data);
    var fd = [...retrievedObject];
    localStorage.setItem("newAddressStorage", JSON.stringify(fd));
  }
  return;
};
