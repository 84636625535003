const responseChecker = async (response) => {
  let error = "";
  let data = {};
  let statusCode = null;
  if (!response.ok) {
    error = "Something went wrong";
  }
  statusCode = response.status;
  data = await response.json();
  return { statusCode, error, data };
};

export function getAccessToken() {
  return (
    document.cookie
      .split("; ")
      .find((row) => row.startsWith("access_token"))
      ?.split("=")[1] || null
  );
}

const fetchFn = (baseURL, authHeader = null, mode = null, disabledKey = false) => {
  const defaultHeader = {
    Accept: "application/json",
    // "Content-Type": "application/x-www-form-urlencoded",
    "Content-Type": "application/json",
    Authorization: authHeader,
  };

  if (!disabledKey) defaultHeader.key = "nHabit";

  // if (mode) defaultHeader["mode"] = mode;

  const customFetch = (
    url,
    method = "GET",
    body = false,
    headers = defaultHeader,
    credentials = "include",
    signal = null
  ) => {
    const options = {
      method,
      headers,
      credentials,
      signal,
    };
    // if (mode) options.mode = mode
    if (body) options.body = JSON.stringify(body);
    return fetch(url, options);
  };
  const get = async (endpoint) => {
    const url = `${baseURL}${endpoint}`;
    const response = await customFetch(url, "GET", false, {
      ...defaultHeader,
      Authorization: `Bearer ${getAccessToken()}`,
    });
    return responseChecker(response);
  };
  const post = async (endpoint, body = {}, credentials, signal) => {
    const url = `${baseURL}${endpoint}`;
    const response = await customFetch(
      url,
      "POST",
      body,
      {
        ...defaultHeader,
        Authorization: `Bearer ${getAccessToken()}`,
      },
      credentials,
      signal
    );
    return responseChecker(response);
  };
  const remove = async (endpoint, body = {}) => {
    const url = `${baseURL}${endpoint}`;
    const response = await customFetch(url, "DELETE", body, {
      ...defaultHeader,
      Authorization: `Bearer ${getAccessToken()}`,
    });
    return responseChecker(response);
  };
  const put = async (endpoint, body = {}) => {
    const url = `${baseURL}${endpoint}`;
    const response = await customFetch(url, "PUT", body, {
      ...defaultHeader,
      Authorization: `Bearer ${getAccessToken()}`,
    });
    return responseChecker(response);
  };
  const patch = async (endpoint, body = {}) => {
    const url = `${baseURL}${endpoint}`;
    const response = await customFetch(url, "PATCH", body, {
      ...defaultHeader,
      Authorization: `Bearer ${getAccessToken()}`,
    });
    return responseChecker(response);
  };

  return {
    get,
    post,
    put,
    patch,
    remove,
  };
};
export default fetchFn;
