import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";

// import { composeWithDevTools } from "redux-devtools-extension";

import { cartReducer } from "./cart/reducers";
// import { createLogger } from "redux-logger";

import { checkoutReducer } from "./checkout/reducers";
import { paymentReducer } from "./payment/reducers";
import { loginReducer } from "./login/reducers";
import { addressReducer } from "./address/reducers";

const rootReducer = combineReducers({
  checkout: checkoutReducer,
  payment: paymentReducer,
  cart: cartReducer,
  login: loginReducer,
  address: addressReducer,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

// export default function configureStore() {
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer));

export default store;
