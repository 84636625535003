import { cartAPI } from "../../helpers/api/index";
import { PAYMENT_DONE } from "../checkout/actions";

export const PAYMENT_MODE = "paymentMode";

export const createOrder =
  (formData, mode = "placeOrder") =>
  async (dispatch) => {
    // const { data, statusCode } = await cartAPI.post("/new/checkout/place/order", formData);
    const { data, statusCode } = await cartAPI.post("/api/v1/checkout/place/order", formData);
    if (statusCode === 201) {
      dispatch({
        type: PAYMENT_DONE,
      });
    }
  };

export const changePaymentMode = (formData, paymentMode) => async (dispatch) => {
  dispatch({
    type: PAYMENT_MODE,
    payload: paymentMode,
  });
  dispatch(createOrder(formData, "updateOnly"));
};
