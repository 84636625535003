export default function BillDetailsSkeletonLoader() {
  return (
    <div className="bill-details-skeleton-loader">
      <div className="skeleton-heading pulse"></div>

      <div className="skeleton-section">
        <div className="skeleton-title-container">
          <div className="skeleton-title pulse"></div>
          <div className="skeleton-title pulse"></div>
        </div>

        <div className="skeleton-title-container">
          <div className="skeleton-title-long pulse"></div>
          <div className="skeleton-title pulse"></div>
        </div>

        <div className="skeleton-title-container">
          <div className="skeleton-title-longer pulse"></div>
          <div className="skeleton-title-short pulse"></div>
        </div>
      </div>

      <div className="skeleton-footer-container">
        <div className="skeleton-footer">
          <div className="skeleton-footer-title pulse"></div>
          <div className="skeleton-footer-title pulse"></div>
        </div>
      </div>
    </div>
  );
}
