export const sendDataToSlack = (requestParam, response, statusCode) => {
  const webhookUrl = "https://hooks.slack.com/services/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";
  let url = `https://checkout.nathabit.in/?cart_id=${requestParam.product}`;
  let color = "#FF0000";
  statusCode = statusCode.toString();

  let formData1 = JSON.stringify(requestParam);
  let formData = "```" + formData1 + "```";
  let data1 = JSON.stringify(response);
  let data = "```" + data1 + "```";

  let attachments = [
    {
      color: color,
      blocks: [
        {
          type: "header",
          text: {
            type: "plain_text",
            text: statusCode,
            emoji: true,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Cart URL: <${url}| url>`,
          },
        },
        {
          type: "divider",
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: formData,
          },
        },
        {
          type: "divider",
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: data,
          },
        },
      ],
    },
  ];

  fetch(webhookUrl, {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify({ attachments }),
    mode: "no-cors",
  });
};


export const sendDataToSlackForAddress = (requestParam, response, statusCode) => {
  const webhookUrl = "https://hooks.slack.com/services/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";
  let url = `https://checkout.nathabit.in/`;
  let color = "#FF0000";
  statusCode = statusCode.toString();

  let formData1 = JSON.stringify(requestParam);
  let formData = "```" + formData1 + "```";
  let data1 = JSON.stringify(response);
  let data = "```" + data1 + "```";

  let attachments = [
    {
      color: color,
      blocks: [
        {
          type: "header",
          text: {
            type: "plain_text",
            text: statusCode,
            emoji: true,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `Address Error: <${url}| url>`,
          },
        },
        {
          type: "divider",
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: formData,
          },
        },
        {
          type: "divider",
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: data,
          },
        },
      ],
    },
  ];

  fetch(webhookUrl, {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify({ attachments }),
    mode: "no-cors",
  });
};
