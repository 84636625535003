import {
	ACTIVATE_LOGIN,
	ACTIVATE_ADDRESS,
	ACTIVATE_PAYMENT,
	REMOVE_OVERLAY,
	SHOW_MODAL,
	CHANGE_MODAL_KEY,
	CLOSE_MODAL,
	DISABLE_ADDRESS,
	ENABLE_ADDRESS,
	CHANGE_ADDRESS,
	CHANGE_ADDRESS_BACK_BUTTON,
	PROCEED_PAY_STEP,
	PAYMENT_DONE,
	BACK_TO_ADDRESS,
	TOGGLE_PAGE_LOADING_STATE,
	UPDATE_VALID_CHECKOUT,
	SET_ORDER_ID,
} from "./actions";

const checkoutInitialState = {
	pageInitialLoaded: false,
	validCheckout: true,
	checkoutErrorMessage: "",
	checkoutMessage: "",
	currentState: "",
	removeOverlay: false,
	showModal: false,
	modalKey: "",
	disableAllAddresses: false,
	orderID: "",
	activeCheckout: true,
	verifiedCart: true,
};

export const checkoutReducer = (state = checkoutInitialState, action) => {
	switch (action.type) {
		case ACTIVATE_LOGIN:
			return {
				...state,
				currentState: ACTIVATE_LOGIN,
			};
		case ACTIVATE_ADDRESS:
			return {
				...state,
				currentState: ACTIVATE_ADDRESS,
			};
		case ACTIVATE_PAYMENT:
			return {
				...state,
				currentState: ACTIVATE_PAYMENT,
			};

		case PROCEED_PAY_STEP:
			return {
				...state,
				currentState: PROCEED_PAY_STEP,
			};
		case REMOVE_OVERLAY:
			return {
				...state,
				removeOverlay: action.payload.removeOverlay,
			};
		case SHOW_MODAL:
			return {
				...state,
				showModal: true,
				modalKey: Math.random(),
			};
		case CHANGE_MODAL_KEY:
			return {
				...state,
				modalKey: Math.random(),
			};
		case CLOSE_MODAL:
			return {
				...state,
				showModal: false,
			};
		case CHANGE_ADDRESS:
			return {
				...state,
				currentState: CHANGE_ADDRESS,
			};
		case CHANGE_ADDRESS_BACK_BUTTON:
			return {
				...state,
				currentState: CHANGE_ADDRESS_BACK_BUTTON,
			};

		case DISABLE_ADDRESS:
			return {
				...state,
				disableAllAddresses: true,
			};
		case ENABLE_ADDRESS:
			return {
				...state,
				disableAllAddresses: false,
			};
		case PAYMENT_DONE:
			return {
				...state,
				currentState: PAYMENT_DONE,
			};

		case BACK_TO_ADDRESS:
			return {
				...state,
				currentState: BACK_TO_ADDRESS,
			};
		case TOGGLE_PAGE_LOADING_STATE:
			return {
				...state,
				pageInitialLoaded: !state.pageInitialLoaded,
			};
		case UPDATE_VALID_CHECKOUT:
			return {
				...state,
				...action.payload
			};
		case SET_ORDER_ID:
			return {
				...state,
				orderID: action.payload,
			};
		default:
			return state;
	}
};
