import React from "react";
import { PHONE_NUMBER } from "../helpers/constants";

export default function ContactBlock() {
  return (
    <div className="success-row wa">
      <span className="subtitle">For updates, reach out to us on this number.</span>

      <p className="tel">
        <i
          className="fa fa-whatsapp"
          aria-hidden="true"
          style={{ marginRight: 10, fontSize: 20, color: "#075e54" }}
        ></i>
        {PHONE_NUMBER}
      </p>
    </div>
  );
}
