import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { addClass, hasClass, makePublicURL, mobileNumberValidator, removeClass, validateEmail } from "../../helpers";
import { ACTIVATE_ADDRESS, REMOVE_OVERLAY, CHANGE_MODAL_KEY } from "../../store/checkout/actions";
import { GET_LOCAL_ADDRESS } from "../../store/address/actions";
import OtpInput from "react-otp-input";
import {
  UPDATE_LOGIN,
  sentVerificationCode,
  ADD_CONTACT,
  verifyCode,
  UPDATE_OTP_ERROR_MESSAGE,
  getCustomerDataV2,
  UPDATE_OPTIONAL_EMAIL,
  updateCartInfo,
  guestUserData,
} from "../../store/login/actions";
import "./scss/login.scss";
import { EMPTY, GUEST_USER, INVALID, VALID, VERIFIED_USER } from "../../helpers/constants";
import { sendEvent, sendCheckoutOptionGoogleAnalytics } from "../../helpers/ga";
import { setDomainCookie, getPhoneCookie } from "../../helpers/cookies";
import { getSavedAddressFromLocalStorage } from "../../helpers/addressStorage";
import LoadingBlock from "../checkout/LoadingBlock";
import { isElement } from "react-dom/test-utils";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { updateDiscount } from "../../store/cart/actions";

export default function VerifyPhoneStepComponent() {
  const antIcon = <LoadingOutlined style={{ fontSize: 20, color: "white" }} spin />;

  const loginSelector = useSelector((state) => state.login);
  const loading = loginSelector.loading;
  const phoneNo = loginSelector.phoneNumber;
  const mode = loginSelector.mode;
  const [phoneNumber, setPhoneNumber] = useState(loginSelector.phoneNumber);
  const [email, setEmail] = useState(loginSelector.customerEmail);
  const [emailValidationError, setEmailValidationError] = useState("");
  const [OTP, setOTP] = useState(loginSelector.OTP);
  const [phoneNumberValidationMessage, setPhoneNumberValidationMessage] = useState("");
  const [guestLoginDisabled, setGuestLoginDisabled] = useState(true);
  const { checkoutId, productDetails, currency, finalTotalPrice, paymentCharges, discountReason, cartId } =
    useSelector((state) => state.cart);

  const phoneRegExp = new RegExp(/^(?=.*[0-9])[+0-9]+$/);

  const phoneNumberInputRef = useRef();
  const phoneNumberVerifyButtonRef = useRef();
  const wrapOTPRef = useRef();
  const stepOneRef = useRef();
  const editButtonRef = useRef();
  const wrapNumberRef = useRef();
  const guestLoginRef = useRef();
  const verifyPhoneWrapTitleRef = useRef();
  const verifyPhoneWrapSubTitleRef = useRef();

  const stepOneContentClassRef = useRef();
  const stepOneSuccessLoginWrapClassRef = useRef();
  const OTPVerifyButtonRef = useRef();
  const resendBtnRef = useRef();
  const timerValueJsRef = useRef();
  const [timerVal, setTimerVal] = useState("");
  const [cookies, setCookie] = useCookies();
  const dispatch = useDispatch();

  const [showLoginForm, setShowLoginForm] = useState(true);
  const loginModalBackdropRef = useRef();
  const checkoutSelector = useSelector((state) => state.checkout);

  useEffect(() => {
    if (cookies.tempUserPhone) {
      validatePhoneNumber(cookies.tempUserPhone);

      let phoneNumberValue = cookies.tempUserPhone;
      if (!phoneRegExp.test(phoneNumberValue)) {
        // Replace anything that isn't a number or a plus sign
        phoneNumberValue = phoneNumberValue.replace(/([^+0-9]+)/gi, "");
      }
      if (phoneNumberValue.length > 9 && phoneNumberValue.length < 14) {
        addClass(phoneNumberVerifyButtonRef, "is-active");
      } else {
        removeClass(phoneNumberVerifyButtonRef, "is-active");
      }

      if (phoneNumberValue.length > 0) {
        addClass(phoneNumberInputRef, "valid");
        removeClass(phoneNumberInputRef, "empty_field");
      } else {
        removeClass(phoneNumberInputRef, "valid");
        addClass(phoneNumberInputRef, "empty_field");
      }
      setPhoneNumber(phoneNumberValue);
      if (phoneNumber.length === 10) {
        setGuestLoginDisabled(false);
      } else {
        setGuestLoginDisabled(true);
      }
    }
  }, []);

  useEffect(() => {
    if (phoneNo !== "") {
      // const expires = new Date();
      // expires.setDate(expires.getDate() + 180);
      // // setCookie("userPhone", "9999924738", { path: "/", expires });
      // if (mode === VERIFIED_USER) {
      //   // setCookie("userPhone", phoneNo, { path: "/", expires });
      //   // setPhoneCookie("userPhone", phoneNo);
      //   // setDomainCookie("userPhone", phoneNo, 180);
      //   if (!cookies.userPhone || cookies.userPhone === "undefined" || cookies.userPhone === "") {
      //     const expires = new Date();
      //     expires.setDate(expires.getDate() + 180);
      //     if (phoneNo && phoneNo.trim() !== "") {
      //       // setCookie("userPhone", phoneNo, { path: "/", domain: ".nathabit.in", expires });
      //       // setCookie("userPhone", phoneNo, { path: "/", expires });
      //     }
      //   }
      // }
      markStepOneComplete();
    }
  }, [phoneNo, mode]);

  // useEffect(() => {
  //   // const phoneCookie = getPhoneCookie();
  //   const phoneCookie = cookies.userPhone;
  //   if (phoneCookie && phoneCookie != "" && phoneCookie != "undefined") {
  //     dispatch(getCustomerDataV2(phoneCookie));
  //   }
  // }, []);

  useEffect(() => {
    const phoneCookie = cookies.userPhone;
    const accessToken = cookies.access_token;
    if (
      phoneCookie &&
      phoneCookie != "" &&
      phoneCookie != "undefined" &&
      accessToken &&
      accessToken != "" &&
      accessToken != "undefined"
    ) {
      dispatch(getCustomerDataV2(phoneCookie));
    }
  }, []);

  useEffect(() => {
    if (showLoginForm) {
      setTimeout(function () {
        if (phoneNumberInputRef.current) {
          phoneNumberInputRef.current.focus();
        }
      }, 1000);
    }
  }, [showLoginForm]);

  const validatePhoneNumber = (phoneNo = phoneNumber) => {
    const validationResult = mobileNumberValidator(phoneNo);
    if (validationResult == EMPTY) {
      setPhoneNumberValidationMessage("This field is required");
      return false;
    } else if (validationResult == VALID) {
      setPhoneNumberValidationMessage("");
      return true;
    } else if (validationResult == INVALID) {
      setPhoneNumberValidationMessage("Please specify a valid mobile number");
      return false;
    }
  };

  const startTimer = () => {
    var timer2 = "2:01";
    var interval = setInterval(function () {
      var timer = timer2.split(":");
      //by parsing integer, I avoid all extra string processing
      var minutes = parseInt(timer[0], 10);
      var seconds = parseInt(timer[1], 10);
      --seconds;
      minutes = seconds < 0 ? --minutes : minutes;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 0 ? 59 : seconds;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      //minutes = (minutes < 10) ?  minutes : minutes;
      setTimerVal(minutes + ":" + seconds);

      //check if both minutes and seconds are 0
      if (seconds <= 0 && minutes <= 0) {
        removeClass(resendBtnRef, "disable");
        removeClass(timerValueJsRef, "is-active");
        clearInterval(interval);
      }
      timer2 = minutes + ":" + seconds;
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handlePhoneNumberChange = (e, ref) => {
    validatePhoneNumber();

    let phoneNumberValue = e.target.value;
    if (!phoneRegExp.test(phoneNumberValue)) {
      // Replace anything that isn't a number or a plus sign
      phoneNumberValue = phoneNumberValue.replace(/([^+0-9]+)/gi, "");
    }
    if (phoneNumberValue.length > 9 && phoneNumberValue.length < 14) {
      addClass(phoneNumberVerifyButtonRef, "is-active");
    } else {
      removeClass(phoneNumberVerifyButtonRef, "is-active");
    }

    if (phoneNumberValue.length > 0) {
      addClass(phoneNumberInputRef, "valid");
      removeClass(phoneNumberInputRef, "empty_field");
    } else {
      removeClass(phoneNumberInputRef, "valid");
      addClass(phoneNumberInputRef, "empty_field");
    }
    setPhoneNumber(phoneNumberValue);
    if (phoneNumber.length === 10) {
      setGuestLoginDisabled(false);
    } else {
      setGuestLoginDisabled(true);
    }
  };

  const handleOTPChange = (otp) => {
    setOTP(otp);
    if (otp.length == 4) {
      addClass(OTPVerifyButtonRef, "is-active");
      OTPVerifyButtonRef.current.focus();
    } else {
      removeClass(OTPVerifyButtonRef, "is-active");
    }
    dispatch({ type: UPDATE_OTP_ERROR_MESSAGE, payload: "" });
  };

  const onPhoneNumberVerifyButton = (e) => {
    if (validatePhoneNumber()) {
      removeClass(stepOneRef, "active");

      addClass(wrapOTPRef, "is-active");
      addClass(phoneNumberInputRef, "disabled");
      removeClass(editButtonRef, "d-none");
      addClass(editButtonRef, "is-active");

      // Verify Mobile Number
      if (hasClass(phoneNumberInputRef, "valid")) {
        removeClass(phoneNumberVerifyButtonRef, "is-active");
        removeClass(OTPVerifyButtonRef, "is-active");
        removeClass(guestLoginRef, "d-none");
        addClass(phoneNumberVerifyButtonRef, "disable");
        removeClass(OTPVerifyButtonRef, "disable");
      } else {
        removeClass(phoneNumberVerifyButtonRef, "disable");
        addClass(OTPVerifyButtonRef, "disable");
        addClass(guestLoginRef, "d-none");
      }

      document.querySelector(".otp-list div input").focus();

      // hide number on mobile
      addClass(wrapNumberRef, "hide-mobile");
      addClass(verifyPhoneWrapSubTitleRef, "hide-mobile");
      addClass(verifyPhoneWrapTitleRef, "hide-mobile");

      sentVerificationCode(phoneNumber);

      dispatch({
        type: ADD_CONTACT,
        payload: {
          phoneNumber: phoneNumber,
          OTP: OTP,
        },
      });
    }
  };

  const onOTPVerifyButton = (e) => {
    //Verify mobile number from sms
    if (hasClass(OTPVerifyButtonRef, "is-active")) {
      if (email && email !== "") dispatch({ type: UPDATE_OPTIONAL_EMAIL, payload: { email } });
      dispatch(verifyCode(phoneNumber, OTP, checkoutId, email));
    }
  };

  // const handleLogout = (e) => {
  //   removePhoneCookie("userPhone");
  //   window.location.reload();
  // };

  const changePhoneNumber = (e) => {
    e.preventDefault();

    addClass(editButtonRef, "d-none");
    addClass(guestLoginRef, "d-none");
    removeClass(phoneNumberInputRef, "disabled");
    phoneNumberInputRef.current.focus();
    removeClass(phoneNumberVerifyButtonRef, "disable");

    // $(".stepOne .stepBtn").addClass("is-active");
    if (validatePhoneNumber()) {
      addClass(phoneNumberVerifyButtonRef, "is-active");
    }

    // $(".stepOne .wrap-otp").removeClass("is-active");
    removeClass(wrapOTPRef, "is-active");

    addClass(OTPVerifyButtonRef, "disable");

    // $(".stepOne .otp-list input").each(function () {
    // 	$(this).val("");
    // });
    setOTP("");
    dispatch({ type: UPDATE_OTP_ERROR_MESSAGE, payload: "" });

    if (window.matchMedia("(max-width: 767px)").matches) {
      // $(".wrap-number .title, .wrap-number").removeClass("hide-mobile");
      removeClass(wrapNumberRef, "hide-mobile");

      // $(
      // 	".verify-phone-wrap .step-title, .verify-phone-wrap .subtitle"
      // ).removeClass("hide-mobile");
      removeClass(verifyPhoneWrapTitleRef, "hide-mobile");
      removeClass(verifyPhoneWrapSubTitleRef, "hide-mobile");

      // $(".verify-phone-wrap .wrap-otp").removeClass("is-active");
      removeClass(wrapOTPRef, "is-active");

      // $('.stepOne input[name="phone_number"]').focus();
      phoneNumberInputRef.current.focus();

      // $(".verify-phone-wrap .phoneNumberBtnJs").removeClass("disable");
      removeClass(phoneNumberVerifyButtonRef, "disable");
      // $(".verify-phone-wrap .verifyPhoneBtnJs").addClass("disable");
      addClass(OTPVerifyButtonRef, "is-active");
    }
  };

  const onResendOTP = (e) => {
    addClass(resendBtnRef, "disable");
    addClass(timerValueJsRef, "is-active");
    sentVerificationCode(phoneNumber);
    startTimer();
    dispatch({ type: UPDATE_OTP_ERROR_MESSAGE, payload: "" });
    setOTP("");
  };

  const onResendWhatsOTP = (e) => {
    addClass(resendBtnRef, "disable");
    addClass(timerValueJsRef, "is-active");
    sentVerificationCode(phoneNumber, true);
    startTimer();
    dispatch({ type: UPDATE_OTP_ERROR_MESSAGE, payload: "" });
    setOTP("");
  };

  const handleGuestLoginClick = (e) => {
    dispatch(guestUserData(phoneNumber, email, checkoutId));
    // let updateData = {
    //   customer_mobile: phoneNumber,
    // };

    // if (email && email !== "") {
    //   dispatch({ type: UPDATE_OPTIONAL_EMAIL, payload: { email } });
    //   updateData["customer_email"] = email;
    // }

    // updateCartInfo(updateData, checkoutId);

    const localAddress = getSavedAddressFromLocalStorage();
    dispatch({
      type: GET_LOCAL_ADDRESS,
      payload: localAddress,
    });

    dispatch({
      type: ACTIVATE_ADDRESS,
    });
    dispatch({
      type: UPDATE_LOGIN,
      payload: {
        mode: GUEST_USER,
        phoneNumber,
      },
    });

    dispatch({ type: CHANGE_MODAL_KEY });

    // sendEvent("guestLogin", "Logged In As Guest", "Click");
    sendCheckoutOptionGoogleAnalytics(2, "guestUser", productDetails);
    try {
      window.Moengage.track_event("Login", {
        phone: phoneNumber,
        guest_mode: true,
        referrer_section: "checkout",
        referrer_page: "checkout",
      });
    } catch (err) {
      console.log(err);
    }
    window.clarity("identify", "phoneNo", phoneNumber);
    // window.__insp.push(["identify", phoneNumber]);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    if (validateEmail(email)) {
      setEmailValidationError("");
    } else {
      setEmailValidationError("Enter valid email");
    }
    setEmail(email);
  };

  const emailValidator = () => {
    const isEmailValid = validateEmail(email);
    if (!isEmailValid) {
      setEmail("");
      setEmailValidationError("");
    }
  };

  const markStepOneComplete = () => {
    addClass(OTPVerifyButtonRef, "is-active");
    addClass(stepOneContentClassRef, "disable");
    addClass(stepOneSuccessLoginWrapClassRef, "d-block");
    addClass(loginModalBackdropRef, "is-disabled");
    setShowLoginForm(true);
  };

  // useEffect(() => {
  //   if (phoneNo !== "" && discountReason && discountReason !== "") {
  //     const discountPayload = {
  //       discount_code: discountReason,
  //       checkout_token: cartId,
  //       phone_number: phoneNo,
  //     };
  //     dispatch(updateDiscount(discountPayload, null, true, checkoutSelector.pageInitialLoaded));
  //   }
  // }, [phoneNo, discountReason, cartId, dispatch]);

  if (!showLoginForm && !phoneNo && phoneNo === "") {
    return (
      <div className="proceed-to-login-pane">
        <button className="price-btn">
          <span className="price-text">
            {currency} {finalTotalPrice - paymentCharges}
          </span>
          <span className="price-desc">Total bill amount</span>
        </button>
        <button
          onClick={() => {
            setShowLoginForm(true);
            dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: false } });
          }}
          className="proceed-to-login-btn"
        >
          Proceed to login
        </button>
      </div>
    );
  }

  return (
    <>
      {phoneNo && phoneNo !== "" ? null : (
        <div
          className="login-modal-backdrop"
          ref={loginModalBackdropRef}
          onClick={() => {
            setShowLoginForm(false);
            dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: true } });
          }}
        ></div>
      )}

      <form
        ref={stepOneRef}
        className="step-item step-verify-phone stepOne validate-form active"
        onSubmit={handleSubmit}
        autoComplete="off"
        id="verifyPhoneForm"
      >
        {/* <span className="step-number hide-mobile">
        STEP 1
        <a
          className={`signout-button ml-auto text-uppercase desktop ${phoneCookie.userPhone ? "" : "d-none"}`}
          onClick={handleLogout}
        >
          Logout
        </a>
      </span> */}

        <div className="step-body">
          {loginSelector.loginStepLoading ? <LoadingBlock /> : <></>}
          <div className={`${loginSelector.loginStepLoading ? "d-none" : "d-block"}`}>
            <div ref={stepOneContentClassRef} className="step-content">
              <div className="verify-phone-wrap text-center">
                <span className="step-title" ref={verifyPhoneWrapTitleRef}>
                  Login / Register
                </span>

                {phoneNo && phoneNo !== "" ? null : (
                  <span
                    className="close-btn"
                    onClick={() => {
                      setShowLoginForm(false);
                      dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: true } });
                    }}
                  >
                    <img src={makePublicURL("img/close-button.svg")} alt="close btn" />
                  </span>
                )}

                <span className="subtitle text-left" ref={verifyPhoneWrapSubTitleRef}>
                  Please verify your mobile number and continue your purchase.
                </span>
                {/* Mobile number */}
                <div className="form-item wrap-number field-row mt-4 text-left" style={{ marginBottom: "45px" }}>
                  <label className="title" style={{ top: "-10px" }}>
                    Mobile number
                  </label>
                  <p
                    className={`country-code subtitle`}
                    style={{
                      color: "#344254",
                      paddingBottom: "7px",
                      marginBottom: "0px",
                      top: "unset",
                    }}
                  >
                    +91-
                  </p>
                  <input
                    ref={phoneNumberInputRef}
                    type="text"
                    name={`phone_number`}
                    maxLength="10"
                    className={`field-input`}
                    onChange={handlePhoneNumberChange}
                    onInput={handlePhoneNumberChange}
                    onKeyUp={handlePhoneNumberChange}
                    onBlur={handlePhoneNumberChange}
                    style={{ paddingLeft: 30 }}
                    autoComplete="off"
                    value={phoneNumber}
                  />
                  <span className="bar" />
                  <label id="phone_number-error" className="error" htmlFor="phone_number" style={{ top: "35px" }}>
                    {phoneNumberValidationMessage}
                  </label>
                  <a ref={editButtonRef} className={`change-btn changePhoneJs d-none`} onClick={changePhoneNumber}>
                    Edit
                  </a>
                </div>

                <div className="form-item wrap-number field-row mt-4 text-left">
                  <input
                    // ref={phoneNumberInputRef}
                    type="text"
                    name="email"
                    className={`field-input ${email ? "valid" : ""}`}
                    autoComplete="off"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={emailValidator}
                  />
                  <label id="phone_number-error" className="error" htmlFor="phone_number">
                    {emailValidationError}
                  </label>
                  <span className="bar" />
                  <label className="title">Email (OPTIONAL)</label>
                </div>

                {/* first step btn verify */}
                <button
                  ref={phoneNumberVerifyButtonRef}
                  className={`form-btn stepBtn phoneNumberBtnJs`}
                  type="submit"
                  onClick={onPhoneNumberVerifyButton}
                >
                  Verify
                </button>

                {/* Enter OTP */}
                <div ref={wrapOTPRef} className={`form-item wrap-otp text-left`}>
                  <span className="title">
                    <span className="hide-mobile">Enter otp</span>
                    <span className="show-mobile title-mobile">
                      Please enter OTP we have sent you on <output name="result" className="phone-number" />
                    </span>
                  </span>
                  <label id="otp-error" className="error">
                    {loginSelector.OTPErrorMessage}
                  </label>
                  <OtpInput
                    containerStyle={"otp-list"}
                    value={OTP}
                    onChange={handleOTPChange}
                    numInputs={4}
                    separator={<span>&nbsp; &nbsp;</span>}
                    isInputNum={true}
                    inputStyle={{ width: "	100%" }}
                  />

                  <div className="resend-btns" ref={resendBtnRef}>
                    <button type="button" className="whatsapp-btn" onClick={onResendWhatsOTP}>
                      Send on whatsapp
                    </button>
                    <button type="button" className="sms-btn" onClick={onResendOTP}>
                      Resend
                    </button>
                  </div>

                  {/* <button ref={resendBtnRef} type="button" className="change-btn resendBtn" onClick={onResendOTP}>
                  Resend
                </button> */}

                  {/* timer */}
                  {/* <div className="otp-timer"> */}
                  <div className="otp-timer2">
                    <div className="count timerValueJs" ref={timerValueJsRef}>
                      {timerVal}
                    </div>
                  </div>
                </div>

                {/* second step btn verify */}
                <button
                  ref={OTPVerifyButtonRef}
                  className={`form-btn stepBtn verifyPhoneBtnJs disable`}
                  type="submit"
                  onClick={onOTPVerifyButton}
                  style={{ width: "83%" }}
                >
                  {loading ? <i className="fa fa-circle-o-notch fa-spin ml-2" style={{ marginRight: 10 }}></i> : null}
                  Verify
                </button>

                {/* <span className="step-title text-center mt-2" id="orSpanText">or</span>
              <button
                ref={guestLoginRef}
                className="form-btn outline-btn mt-2"
                onClick={handleGuestLoginClick}
              >
                Continue as a Guest
              </button> */}

                {/* <span className="d-block mt-2" style={{lineHeight: "46px"}}>
                <a ref={guestLoginRef} href="#" className="d-none" onClick={handleGuestLoginClick}>
                  Skip &#8594;
                </a>
              </span> */}
                <a ref={guestLoginRef} className="d-none skip-btn" onClick={handleGuestLoginClick}>
                  Skip
                </a>
              </div>
              <div className="verify-section my-auto">
                <div className="img-wrap">
                  <img src={makePublicURL("img/phone-verify.png")} alt="" className="phone-img" />
                </div>
                <div className="description">
                  <span className="title">Verify your mobile number</span>
                  <span className="subtitle">Please verify your mobile number and continue your purchase.</span>
                </div>
              </div>
            </div>
            <div ref={stepOneSuccessLoginWrapClassRef} className="success-login-wrap">
              <span className="step-title d-block mb-0">
                {loginSelector.firstName && loginSelector.firstName !== "Mrs" && loginSelector.firstName !== "Mr."
                  ? `Hi ${/\d/.test(loginSelector.firstName) ? "" : loginSelector.firstName}!`
                  : "Hi!"}
              </span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
