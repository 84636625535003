import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addClass, hasClass, mobileNumberValidator, removeClass } from "../../helpers";
import { REMOVE_OVERLAY } from "../../store/checkout/actions";
import {
  UPDATE_LOGIN,
  sentVerificationCode,
  verifyCodeGuest,
  UPDATE_OTP_ERROR_MESSAGE,
} from "../../store/login/actions";
import OtpInput from "react-otp-input";
import "./scss/response.scss";
import "./scss/guest-verify-phone.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { CONTACT_SUPPORT, EMPTY, INCORRECT_MOBILE_NO, INVALID, VALID, VERIFIED_USER } from "../../helpers/constants";

export default function VerifyGuestPhone({ storedAddress }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 20, color: "white" }} spin />;

  const loginSelector = useSelector((state) => state.login);
  const { loading } = loginSelector;
  const { orderID } = useSelector((state) => state.checkout);

  const [phoneNumber, setPhoneNumber] = useState(loginSelector.phoneNumber);
  const [OTP, setOTP] = useState(loginSelector.OTP);
  const [phoneNumberValidationMessage, setPhoneNumberValidationMessage] = useState("");
  const [orderVerificationErrorMessage, setOrderVerificationErrorMessage] = useState("");
  const [verificationPart, setVerificationPart] = useState(1);
  const phoneRegExp = new RegExp(/^(?=.*[0-9])[+0-9]+$/);
  const phoneNumberInputRef = useRef();
  const phoneNumberVerifyButtonRef = useRef();
  const editButtonRef = useRef();
  const wrapNumberRef = useRef();
  const phoneSectionRef = useRef();
  const OTPSectionRef = useRef();
  const OTPVerifyButtonRef = useRef();
  const resendBtnRef = useRef();
  const timerValueJsRef = useRef();
  const [timerVal, setTimerVal] = useState("");
  const verifyPhoneWrapTitleRef = useRef();
  const verifyPhoneWrapSubTitleRef = useRef();
  const wrapOTPRef = useRef();

  useEffect(() => {
    if (storedAddress && Object.keys(storedAddress).length !== 0) {
      let number = "";
      if (storedAddress.mobileNumber.startsWith("+91")) {
        number = storedAddress.mobileNumber.substring(3);
      } else {
        number = storedAddress.mobileNumber;
      }
      setPhoneNumber(number);
    }
  }, [storedAddress]);

  const dispatch = useDispatch();

  const validatePhoneNumber = (phoneNo) => {
    const validationResult = mobileNumberValidator(phoneNo);
    if (validationResult == EMPTY) {
      setPhoneNumberValidationMessage("This field is required");
      return false;
    } else if (validationResult == VALID) {
      setPhoneNumberValidationMessage("");
      return true;
    } else if (validationResult == INVALID) {
      setPhoneNumberValidationMessage("Please specify a valid phone number");
      return false;
    }
  };

  const startTimer = () => {
    var timer2 = "2:01";
    var interval = setInterval(function () {
      var timer = timer2.split(":");
      //by parsing integer, I avoid all extra string processing
      var minutes = parseInt(timer[0], 10);
      var seconds = parseInt(timer[1], 10);
      --seconds;
      minutes = seconds < 0 ? --minutes : minutes;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 0 ? 59 : seconds;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      //minutes = (minutes < 10) ?  minutes : minutes;
      setTimerVal(minutes + ":" + seconds);
      if (minutes < 0) clearInterval(interval);
      //check if both minutes and seconds are 0
      if (seconds <= 0 && minutes <= 0) clearInterval(interval);
      timer2 = minutes + ":" + seconds;
    }, 1000);
  };

  const handlePhoneNumberChange = (e, ref) => {
    validatePhoneNumber(e.target.value);

    let phoneNumberValue = e.target.value;
    if (!phoneRegExp.test(phoneNumberValue)) {
      // Replace anything that isn't a number or a plus sign
      phoneNumberValue = phoneNumberValue.replace(/([^+0-9]+)/gi, "");
    }
    if (phoneNumberValue.length > 9 && phoneNumberValue.length < 14) {
      addClass(phoneNumberVerifyButtonRef, "is-active");
    } else {
      removeClass(phoneNumberVerifyButtonRef, "is-active");
    }

    if (phoneNumberValue.length > 0) {
      addClass(phoneNumberInputRef, "valid");
      removeClass(phoneNumberInputRef, "empty_field");
    } else {
      removeClass(phoneNumberInputRef, "valid");
      addClass(phoneNumberInputRef, "empty_field");
    }
    setPhoneNumber(phoneNumberValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const changePhoneNumber = (e) => {
    e.preventDefault();

    setVerificationPart(1);
    phoneNumberInputRef.current.focus();

    // $(".stepOne .stepBtn").addClass("is-active");
    if (validatePhoneNumber(phoneNumber)) {
      addClass(phoneNumberVerifyButtonRef, "is-active");
    }

    // $(".stepOne .wrap-otp").removeClass("is-active");
    removeClass(wrapOTPRef, "is-active");

    // $(".stepOne .otp-list input").each(function () {
    // 	$(this).val("");
    // });
    setOTP("");
    dispatch({ type: UPDATE_OTP_ERROR_MESSAGE, payload: "" });

    if (window.matchMedia("(max-width: 767px)").matches) {
      // $(".wrap-number .title, .wrap-number").removeClass("hide-mobile");
      removeClass(wrapNumberRef, "hide-mobile");

      // $(
      // 	".verify-phone-wrap .step-title, .verify-phone-wrap .subtitle"
      // ).removeClass("hide-mobile");
      removeClass(verifyPhoneWrapTitleRef, "hide-mobile");
      removeClass(verifyPhoneWrapSubTitleRef, "hide-mobile");

      // $(".verify-phone-wrap .wrap-otp").removeClass("is-active");
      removeClass(wrapOTPRef, "is-active");

      // $('.stepOne input[name="phone_number"]').focus();
      phoneNumberInputRef.current.focus();

      // $(".verify-phone-wrap .phoneNumberBtnJs").removeClass("disable");
      removeClass(phoneNumberVerifyButtonRef, "disable");
      // $(".verify-phone-wrap .verifyPhoneBtnJs").addClass("disable");
      addClass(OTPVerifyButtonRef, "is-active");
    }
  };

  const handleOTPChange = (otp) => {
    setOTP(otp);
    if (otp.length == 4) {
      addClass(OTPVerifyButtonRef, "is-active");
      OTPVerifyButtonRef.current.focus();
    } else {
      removeClass(OTPVerifyButtonRef, "is-active");
    }
    dispatch({ type: UPDATE_OTP_ERROR_MESSAGE, payload: "" });
  };

  const onPhoneNumberVerifyButton = (e) => {
    if (validatePhoneNumber(phoneNumber)) {
      setVerificationPart(2);
      sentVerificationCode(phoneNumber);
      setTimeout(() => {
        let absoluteTopPosition = phoneNumberVerifyButtonRef.current.getBoundingClientRect().top;
        window.scrollBy({
          top: absoluteTopPosition - 50,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  const onOTPVerifyButton = (e) => {
    //Verify phone number from sms
    if (hasClass(OTPVerifyButtonRef, "is-active")) {
      dispatch(verifyCodeGuest(loginSelector.phoneNumber ? loginSelector.phoneNumber : phoneNumber, OTP, orderID));
    }
  };

  const onResendOTP = (e) => {
    sentVerificationCode(phoneNumber);
    addClass(resendBtnRef, "disable");
    addClass(timerValueJsRef, "is-active");
    startTimer();
    dispatch({ type: UPDATE_OTP_ERROR_MESSAGE, payload: "" });
    setOTP("");
  };

  useEffect(() => {
    if (verificationPart == 0) {
      addClass(phoneSectionRef, "d-none");
      addClass(OTPSectionRef, "d-none");
    } else if (verificationPart == 1) {
      removeClass(phoneSectionRef, "d-none");
      addClass(OTPSectionRef, "d-none");
      removeClass(phoneNumberInputRef, "disabled");
      addClass(editButtonRef, "d-none");
      removeClass(phoneSectionRef, "d-none");
      removeClass(phoneNumberVerifyButtonRef, "disable");
    } else if (verificationPart == 2) {
      removeClass(phoneSectionRef, "d-none");
      removeClass(OTPSectionRef, "d-none");
      addClass(phoneNumberInputRef, "disabled");
      removeClass(editButtonRef, "d-none");
      addClass(phoneNumberVerifyButtonRef, "disable");
      document.querySelector("#otp-section div.otp-list div input").focus();
    }
  }, [verificationPart]);

  useEffect(() => {
    setTimeout(function () {
      phoneNumberInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 1000);
  }, []);

  useEffect(() => {
    setPhoneNumber(loginSelector.phoneNumber);
  }, [loginSelector.phoneNumber]);

  useEffect(() => {
    if (loginSelector.verificationError == CONTACT_SUPPORT) {
      setVerificationPart(0);
      setOrderVerificationErrorMessage(loginSelector.verificationErrorMessage);
    } else if (loginSelector.verificationError == INCORRECT_MOBILE_NO) {
      setVerificationPart(1);
      setPhoneNumberValidationMessage(loginSelector.verificationErrorMessage);
    }
  }, [loginSelector.verificationError, loginSelector.verificationErrorMessage]);

  return (
    <form className="guest-verify-phone" onSubmit={handleSubmit} autoComplete="off">
      <div className="verify-phone-wrap m-auto" style={{ maxWidth: "300px" }}>
        <span className="text-danger text-uppercase" style={{ paddingBottom: 50 }}>
          {orderVerificationErrorMessage}
        </span>
        <div ref={phoneSectionRef} id="phone-section">
          {/* Phone number */}
          <div ref={wrapNumberRef} className="form-item wrap-number mb-4 field-row" style={{ marginBottom: "45px" }}>
            <label className="title" style={{ top: "-10px" }}>
              Mobile number
            </label>
            <p
              className={`country-code subtitle`}
              style={{
                color: "#344254",
                top: "5.5px",
              }}
            >
              +91-
            </p>
            <input
              ref={phoneNumberInputRef}
              type="text"
              name="phone_number"
              className={`field-input valid`}
              onChange={handlePhoneNumberChange}
              onInput={handlePhoneNumberChange}
              onKeyUp={handlePhoneNumberChange}
              onBlur={handlePhoneNumberChange}
              style={{ paddingLeft: 30 }}
              maxLength="10"
              autoComplete="off"
              value={phoneNumber}
              readOnly={loginSelector.phoneNumber || storedAddress.mobileNumber}
            />
            <label id="phone_number-error" className="error" htmlFor="phone_number">
              {phoneNumberValidationMessage}
            </label>
            <span className="bar" />
            {loginSelector.phoneNumber ? null : (
              <a ref={editButtonRef} className={`change-btn changePhoneJs d-none`} onClick={changePhoneNumber}>
                Edit
              </a>
            )}
            {/* <a
              ref={editButtonRef}
              className={`change-btn changePhoneJs disabled`}
              onClick={changePhoneNumber}
            >
              Edit
            </a> */}
          </div>

          {/* first step btn verify */}
          <button
            ref={phoneNumberVerifyButtonRef}
            className={`form-btn stepBtn phoneNumberBtnJs is-active`}
            type="submit"
            onClick={onPhoneNumberVerifyButton}
          >
            Verify
          </button>
        </div>

        <div ref={OTPSectionRef} id="otp-section">
          {/* Enter OTP */}
          <div className={`form-item wrap-otp`}>
            <span className="title">
              <span className="hide-mobile">Enter otp</span>
              <span className="show-mobile title-mobile">
                Please enter OTP we have sent you on <output name="result" className="phone-number" />
              </span>
            </span>
            <label id="otp-error" className="error">
              {loginSelector.OTPErrorMessage}
            </label>
            <OtpInput
              containerStyle={"otp-list"}
              value={OTP}
              onChange={handleOTPChange}
              numInputs={4}
              separator={<span>&nbsp; &nbsp;</span>}
              isInputNum={true}
              inputStyle={{ width: "100%" }}
            />

            <button ref={resendBtnRef} type="button" className="change-btn resendBtn" onClick={onResendOTP}>
              Resend
            </button>
            {/* timer */}
            <div className="otp-timer">
              <div className="count timerValueJs" ref={timerValueJsRef}>
                {timerVal}
              </div>
            </div>
          </div>

          {/* second step btn verify */}
          <button
            ref={OTPVerifyButtonRef}
            className={`form-btn stepBtn verifyPhoneBtnJs`}
            type="submit"
            onClick={onOTPVerifyButton}
          >
            {loading ? <Spin indicator={antIcon} style={{ marginRight: 10 }} /> : null}
            Verify
          </button>
        </div>
      </div>
    </form>
  );
}
