import { VERIFIED_USER } from "../../helpers/constants";
import {
  UPDATE_LOGIN,
  SUCCESSFULL_LOGIN,
  TOGGLE_LOADING,
  UPDATE_OTP_ERROR_MESSAGE,
  TOGGLE_LOGIN_STEP_LOADING,
  UPDATE_OPTIONAL_EMAIL,
  UPDATE_VERIFICATION_ERROR,
  UPDATE_GUEST_USER_ID,
} from "./actions";

const loginInitialState = {
  OTPErrorMessage: "",
  mode: "",
  phoneNumber: "",
  OTP: "",
  firstName: "",
  lastName: "",
  email: "",
  customerEmail: "",
  shpID: null,
  loading: false,
  loginStepLoading: false,
  verificationError: "",
  verificationErrorMessage: "",
  guestUserId: null,
};

export const loginReducer = (state = loginInitialState, action) => {
  switch (action.type) {
    case UPDATE_GUEST_USER_ID:
      return {
        ...state,
        guestUserId: action.payload,
      };
    case UPDATE_LOGIN:
      return {
        ...state,
        mode: action.payload.mode,
        phoneNumber: action.payload.phoneNumber,
        OTP: action.payload.OTP,
        loading: false,
      };
    case SUCCESSFULL_LOGIN:
      return {
        ...state,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        email: action.payload.email,
        shpID: action.payload.shp_id,
        phoneNumber: action.payload.phoneNumber,
        mode: VERIFIED_USER,
        loading: false,
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case UPDATE_OTP_ERROR_MESSAGE:
      return {
        ...state,
        OTPErrorMessage: action.payload,
      };
    case TOGGLE_LOGIN_STEP_LOADING:
      return {
        ...state,
        loginStepLoading: !state.loginStepLoading,
      };
    case UPDATE_OPTIONAL_EMAIL:
      return {
        ...state,
        customerEmail: action.payload.email,
      };
    case UPDATE_VERIFICATION_ERROR:
      return {
        ...state,
        verificationError: action.payload.verificationError,
        verificationErrorMessage: action.payload.verificationErrorMessage,
      };
    default:
      return state;
  }
};
