import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Switch, useHistory, Route } from "react-router-dom";
import CheckoutPage from "../pages/checkout/CheckoutPage";
import VerifyOrder from "../pages/verifyOrder";

export default function Routes(props) {
  let history = useHistory(props);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={CheckoutPage} />
        <Route path="/order/placed/thank_you" exact component={CheckoutPage} />
        <Route path="/verify/order/:checkoutID/:checkoutToken/" exact component={VerifyOrder} />
        {/* <Route path="/checkout" exact component={CheckoutPage} /> */}
      </Switch>
    </Router>
  );
}
