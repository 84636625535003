import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function LoadingBlock(props) {
  const antIcon = <LoadingOutlined style={{ fontSize: 50, color: "green" }} spin />;

  return (
    <div style={{ textAlign: "center", marginTop: "15%", marginBottom: "15%" }}>
      {/* <img src={makePublicURL("img/loading2.gif")} style={{ width: "100px" }} /> */}
      <Spin indicator={antIcon} />
    </div>
  );
}
