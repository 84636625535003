import {
  UPDATE_PRODUCTS,
  UPDATE_DISCOUNT,
  UPDATE_CART,
  UPDATE_SHIPPING,
  UPDATE_PRODUCT_VALUE,
  UPDATE_SUBTOTAL_VALUE,
  UPDATE_PRODUCT_DELIVERABILITY,
  UPDATE_PRODUCT_DELIVERABILITY_ERROR,
  UPDATE_DISCOUNT_ERROR,
  UPDATE_DISCOUNTED_PRICE,
  UPDATE_DISCOUNT_PERCENTAGE,
  UPDATE_EMPTY_CART,
  UPDATE_CHECKOUT_ID,
  UPDATE_ADDRESS_DELIVERABILTY_ERROR,
  SET_CART_ID,
  RESET_ADDITIONAL_OFF,
  RESET_NAT_CASH_BACK,
  SET_NEW_CART_ID,
  SET_OUTSTATION,
  UPDATE_REFERRAL_DISCOUNT_MODE,
  UPDATE_FINAL_VALUES,
  UPDATE_COUNT_ITEM_WITH_ERROR,
  UPDATE_GIFT_DATA,
  UPDATE_INTERNAL_GIFT_DATA,
  UPDATE_OPEN_PAYMENT,
  // UPDATE_CLOSE_PAYMENT,
  UPDATE_GIFT_TEXT,
  UPDATE_NAT_CASH,
  UPDATE_APPLIED_NAT_CASH,
  UPDATE_TOTAL_VALUE,
  UPDATE_DISCOUNT_AMOUNT,
  UPDATE_PROMOTION_COSTS,
  UPDATE_DISCOUNT_REASON,
  UPDATE_FREE_ITEMS,
  UPDATE_PAYMENT_CHARGES,
  UPDATE_IS_LOADING,
  UPDATE_PAYMENT_CHARGES_OBJ,
  UPDATE_REDEEMED_NAT_CASH,
  UPDATE_ADDITIONAL_OFF,
  UPDATE_CHECKOUT_STATE,
  UPDATE_PROMOTION_DISCOUNT_AMT,
  // UPDATE_ORDER_NAME,
} from "./actions";

import { round } from "../../helpers";

const cartInitialState = {
  finalTotalPrice: 0.0,
  discountOnMrp: 0.0,
  discountReason: "",
  cartId: "",
  currency: "Rs. ",
  productDetails: [],
  freeItems: [],
  shipping: 0.0,
  productPrices: 0.0,
  subTotalPrice: 0.0,
  discountCode: "",
  discountCodeErrorMessage: "",
  discountPrice: 0.0,
  discountType: "",
  discountPercentage: "",
  addressDeliverabilityError: null,
  outstation: null,
  emptyCart: false,
  checkoutId: null,
  isDiscountTypeReferral: false,
  finalFirstName: "",
  finalOutstation: false,
  MrpTotal: 0.0,
  finalSubtotal: 0.0,
  finalTotal: 0.0,
  finalShipping: 0.0,
  finalDiscount: 0.0,
  itemWithError: 0,
  giftEnable: false,
  giftTo: "",
  giftFrom: "",
  giftMessage: "",
  doNotSendInvoice: false,
  // PaymentCharges: {},
  paymentCharges: 0.0,
  paymentOpen: false,
  newCartId: "",
  additionalOff: 0.0,
  additionalOffExpiry: null,
  eligibleNatCash: 0.0,
  eligibleNatCashExpiry: null,
  natCash: 0,
  tempNatCashApplied: 0,
  natCashApplied: 0,
  maxApplicableNatCash: 0,
  paymentChargesObj: {},
  checkoutState: "",
  isLoading: false,
  promotionCosts: [],
  promotionDiscountAmount: 0,
  // order_name: null,
};

const formatProductData = (data) => {
  const newData = data.map((item) => {
    return {
      productId: item.product_id,
      variantId: item.variant_id,
      productName: item.title,
      cmsProductName: item.cms_product_title,
      productCategory: "Nutrition Packed HairMasks",
      // productPrice: item.total_mrp,
      // productQuantity: item.quantity,
      productQuantity: item.is_free_product ? item.quantity : item.total_quantity,
      promotionQty: item.promotion_quantity,
      promotionPrice: item.promotion_price,
      productThumbnail: item.image,
      id: item.id,
      productOriginalPrice: item.per_unit_sp,
      deliverability: true,
      collectionId: item.collection_id,
      isPanIndia: true,
      productDeliverabilityCities: [],
      // productDeliverabilityError: item.panIndiaDeliverabilityError,
      variantTitle: item.variant_title,
      cmsVariantTitle: item.cms_variant_name,
      discountReason: item.discount_reason,
      isFreeProduct: item.is_free_product,
      expiredAt: item.expired_at,
      Mrp: item.total_mrp,
      applyDiscountOnProduct: item.apply_discount,
      productDisplayMessage: item.display_message,
    };
  });
  return newData;
};

const formatProductDeliverableData = (prevState, deliverabilityData) => {
  let newProductData = [];
  let product;
  for (product of prevState) {
    let deliveryData;
    for (deliveryData of deliverabilityData) {
      if (deliveryData.variant_id === product.variantId) {
        let productDeliverabilityCities = deliveryData.pan_india_deliverability
          ? ["All"]
          : ["New Delhi", "Delhi", "Noida", "Gurgaon", "Gurugram", "Noida", "Greater Noida", "Ghaziabad"];

        let tempData = {
          ...product,
          deliverability: deliveryData.deliverability,
          isPanIndia: deliveryData.pan_india_deliverability,
          productDeliverabilityCities: productDeliverabilityCities,
        };
        newProductData.push(tempData);
      }
    }
  }
  return newProductData;
};

const formatProductDeliverableError = (prevState, selectedCity) => {
  let newProductData = [];
  let errorFlag = false;
  let product;
  for (product of prevState) {
    if (
      !product.productDeliverabilityCities.includes(selectedCity) &&
      !product.productDeliverabilityCities.includes("All")
    ) {
      errorFlag = true;
      let tempData = {
        ...product,
        productDeliverabilityError: true,
      };
      newProductData.push(tempData);
    } else {
      let tempData = {
        ...product,
        productDeliverabilityError: false,
      };
      newProductData.push(tempData);
    }
  }

  const cities = ["delhi", "new delhi", "noida", "faridabad", "greater noida", "gurgaon", "gurugram"];
  let outstation = false;
  if (!cities.includes(selectedCity.toLowerCase())) outstation = true;

  return [newProductData, errorFlag, outstation];
};

export const cartReducer = (state = cartInitialState, action) => {
  switch (action.type) {
    case SET_CART_ID:
      return {
        ...state,
        cartId: action.payload,
      };
    case SET_NEW_CART_ID:
      return {
        ...state,
        newCartId: action.payload,
      };
    case UPDATE_DISCOUNT:
      return {
        ...state,
        discountCode: action.payload,
      };
    // case UPDATE_ORDER_NAME:
    //   return {
    //     ...state,
    //     orderName: action.payload,
    //   };
    case UPDATE_REFERRAL_DISCOUNT_MODE:
      return {
        ...state,
        isDiscountTypeReferral: action.payload,
      };
    case UPDATE_PRODUCTS:
      return {
        ...state,
        productDetails: action.payload,
      };
    case UPDATE_ADDRESS_DELIVERABILTY_ERROR:
      return {
        ...state,
        addressDeliverabilityError: action.payload,
      };
    case UPDATE_CART:
      return {
        ...state,
        productDetails: formatProductData(action.payload),
      };
    // case UPDATE_FREE_PRODUCT:
    //   return {
    //     ...state,
    //     productDetails: [...formatProductData(action.payload), ...state.productDetails],
    //   };
    case UPDATE_SHIPPING:
      return {
        ...state,
        shipping: action.payload,
      };
    case UPDATE_CHECKOUT_STATE:
      return {
        ...state,
        checkoutState: action.payload,
      };
    case UPDATE_PAYMENT_CHARGES:
      return {
        ...state,
        paymentCharges: action.payload,
      };
    case UPDATE_PAYMENT_CHARGES_OBJ:
      return {
        ...state,
        paymentChargesObj: action.payload,
      };
    case UPDATE_PRODUCT_VALUE:
      return {
        ...state,
        productPrices: action.payload,
      };
    case UPDATE_TOTAL_VALUE:
      return {
        ...state,
        finalTotalPrice: action.payload,
      };
    case UPDATE_DISCOUNT_AMOUNT:
      return { ...state, discountAmount: action.payload };
    case UPDATE_PROMOTION_COSTS:
      return { ...state, promotionCosts: action.payload };
    case UPDATE_PROMOTION_DISCOUNT_AMT:
      return { ...state, promotionDiscountAmount: action.payload };
    case UPDATE_DISCOUNT_REASON:
      return {
        ...state,
        discountReason: action.payload,
      };
    case UPDATE_SUBTOTAL_VALUE:
      return {
        ...state,
        subTotalPrice: action.payload,
      };
    case UPDATE_PRODUCT_DELIVERABILITY_ERROR:
      const [productDeets, addressError, outstation] = formatProductDeliverableError(
        state.productDetails,
        action.payload
      );
      return {
        ...state,
        productDetails: productDeets,
        addressDeliverabilityError: addressError,
        outstation,
      };
    case UPDATE_PRODUCT_DELIVERABILITY:
      return {
        ...state,
        // productDetails: formatProductDeliverableData(state.productDetails, action.payload),
      };
    case UPDATE_DISCOUNT_ERROR:
      return {
        ...state,
        discountCodeErrorMessage: action.payload,
      };
    case UPDATE_DISCOUNTED_PRICE:
      return {
        ...state,
        // discountPrice: action.payload.discountAmount,
        discountCodeErrorMessage: "",
        discountType: action.payload.discountType,
        discountPercentage: action.payload.discountValue,
      };
    case UPDATE_DISCOUNT_PERCENTAGE:
      return {
        ...state,
        discountPrice: round((action.payload / 100) * state.subtotalPrice, 2),
        discountCodeErrorMessage: "",
        discountType: "percentage",
      };
    case UPDATE_FREE_ITEMS:
      return {
        ...state,
        freeItems: formatProductData(action.payload),
      };
    case UPDATE_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case UPDATE_EMPTY_CART:
      return {
        ...state,
        emptyCart: true,
        productDetails: [],
      };
    case UPDATE_CHECKOUT_ID:
      return {
        ...state,
        checkoutId: action.payload,
      };
    case SET_OUTSTATION:
      return {
        ...state,
        outstation: action.payload,
      };
    case UPDATE_FINAL_VALUES:
      return {
        ...state,
        // PaymentCharges: action.payload.payment_charges,
        // MrpTotal: action.payload.mrp_total,
        // finalFirstName: action.payload.firstName,
        // finalOutstation: action.payload.outstation,
        // finalSubtotal: action.payload.subtotal,
        // finalTotal: action.payload.total,
        // finalShipping: action.payload.shipping,
        // finalDiscount: action.payload.discount,
        // tempNatCashApplied: action.payload.redeemed_nat_cash,
        // additionalOff: action.payload.additional_off || 0,
        additionalOffExpiry: action.payload.additional_off_expiry,
        maxApplicableNatCash: action.payload.maxApplicableNatCash,
        eligibleNatCash: action.payload.eligibleNatCash,
        eligibleNatCashExpiry: action.payload.eligibleNatCashExpiry,
      };
    case UPDATE_REDEEMED_NAT_CASH:
      return {
        ...state,
        tempNatCashApplied: action.payload,
      };
    case UPDATE_ADDITIONAL_OFF:
      return {
        ...state,
        additionalOff: action.payload,
      };
    case RESET_ADDITIONAL_OFF:
      return {
        ...state,
        additionalOff: 0,
        additionalOffExpiry: null,
      };
    case RESET_NAT_CASH_BACK:
      return {
        ...state,
        eligibleNatCash: 0,
        eligibleNatCashExpiry: null,
      };
    case UPDATE_COUNT_ITEM_WITH_ERROR:
      return {
        ...state,
        itemWithError: action.payload,
      };
    case UPDATE_GIFT_DATA:
      return {
        ...state,
        giftEnable: true,
        giftTo: action.payload.to,
        giftFrom: action.payload.sender,
        giftMessage: action.payload.message,
        doNotSendInvoice: action.payload.send_invoice,
      };
    case UPDATE_INTERNAL_GIFT_DATA:
      return {
        ...state,
        giftEnable: action.payload.enable_gift,
        giftTo: action.payload.to,
        giftFrom: action.payload.sender,
        giftMessage: action.payload.message,
        doNotSendInvoice: action.payload.send_invoice,
      };
    case UPDATE_OPEN_PAYMENT:
      return {
        ...state,
        paymentOpen: action.payload,
      };
    case UPDATE_GIFT_TEXT:
      return {
        ...state,
        tempIsGift: action.payload.isGift,
        tempSender: action.payload.sender,
        tempRecepient: action.payload.recepient,
        tempIsgiftMessage: action.payload.isgiftMessage,
        tempSendInvoice: action.payload.sendInvoice,
      };

    case UPDATE_NAT_CASH:
      return {
        ...state,
        natCash: action.payload.natCash,
      };

    case UPDATE_APPLIED_NAT_CASH:
      return {
        ...state,
        natCashApplied: action.payload,
      };

    default:
      return state;
  }
};
