import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addClass,
  debounce,
  hasClass,
  makePublicURL,
  mobileNumberValidator,
  pincodeValidator,
  removeClass,
} from "../../helpers";
import {
  addNewAddressV2,
  ADD_ADDRESS_LOADING,
  CANDIDATE_ADDRESS,
  updateAddress,
  addNewGuestAddress,
} from "../../store/address/actions";
import { CLOSE_MODAL } from "../../store/checkout/actions";
import "./scss/address.scss";
import { EMPTY, INVALID } from "../../helpers/constants";
import { getCookieFromName } from "../../helpers/cookies";
// import GooglePlacesAutocomplete, {
// 	geocodeByAddress,
// 	getLatLng,
// } from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { cartAPI, cityStateAPI } from "../../helpers/api";

export default function AddressModal(props) {
  const checkoutSelector = useSelector((state) => state.checkout);
  const addressSelector = useSelector((state) => state.address);
  const loginSelector = useSelector((state) => state.login);
  const storeAddressDetails = addressSelector.addressDetails;
  const [saveDisabled, setSaveDisabled] = useState(true);
  const { mode, shpID } = loginSelector;
  const loading = addressSelector.loadingAddAddress;

  const dispatch = useDispatch();

  const navLinkHomeRef = useRef();
  const navLinkWorkRef = useRef();
  const navLinkOtherRef = useRef();
  const tabContentRef = useRef();
  const tabPaneRef = useRef();
  const modalRef = useRef();
  const addressTypeRef = useRef();
  const submitButtonRef = useRef();
  const toggleTabContentJsRef = useRef();
  const mapSectionRef = useRef();
  const pageEndRef = useRef();
  const stateInputRef = useRef();
  const cityInputRef = useRef();
  const abortController = useRef(null);

  let initialState = {
    fullName: "",
    mobileNumber: "",
    alternateMobileNumber: "",
    addressType: "Home",
    apartmentName: "",
    addressLocation: "",
    addressLandmark: "",
    deliveryInfo: "",
    addressStreet: "",
    enableEdit: true,
  };

  let locationInitialState = {
    pincode: "",
    city: "",
    state: "",
    lat: "",
    lng: "",
  };

  if (props.addressDetails) {
    initialState = {
      fullName: props.addressDetails.fullName,
      mobileNumber: props.addressDetails.mobileNumber.slice(-10),
      alternateMobileNumber: props.addressDetails.alternateMobileNumber,
      addressType: props.addressDetails.addressType ? props.addressDetails.addressType : "Home",
      apartmentName: props.addressDetails.editAddressData.address1?.slice(0, -2) || "",
      addressLocation: props.addressDetails.editAddressData.locality,
      addressLandmark: props.addressDetails.editAddressData.landmark,
      deliveryInfo: props.addressDetails.deliveryInfo,
      addressStreet: props.addressDetails.addressStreet,
      addressID: props.addressDetails.addressID,
      enableEdit: props.addressDetails.enableEdit ? props.addressDetails.enableEdit : true,
    };

    locationInitialState = {
      pincode: props.addressDetails.pincode,
      city: props.addressDetails.city,
      state: props.addressDetails.state,
      lat: props.addressDetails.lat,
      lng: props.addressDetails.lng,
    };
  }

  const [addressDetails, setAddressDetails] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState({
    fullName: "",
    mobileNumber: "",
    alternateMobileNumber: "",
    addressType: "",
    apartmentName: "",
    addressLocation: "",
    addressLandmark: "",
    deliveryInfo: "",
    addressStreet: "",
    enableEdit: true,
    pincode: "",
  });
  const [locationDetails, setLocationDetails] = useState(locationInitialState);
  const [mobileNumberValid, setMobileNumberValid] = useState(false);
  const [alternateMobileNumberValid, setAlternateMobileNumberValid] = useState(false);
  const [pincodeValid, setPincodeValid] = useState(false);
  const [placesSuggestions, setPlacesSuggestions] = useState([]);
  const [firstMount, setFirstMount] = useState(true);
  const [cleanupFinished, setCleanupFinished] = useState(false);
  const { cartId } = useSelector((state) => state.cart);
  const [citiesSuggestion, setCitiesSuggestion] = useState([]);

  const fetchPlacesSuggestions = async (input) => {
    let url = `/api/v1/checkout/get/map?search=${input}`;
    if (locationDetails.lat && locationDetails.lat !== "")
      url = url + `&location=${locationDetails.lat},${locationDetails.lng}`;

    const { data, statusCode } = await cartAPI.get(url);
    if (statusCode === 200) setPlacesSuggestions(data.results);
  };

  const fetchCitySuggestion = async (pincode) => {
    // aborting previous request
    abortController.current && abortController.current.abort();
    abortController.current = new AbortController();
    const { data, statusCode } = await cityStateAPI.post(
      "",
      { pincode: locationDetails.pincode },
      "omit",
      abortController.current.signal
    );
    let city, state;
    if (statusCode === 200) {
      let suggestions = data.data.result;
      if (suggestions.length) {
        city = suggestions[0].city;
        // Assuming State is always same in results. I am not handling it with stateSuggestions
        state = suggestions[0].state;
        const setOfCitySuggestions = new Set(suggestions.map((sug) => sug.city));
        setCitiesSuggestion([...setOfCitySuggestions]);
        setPincodeValid(true);
        setErrorMessage({
          ...errorMessage,
          pincode: "",
        });
        if (setOfCitySuggestions.has(locationDetails.city)) {
          city = locationDetails.city;
        }
      } else {
        setCitiesSuggestion([]);
        setPincodeValid(false);
        setErrorMessage({
          ...errorMessage,
          pincode: "The pincode is unserviceable",
        });
      }
    }

    return {
      city,
      state,
    };
  };

  const debouncePlacesFetch = useCallback(debounce(fetchPlacesSuggestions, 1000), [
    locationDetails.lat,
    locationDetails.lng,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setAddressDetails({
      ...addressDetails,
      city: locationDetails.city,
    });
    let isValid = true;
    let tempErrorMessage = {};
    tempErrorMessage.fullName = validateFullName();
    tempErrorMessage.mobileNumber = validateMobileNumber();
    if (errorMessage.pincode) {
      tempErrorMessage.pincode = errorMessage.pincode;
    } else {
      tempErrorMessage.pincode = validatePincode();
    }
    tempErrorMessage.addressLocation = validateRequired("addressLocation");
    tempErrorMessage.apartmentName = validateRequired("apartmentName");
    if (errorMessage.alternateMobileNumber) {
      tempErrorMessage.alternateMobileNumber = errorMessage.alternateMobileNumber;
    } else {
      tempErrorMessage.alternateMobileNumber = "";
    }
    tempErrorMessage.city = validateRequired("city");
    tempErrorMessage.stateSelect = validateRequired("stateSelect");
    setErrorMessage({
      ...errorMessage,
      fullName: tempErrorMessage.fullName,
      mobileNumber: tempErrorMessage.mobileNumber,
      pincode: tempErrorMessage.pincode,
      addressLocation: tempErrorMessage.addressLocation,
      apartmentName: tempErrorMessage.apartmentName,
      alternateMobileNumber: tempErrorMessage.alternateMobileNumber,
      city: tempErrorMessage.city,
      stateSelect: tempErrorMessage.stateSelect,
    });

    for (let key in tempErrorMessage) {
      if (tempErrorMessage[key] !== "") {
        document.getElementsByName(key)[0].focus();
        isValid = false;
        return false;
      }
    }
    let addressObject = addressDetails;
    if (isValid) {
      /* check address 1 length and seperate with address 2 */
      if (addressDetails.apartmentName.length >= 90) {
        let longAddress1 = addressDetails.apartmentName;
        const arry = longAddress1.split(" ");
        let shortAddress1 = arry[0];
        let shortAddress2 = "";
        let address1EndPoint = 0;
        for (let i = 1; i < arry.length; i++) {
          let temp = shortAddress1 + " " + arry[i];
          if (temp.length <= 90) {
            shortAddress1 = temp;
          } else {
            address1EndPoint = i;
            shortAddress2 = arry[i];
            break;
          }
        }

        for (let j = address1EndPoint + 1; j < arry.length; j++) {
          shortAddress2 = shortAddress2 + " " + arry[j];
        }

        // setAddressDetails({
        //   ...addressDetails,
        //   apartmentName: shortAddress1,
        //   addressStreet: shortAddress2,
        // });
        addressObject.apartmentName = shortAddress1.trim();
        addressObject.addressStreet = shortAddress2.trim();
      }
      /*  */

      if (props.addressDetails) {
        addNewAddressV2(addressObject, locationDetails, cartId, shpID, true)(dispatch);
      } else {
        if (!getCookieFromName("access_token"))
          addNewGuestAddress(addressObject, locationDetails, cartId, getCookieFromName("userId"))(dispatch);
        else addNewAddressV2(addressObject, locationDetails, cartId, shpID)(dispatch);
      }
    }
  };

  const onNavLinkClick = (e) => {
    if (e.target.textContent === "Home (9am-9pm delivery)") {
      removeClass(navLinkWorkRef, "active");
      setAddressDetails({
        ...addressDetails,
        addressType: "Home",
      });
    }
    if (e.target.textContent === "Office (9am-5pm delivery)") {
      removeClass(navLinkHomeRef, "active");
      setAddressDetails({
        ...addressDetails,
        addressType: "Office",
      });
    }
  };

  const validateFullName = () => {
    // let tempValue = document.getElementsByName("fullName")[0].value.replace(/[^A-Za-z. ]/gi, "").trim();
    let tempValue = document.getElementsByName("fullName")[0].value.replace(/[^A-Za-z. ]/gi, "");
    setAddressDetails({
      ...addressDetails,
      fullName: tempValue,
    });
    let msg = "";
    if (tempValue.length < 3) {
      msg = "Please enter atleast 3 characters";
    } else if (tempValue.length > 30) {
      msg = "Name length should be between 3-30 characters";
    } else if (tempValue.split(" ").length > 1 && /\d/.test(tempValue.split(" ")[0])) {
      msg = "Please enter a valid name";
    }
    setErrorMessage({
      ...errorMessage,
      fullName: msg,
    });
    return msg;
  };

  const validateMobileNumber = (mobileNumber = document.getElementsByName("mobileNumber")[0].value) => {
    mobileNumber = mobileNumber.replace(/([^+0-9]+)/gi, "");
    const validationResult = mobileNumberValidator(mobileNumber);
    let msg = "";

    setAddressDetails({
      ...addressDetails,
      mobileNumber: mobileNumber,
    });

    if (validationResult === EMPTY || validationResult === INVALID) {
      msg = "Please enter a valid 10-digit mobile number";
      setMobileNumberValid(false);
    } else {
      setMobileNumberValid(true);
    }
    setErrorMessage({
      ...errorMessage,
      mobileNumber: msg,
    });

    return msg;
  };

  const validateAlternateMobileNumber = (alternateMobileNumber) => {
    alternateMobileNumber = alternateMobileNumber.replace(/([^+0-9]+)/gi, "");
    const validationResult = mobileNumberValidator(alternateMobileNumber);
    let msg = "";
    if (validationResult === EMPTY || validationResult === INVALID) {
      msg = "Please enter a valid 10-digit mobile number";
      setAlternateMobileNumberValid(false);
    } else {
      setAlternateMobileNumberValid(true);
    }
    setErrorMessage({
      ...errorMessage,
      alternateMobileNumber: msg,
    });
    setAddressDetails({
      ...addressDetails,
      alternateMobileNumber: alternateMobileNumber,
    });
  };

  const validatePincode = (pincode = document.getElementsByName("pincode")[0].value.replace(/([^0-9])/gi, "")) => {
    const validationResult = pincodeValidator(pincode);
    setLocationDetails({
      ...locationDetails,
      pincode: pincode,
    });

    let msg = "";
    if (validationResult === INVALID) {
      msg = "Please enter a valid pincode";
      setPincodeValid(false);
    } else {
      setPincodeValid(true);
    }
    setErrorMessage({
      ...errorMessage,
      pincode: msg,
    });
    return msg;
  };

  const validateRequired = (domName) => {
    const selectedDom = document.getElementsByName(domName)[0];

    let msg = "";
    if (selectedDom.value.length === 0) {
      if (domName === "addressLocation") {
        msg = `Please enter area/sector`;
      } else if (domName === "apartmentName") {
        msg = `Please enter apartment/house no.`;
      } else if (domName === "city") {
        msg = `Please enter city`;
      } else if (domName === "stateSelect") {
        msg = `Please select state`;
      } else {
        msg = `Please enter a ${domName}`;
      }
    }
    setErrorMessage({
      ...errorMessage,
      [domName]: msg,
    });
    setAddressDetails({
      ...addressDetails,
      [domName]: selectedDom.value,
    });
    return msg;
  };

  const handleNewAddressFormInputChanges = async (e) => {
    if (e.target.name === "mobileNumber") {
      validateMobileNumber(e.target.value);
    } else if (e.target.name === "pincode") {
      const pincode = e.target.value.replace(/([^0-9])/gi, "");
      validatePincode(pincode);
    } else if (e.target.name === "alternateMobileNumber") {
      validateAlternateMobileNumber(e.target.value);
    } else if (e.target.name === "fullName") {
      validateFullName();
    } else if (e.target.name === "stateSelect") {
      setLocationDetails({
        ...locationDetails,
        state: e.target.value,
      });
      validateRequired("stateSelect");
    } else if (e.target.required && e.target.value.length === 0) {
      setErrorMessage({
        ...errorMessage,
        [e.target.name]: `Please enter a ${e.target.name}`,
      });
      setAddressDetails({
        ...addressDetails,
        [e.target.name]: e.target.value,
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [e.target.name]: "",
      });
      setAddressDetails({
        ...addressDetails,
        [e.target.name]: e.target.value,
      });
    }
  };

  const setGoogleLocation = async (e) => {
    setAddressDetails({
      ...addressDetails,
      addressLocation: e.target.value,
    });

    debouncePlacesFetch(e.target.value);
  };

  const getCityAndState = async (pincode) => {
    try {
      let lat, lng;
      try {
        const response = await geocodeByAddress(pincode + " India");
        const responseDetails = await getLatLng(response[0]);
        lat = responseDetails.lat;
        lng = responseDetails.lng;
      } catch (e) {}
      const { city, state } = await fetchCitySuggestion(pincode);

      setLocationDetails({
        pincode,
        city,
        state,
        lat,
        lng,
      });
      // setAddressDetails({ ...addressDetails, city: city });
      addClass(cityInputRef, "valid");
      addClass(stateInputRef, "valid");
    } catch (e) {}
  };

  useEffect(() => {
    // abortController.current && abortController.current.abort()
    if (locationDetails.pincode) {
      if (locationDetails.pincode.length === 6) {
        getCityAndState(locationDetails.pincode);
      } else {
        setLocationDetails({
          ...locationDetails,
          city: "",
          state: "",
        });
        setCitiesSuggestion([]);
        removeClass(cityInputRef, "valid");
        removeClass(stateInputRef, "valid");
      }
    }
    setFirstMount(false);
  }, [locationDetails.pincode]);

  useEffect(() => {
    if (!props.addressDetails) {
      validateMobileNumber(loginSelector.phoneNumber);
    }
    return () => {
      dispatch({
        type: ADD_ADDRESS_LOADING,
        payload: {
          loading: false,
        },
      });
    };
  }, []);

  return (
    <div>
      <div
        ref={modalRef}
        className="modal fade addressModalJs left"
        id="mapModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="mapModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header back-btn">
              <div className="d-flex">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={props.closeRef}>
                  <span aria-hidden="true" className="d-flex">
                    <img src={makePublicURL("img/back-arrow.png")} className="back-arrow" alt="arrow" />
                  </span>
                </button>
                <h5 className="modal-title" id="mapModalLabel">
                  Add <span className="hide-mobile">and Save</span> Address
                </h5>
              </div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body-wrap">
              <div className="modal-body">
                <div className="description-section">
                  {/* <h6 className="title-shipping-address hide-mobile">Save as</h6> */}
                  {/* tabs */}

                  <div ref={tabContentRef} className="tab-content mt-3" id="pills-tabContent">
                    {/* 1 */}
                    <div
                      ref={tabPaneRef}
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                      data-tab="homeItemJs"
                    >
                      <form
                        className="shipping-address shippingAddressJs"
                        noValidate="novalidate"
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        id="addNewAddressForm"
                      >
                        <div className="form-item field-row">
                          <label className="title required-field" style={{ top: "-10px" }}>
                            Mobile number
                            <span>&nbsp;(Required)</span>
                          </label>
                          <p
                            className={`country-code subtitle`}
                            style={{
                              color: "#344254",
                              top: "4.5px",
                            }}
                          >
                            +91-
                          </p>
                          <input
                            type="text"
                            name="mobileNumber"
                            className={`field-input ${addressDetails.mobileNumber ? "valid" : ""}`}
                            maxLength="10"
                            value={addressDetails.mobileNumber}
                            onChange={handleNewAddressFormInputChanges}
                            style={{ paddingLeft: 30 }}
                            required
                            readOnly={mode === "guestUser" ? true : false}
                          />
                          <span className="bar" />
                          <label id="name-error" className="error" htmlFor="mobileNumber">
                            {errorMessage.mobileNumber}
                          </label>
                        </div>
                        <div className="field-row">
                          <input
                            type="text"
                            name="fullName"
                            className={`field-input ${addressDetails.fullName ? "valid" : ""}`}
                            value={addressDetails.fullName}
                            onChange={handleNewAddressFormInputChanges}
                            onBlur={validateFullName}
                            // autoComplete="on"
                            required
                          />
                          <label id="name-error" className="error" htmlFor="fullName">
                            {errorMessage.fullName}
                          </label>
                          <span className="bar" />
                          <label className="required-field">
                            Your Name
                            <span>&nbsp;(Required)</span>
                          </label>
                        </div>
                        <div className="field-row">
                          <input
                            type="text"
                            name="pincode"
                            className={`field-input ${locationDetails.pincode ? "valid" : ""}`}
                            maxLength="6"
                            value={locationDetails.pincode}
                            onChange={(e) => {
                              handleNewAddressFormInputChanges(e);
                            }}
                            required
                          />
                          <label id="name-error" className="error" htmlFor="pincode">
                            {errorMessage.pincode}
                          </label>
                          <span className="bar" />
                          <label className="required-field">
                            Pincode
                            <span>&nbsp;(Required)</span>
                          </label>
                        </div>
                        <div className="field-row">
                          <input
                            type="text"
                            name="addressLocation"
                            id="locality"
                            className={`field-input ${addressDetails.addressLocation ? "valid" : ""}`}
                            value={addressDetails.addressLocation}
                            onChange={setGoogleLocation}
                            autoComplete="off"
                            required
                            onBlur={() => {
                              validateRequired("addressLocation");
                            }}
                          />
                          <label id="name-error" className="error" htmlFor="addressLocation">
                            {errorMessage.addressLocation}
                          </label>
                          <div className="autocomplete-dropdown-container">
                            {placesSuggestions.map((place, index) => (
                              <div
                                key={index}
                                style={{
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                  marginTop: "10px",
                                }}
                                onClick={() => {
                                  setAddressDetails({ ...addressDetails, addressLocation: place.value });
                                  setPlacesSuggestions([]);
                                }}
                              >
                                <span style={{ fontSize: "16px" }}>{place.display}</span>
                              </div>
                            ))}
                          </div>
                          <span className="bar" />
                          <label className="required-field">
                            Locality/Area/Sector
                            <span>&nbsp;(Required)</span>
                          </label>
                        </div>
                        <div className="field-row">
                          <input
                            type="text"
                            name="apartmentName"
                            className={`field-input ${addressDetails.apartmentName ? "valid" : ""}`}
                            value={addressDetails.apartmentName}
                            onChange={handleNewAddressFormInputChanges}
                            required
                          />
                          <label id="name-error" className="error" htmlFor="apartmentName">
                            {errorMessage.apartmentName}
                          </label>
                          <span className="bar" />
                          <label className="required-field">
                            Address 1<span>&nbsp;(Required)</span>
                          </label>
                        </div>
                        <div className="field-row">
                          <input
                            type="text"
                            name="addressLandmark"
                            className={`field-input ${addressDetails.addressLandmark ? "valid" : ""}`}
                            value={addressDetails.addressLandmark}
                            onChange={handleNewAddressFormInputChanges}
                          />
                          <span className="bar" />
                          <label>Landmark (Optional)</label>
                        </div>
                        <div className="form-item field-row">
                          <label className="title" style={{ top: "-10px" }}>
                            Alternate Mobile number (Optional)
                          </label>
                          <p
                            className={`country-code subtitle`}
                            style={{
                              color: "#344254",
                              top: "4.5px",
                            }}
                          >
                            +91-
                          </p>
                          <input
                            type="text"
                            name="alternateMobileNumber"
                            className={`field-input ${addressDetails.alternateMobileNumber ? "valid" : ""}`}
                            maxLength="10"
                            value={addressDetails.alternateMobileNumber}
                            onChange={handleNewAddressFormInputChanges}
                            style={{ paddingLeft: 30 }}
                            required
                          />
                          <span className="bar" />
                          <label id="name-error" className="error" htmlFor="alternateMobileNumber">
                            {errorMessage.alternateMobileNumber}
                          </label>
                        </div>

                        {citiesSuggestion.length === 0 ? (
                          <>
                            <div className="field-row">
                              <input
                                name="city"
                                ref={cityInputRef}
                                type="text"
                                className={`field-input ${addressDetails.city ? "valid" : ""}`}
                                value={addressDetails.city}
                                onChange={handleNewAddressFormInputChanges}
                                required
                              />
                              <label id="name-error" className="error" htmlFor="city">
                                {errorMessage.city}
                              </label>
                              <span className="bar" />
                              <label className="required-field">
                                Town/City
                                <span>&nbsp;(Required)</span>
                              </label>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="field-row">
                              <select
                                name="city"
                                className={`field-input valid`}
                                onChange={(e) => {
                                  setLocationDetails({
                                    ...locationDetails,
                                    city: e.target.value,
                                  });
                                  setAddressDetails({ ...addressDetails, city: e.target.value });
                                }}
                                value={locationDetails.city}
                              >
                                {citiesSuggestion.map((city, index) => {
                                  return (
                                    <option key={index} value={city}>
                                      {city}
                                    </option>
                                  );
                                })}
                              </select>
                              <span className="bar" />
                              <label className="required-field">
                                Town/City
                                <span>&nbsp;(Required)</span>
                              </label>
                            </div>
                          </>
                        )}
                        <div className="field-row">
                          <select
                            name="stateSelect"
                            className={`field-input stateValid`}
                            onChange={(e) => {
                              handleNewAddressFormInputChanges(e);
                            }}
                            value={locationDetails.state}
                            ref={stateInputRef}
                          >
                            <option disabled selected value="">
                              Select State
                            </option>
                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                            <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Ladakh">Ladakh</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                          </select>
                          <span className="bar" />
                          <label id="name-error" className="error" htmlFor="stateSelect">
                            {errorMessage.stateSelect}
                          </label>
                          <label style={{ display: "block" }} className="required-field">
                            State
                            <span>&nbsp;(Required)</span>
                          </label>
                        </div>

                        <ul className="nav nav-pills nav-list" id="pills-tab" role="tablist">
                          <li className="nav-item" data-pills="homeItemJs">
                            <a
                              ref={navLinkHomeRef}
                              className={`nav-link ${addressDetails.addressType == "Home" ? "active" : ""}`}
                              id="pills-home-tab"
                              data-toggle="pill"
                              role="tab"
                              aria-selected="false"
                              onClick={onNavLinkClick}
                            >
                              Home (9am-9pm delivery)
                            </a>
                          </li>
                          <li className="nav-item" data-pills="workItemJs">
                            <a
                              ref={navLinkWorkRef}
                              className={`nav-link ${
                                addressDetails.addressType == "Work" || addressDetails.addressType == "Office"
                                  ? "active"
                                  : ""
                              }`}
                              id="pills-work-tab"
                              data-toggle="pill"
                              role="tab"
                              aria-selected="false"
                              onClick={onNavLinkClick}
                            >
                              Office (9am-5pm delivery)
                            </a>
                          </li>
                        </ul>

                        <button
                          ref={submitButtonRef}
                          type="submit"
                          className="form-btn addressBtnJs is-active"
                          disabled={loading}
                          style={{
                            backgroundColor: loading ? "grey" : "",
                          }}
                        >
                          {props.addressDetails ? "Update Address" : "Save Address"}
                          {loading && (
                            <i
                              className="fa fa-circle-o-notch fa-spin ml-2"
                              style={{
                                marginRight: 10,
                              }}
                            ></i>
                          )}
                        </button>
                        <span ref={pageEndRef}></span>
                      </form>
                    </div>
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
            {/* modal-body-wrap */}
          </div>
        </div>
      </div>
    </div>
  );
}
