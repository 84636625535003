import { cartAPI, orderAPI } from "../../helpers/api";
import {
  PAYMENT_DONE,
  TOGGLE_PAGE_LOADING_STATE,
  UPDATE_VALID_CHECKOUT,
  SET_ORDER_ID,
  REMOVE_OVERLAY,
} from "../checkout/actions";
import { UPDATE_LOGIN } from "../login/actions";
import { PAYMENT_MODE } from "../payment/actions";
import { TOGGLE_ADDRESS_SELECTION_FALSE, TOGGLE_ADDRESS_SELECTION_TRUE } from "../address/actions";
import { sendCheckoutLoadGoogleAnalytics } from "../../helpers/ga";
// import { round } from "../../helpers";
// import store from "../index";

export const UPDATE_PRODUCTS = "updateProducts";
export const UPDATE_DISCOUNT = "updateDiscount";
export const UPDATE_DISCOUNTED_PRICE = "updateDiscountedPrice";
export const UPDATE_DISCOUNT_PERCENTAGE = "updatedDiscountPercentage";
export const UPDATE_CART = "updateCart";
export const UPDATE_SHIPPING = "updateShipping";
export const UPDATE_PRODUCT_VALUE = "updateProductValue";
export const UPDATE_TOTAL_VALUE = "updateTotalValue";
export const UPDATE_SUBTOTAL_VALUE = "updateSubTotalValue";
export const UPDATE_DISCOUNT_AMOUNT = "updateDiscountOnMrp";
export const UPDATE_DISCOUNT_REASON = "updateDiscountReason";
export const UPDATE_PRODUCT_DELIVERABILITY = "updateProductDeliverability";
export const UPDATE_PRODUCT_DELIVERABILITY_ERROR = "updateProductDeliverabilityError";
export const UPDATE_DISCOUNT_ERROR = "updateDiscountError";
export const UPDATE_EMPTY_CART = "updateEmptyCart";
export const UPDATE_CHECKOUT_ID = "updateCheckoutId";
export const UPDATE_ADDRESS_DELIVERABILTY_ERROR = "updateAddressDeliverabilityError";
export const SET_CART_ID = "setCartId";
export const SET_NEW_CART_ID = "setNewCartId";
export const SET_OUTSTATION = "setOutstation";
export const UPDATE_REFERRAL_DISCOUNT_MODE = "updateReferralDiscountMode";
export const UPDATE_FINAL_VALUES = "updateFinalValues";
export const UPDATE_COUNT_ITEM_WITH_ERROR = "updatedCountItemsWithError";
export const UPDATE_FREE_PRODUCT = "updateFreeProduct";
export const UPDATE_GIFT_DATA = "updateGiftData";
export const UPDATE_INTERNAL_GIFT_DATA = "updateInternalGiftData";
export const UPDATE_OPEN_PAYMENT = "updatePaymentOpen";
export const UPDATE_GIFT_TEXT = "UpdateGiftText";
export const RESET_ADDITIONAL_OFF = "ResetAdditionalOff";
export const RESET_NAT_CASH_BACK = "ResetNatCashBack";
export const UPDATE_NAT_CASH = "UpdateNatCash";
export const UPDATE_APPLIED_NAT_CASH = "updateAppliedNatCash";
export const UPDATE_FREE_ITEMS = "updateFreeItems";
export const UPDATE_PAYMENT_CHARGES = "updatePaymentCharges";
export const UPDATE_IS_LOADING = "updateIsLoading";
export const UPDATE_PAYMENT_CHARGES_OBJ = "updatePaymentChargesObj";
export const UPDATE_REDEEMED_NAT_CASH = "updateRedeemedNatCash";
export const UPDATE_ADDITIONAL_OFF = "updateAdditionalOff";
export const UPDATE_CHECKOUT_STATE = "updateCheckoutState";
export const UPDATE_PROMOTION_COSTS = "updatePromotionCosts";
export const UPDATE_PROMOTION_DISCOUNT_AMT = "updatePromotionDiscountAmount";
// export const UPDATE_ORDER_NAME = "updateOrderName";

// export const updateCostsOnLogin = (data) => async (dispatch) => {
//   dispatch({ type: UPDATE_PRODUCT_VALUE, payload: data.costs.total });
//   dispatch({ type: UPDATE_SUBTOTAL_VALUE, payload: data.costs.items_total });
//   dispatch({ type: UPDATE_TOTAL_VALUE, payload: data.costs.total });
//   dispatch({ type: UPDATE_DISCOUNT_AMOUNT, payload: data.costs.discount_amount });
//   dispatch({ type: UPDATE_DISCOUNT_REASON, payload: data.costs.discount_reason });
//   dispatch({ type: UPDATE_SHIPPING, payload: data.costs.shipping });
//   dispatch({ type: UPDATE_PAYMENT_CHARGES, payload: data.costs.payment_charges });
//   dispatch({ type: UPDATE_REDEEMED_NAT_CASH, payload: data.costs.redeemed_natcash });
//   dispatch({ type: UPDATE_ADDITIONAL_OFF, payload: data.costs.additional_off });
// };

export const updateCosting = (checkoutId, payload) => async (dispatch) => {
  dispatch({ type: UPDATE_IS_LOADING, payload: true });
  // const { data, statusCode } = await cartAPI.patch(`/new/checkout/add/${checkoutId}/`, payload);
  const { data, statusCode } = await cartAPI.patch(`/api/v1/checkout/add/${checkoutId}/`, payload);
  if (statusCode === 200) {
    dispatch({ type: UPDATE_IS_LOADING, payload: false });
    dispatch({ type: UPDATE_PRODUCT_VALUE, payload: data.costs.total });
    dispatch({ type: UPDATE_SUBTOTAL_VALUE, payload: data.costs.items_total });
    dispatch({ type: UPDATE_TOTAL_VALUE, payload: data.costs.total });
    dispatch({ type: UPDATE_DISCOUNT_AMOUNT, payload: data.costs.discount_amount });
    dispatch({ type: UPDATE_DISCOUNT_REASON, payload: data.costs.discount_reason });
    dispatch({ type: UPDATE_SHIPPING, payload: data.costs.shipping });
    dispatch({ type: UPDATE_PAYMENT_CHARGES, payload: data.costs.payment_charges });
    dispatch({ type: UPDATE_REDEEMED_NAT_CASH, payload: data.costs.redeemed_natcash });
    dispatch({ type: UPDATE_ADDITIONAL_OFF, payload: data.costs.additional_off });
    dispatch({ type: UPDATE_PROMOTION_DISCOUNT_AMT, payload: data.costs.promotion_discount });

    if (data.discounting && data.discounting.status === "SUCCESS") {
      dispatch({ type: UPDATE_PRODUCT_VALUE, payload: data.discounting.data.costs.total });
      dispatch({ type: UPDATE_SUBTOTAL_VALUE, payload: data.discounting.data.costs.items_total });
      dispatch({ type: UPDATE_TOTAL_VALUE, payload: data.discounting.data.costs.total });
      dispatch({ type: UPDATE_DISCOUNT_AMOUNT, payload: data.discounting.data.costs.discount_amount });
      dispatch({ type: UPDATE_DISCOUNT_REASON, payload: data.discounting.data.costs.discount_reason });
      dispatch({ type: UPDATE_SHIPPING, payload: data.discounting.data.costs.shipping });
      dispatch({ type: UPDATE_PAYMENT_CHARGES, payload: data.discounting.data.costs.payment_charges });
      dispatch({ type: UPDATE_REDEEMED_NAT_CASH, payload: data.discounting.data.costs.redeemed_natcash });
      dispatch({ type: UPDATE_ADDITIONAL_OFF, payload: data.discounting.data.costs.additional_off });
      dispatch({ type: UPDATE_PROMOTION_DISCOUNT_AMT, payload: data.discounting.data.costs.promotion_discount });

      if (data.discounting.error_response) {
        dispatch({ type: UPDATE_DISCOUNT_ERROR, payload: data.discounting.error_response });
      }
    }

    if (payload.customer_email) {
      const Data = {
        token: data.token,
      };
      cartAPI.post(`/v1/checkout/verify/email`, Data);
    }
  }
};

export const updateDiscountReducerValues =
  (discount_code, referral_discount_flow, value_type, value) => async (dispatch) => {
    dispatch({
      type: UPDATE_DISCOUNT,
      payload: discount_code,
    });
    dispatch({
      type: UPDATE_REFERRAL_DISCOUNT_MODE,
      payload: referral_discount_flow,
    });
    dispatch({
      type: UPDATE_DISCOUNTED_PRICE,
      payload: {
        discountType: value_type,
        discountValue: value,
      },
    });
    dispatch({
      type: UPDATE_FREE_ITEMS,
      payload: [],
    });
  };

export const fetchCartContent =
  (cartID, pageInitialLoaded = true, city = "Delhi") =>
  async (dispatch, getState) => {
    // const selectedCity = getState().address.selectedAddress?.city;
    // console.log("city", selectedCity);
    // let finalCity;
    // if (selectedCity) {
    //   finalCity = selectedCity;
    // } else {
    //   finalCity = city;
    // }

    dispatch({ type: TOGGLE_ADDRESS_SELECTION_FALSE });
    // const cityData = store.getState().address.selectedAddress.city;
    if (cartID) {
      dispatch({ type: UPDATE_IS_LOADING, payload: true });
      // const { data, statusCode } = await cartAPI.get(`/v1/checkout/v2/view?cart_id=${cartID}&city=${city}`);
      // const { data, statusCode } = await cartAPI.get(`/new/checkout/view?checkout_token=${cartID}`);
      const { data, statusCode } = await cartAPI.get(`/api/v1/checkout/view?checkout_token=${cartID}`);
      if (statusCode === 200) {
        dispatch({ type: UPDATE_IS_LOADING, payload: false });
        if (data.is_checkout_empty) {
          dispatch({ type: UPDATE_EMPTY_CART, payload: true });
        } else if (data.checkout_items) {
          if (data.activeCheckout && window.location.href.includes("/order/placed")) {
            window.location.assign(`/?cart_id=${cartID}`);
          } else {
            dispatch({ type: UPDATE_CART, payload: data.checkout_items });
            dispatch({ type: SET_NEW_CART_ID, payload: data.cart_id });
            // dispatch({ type: UPDATE_ADDRESS_DELIVERABILTY_ERROR, payload: data.addressDeliverabilityError });
            dispatch({ type: UPDATE_CHECKOUT_ID, payload: data.checkout_id });
            dispatch({ type: UPDATE_PRODUCT_VALUE, payload: data.costs.total });
            dispatch({ type: UPDATE_SUBTOTAL_VALUE, payload: data.costs.items_total });
            dispatch({ type: UPDATE_TOTAL_VALUE, payload: data.costs.total });
            dispatch({ type: UPDATE_DISCOUNT_AMOUNT, payload: data.costs.discount_amount });
            dispatch({ type: UPDATE_PROMOTION_COSTS, payload: data.promotion_costs });
            dispatch({ type: UPDATE_PROMOTION_DISCOUNT_AMT, payload: data.costs.promotion_discount });
            dispatch({ type: UPDATE_DISCOUNT_REASON, payload: data.costs.discount_reason });
            dispatch({ type: UPDATE_PAYMENT_CHARGES, payload: data.costs.payment_charges });
            dispatch({ type: UPDATE_PAYMENT_CHARGES_OBJ, payload: data.payment_charges });
            dispatch({ type: UPDATE_REDEEMED_NAT_CASH, payload: data.costs.redeemed_natcash });
            dispatch({ type: UPDATE_SHIPPING, payload: data.costs.shipping });
            dispatch({ type: UPDATE_ADDITIONAL_OFF, payload: data.costs.additional_off });
            dispatch({ type: UPDATE_CHECKOUT_STATE, payload: data.checkout_state });
            // dispatch({ type: UPDATE_ORDER_NAME, payload: data.order_name });

            if (data.costs.discount_reason) {
              if (data.discounting.status === "SUCCESS") {
                dispatch(
                  updateDiscountReducerValues(
                    data.discounting.data.discount_code,
                    data.discounting.data.referral_discount_flow,
                    data.discounting.data.value_type,
                    data.discounting.data.value
                  )
                );

                dispatch({ type: UPDATE_PRODUCT_VALUE, payload: data.discounting.data.costs.total });
                dispatch({ type: UPDATE_SUBTOTAL_VALUE, payload: data.discounting.data.costs.items_total });
                dispatch({ type: UPDATE_TOTAL_VALUE, payload: data.discounting.data.costs.total });
                dispatch({ type: UPDATE_DISCOUNT_AMOUNT, payload: data.discounting.data.costs.discount_amount });
                dispatch({ type: UPDATE_DISCOUNT_REASON, payload: data.discounting.data.costs.discount_reason });
                dispatch({ type: UPDATE_PAYMENT_CHARGES, payload: data.discounting.data.costs.payment_charges });
                dispatch({ type: UPDATE_REDEEMED_NAT_CASH, payload: data.discounting.data.costs.redeemed_natcash });
                dispatch({ type: UPDATE_SHIPPING, payload: data.discounting.data.costs.shipping });
                dispatch({ type: UPDATE_ADDITIONAL_OFF, payload: data.discounting.data.costs.additional_off });
              } else {
                dispatch({ type: UPDATE_DISCOUNT_ERROR, payload: data.discounting.message });
              }
            }
            // dispatch({ type: UPDATE_COUNT_ITEM_WITH_ERROR, payload: data.items_with_error });
            // dispatch({ type: SET_OUTSTATION, payload: data.outstation });
            // dispatch({ type: UPDATE_OPEN_PAYMENT, payload: data.payment_open });
            dispatch({
              type: UPDATE_FINAL_VALUES,
              payload: {
                // firstName: data.first_name,
                // outstation: data.outstation,
                // subtotal: data.costs.items_total,
                // total: data.costs.total,
                // shipping: data.costs.shipping,
                // discount: data.costs.discount_amount,
                // mrp_total: data.costs.items_total,
                // payment_charges: data.costs.payment_charges,
                // redeemed_nat_cash: data.costs.redeemed_natcash,
                // additional_off: data.costs.additional_off,
                additional_off_expiry: data.additional_off_expiry,
                maxApplicableNatCash: data.max_applicable_nat_cash,
                eligibleNatCash: data.eligible_nat_cash,
                eligibleNatCashExpiry: data.eligible_nat_cash_expiry,
              },
            });
            if (data.gift_note) {
              dispatch({ type: UPDATE_GIFT_DATA, payload: data.gift_note });
            }
            // const productList = data.checkout_items.map((product) => {
            //   return {
            //     product_id: product.product_id,
            //     variant_id: product.variant_id,
            //   };
            // });
            // dispatch(getShippingAmount(data.costs.total, city));

            dispatch({ type: TOGGLE_ADDRESS_SELECTION_TRUE });
          }

          // dispatch(getProductDeliverability(productList));

          // TODO: Incase of a completed or already placed order,
          //  please return 200 and send fields "activeCheckout" and "message" in data
          //  and uncomment the below
          // Here, activeCheckout means whether the order for the particular cartId is placed or not

          if (!data.activeCheckout) {
            dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: true } });
            // TODO : Need a flag verifiedCart which is true when user is verifiedUser or guestUser has verified
            dispatch({
              type: UPDATE_VALID_CHECKOUT,
              payload: {
                checkoutMessage: data.message,
                activeCheckout: data.activeCheckout,
                verifiedCart: data.verifiedCart,
              },
            });
            dispatch({
              type: SET_ORDER_ID,
              payload: data.order_name,
            });
            dispatch({ type: PAYMENT_DONE });
            // TODO : Pass Payment Mode as "paymentMode" field in result
            // paymentMode can have "cashOnDelivery" or "cardPayment" as value
            dispatch({ type: PAYMENT_MODE, payload: data.is_payment_online ? "online" : "cod" });
            // TODO : Pass Login Mode as "loginMode" field in result
            // loginMode can have "guestUser" or "verifiedUser"
            dispatch({ type: UPDATE_LOGIN, payload: { mode: data.loginMode } });
            if (!window.location.href.includes("/order/placed"))
              window.location.assign(`/order/placed/thank_you?cart_id=${cartID}`);
          }
        }
      } else if (statusCode === 404) {
        dispatch({ type: UPDATE_CHECKOUT_ID, payload: data.checkout_id });
        dispatch({
          type: UPDATE_VALID_CHECKOUT,
          payload: { validCheckout: false, checkoutErrorMessage: data.message },
        });
      }
      if (data.checkout_state && data.checkout_state.toLowerCase() === "created") {
        let formData = {
          checkout_state: "checkout",
        };
        // await cartAPI.patch(`/new/checkout/add/${data.checkout_id}/`, formData);
        dispatch(updateCosting(data.checkout_id, formData));
        console.log("here?");
        sendCheckoutLoadGoogleAnalytics(1, "checkoutPageLoad", data);
      }
    } else {
      dispatch({ type: UPDATE_CHECKOUT_ID, payload: null });
      dispatch({
        type: UPDATE_VALID_CHECKOUT,
        payload: {
          validCheckout: false,
          checkoutErrorMessage: "Invalid Checkout Page",
        },
      });
    }

    if (!pageInitialLoaded) {
      dispatch({ type: TOGGLE_PAGE_LOADING_STATE });
    }
  };

export const updateCartContent = (requestData, increment, cartID) => async (dispatch, getState) => {
  const formData = {
    quantity: requestData.productQuantity + increment,
    price: (requestData.productQuantity + increment) * requestData.productOriginalPrice,
  };

  // console.log(getState().address.inte.city);
  const { data, statusCode } = await cartAPI.patch(`/v1/checkout/add_cart_item/${requestData.id}/`, formData);
  if (statusCode === 200) {
    dispatch(fetchCartContent(cartID, true, getState().address.interestedAddress.city));
  }
};

// export const getShippingAmount =
//   (orderAmount, city = "Delhi") =>
//   async (dispatch, getState) => {
//     const discount = getState().cart.discountPrice;
//     const price = orderAmount - discount;
//     const { data, statusCode } = await cartAPI.get(`/v1/checkout/shipping?order_amount=${price}&city=${city}`);
//     if (statusCode === 200) {
//       dispatch({
//         type: UPDATE_SHIPPING,
//         payload: data.shipping_amount,
//       });
//     } else {
//       dispatch({
//         type: UPDATE_SHIPPING,
//         payload: orderAmount < 175 ? 50 : 0,
//       });
//     }
//   };

export const getProductDeliverability =
  (formData, city = "Delhi") =>
  async (dispatch) => {
    const payload = {
      products: formData,
      city,
    };
    const { data, statusCode } = await cartAPI.post("/v1/checkout/product_deliverability", payload);
    if (statusCode === 200) {
      dispatch({
        type: UPDATE_PRODUCT_DELIVERABILITY,
        payload: data.product_deliverability,
      });
    }
  };

// const removeAlreadyAppliedDiscount = (cartId, pageInitialLoaded) => async (dispatch) => {
//   console.log("here");
//   const { data, statusCode } = await cartAPI.post("/api/v1/discount/remove/discount", { checkout_token: cartId });
//   if (statusCode === 200) {
//     dispatch({ type: UPDATE_IS_LOADING, payload: false });
//     if (data.status === "SUCCESS") {
//       if (data.data.is_free_item_removed) {
//         dispatch({
//           type: UPDATE_FREE_ITEMS,
//           payload: [],
//         });
//         dispatch(fetchCartContent(cartId, pageInitialLoaded));
//       }
//       dispatch({ type: UPDATE_PRODUCT_VALUE, payload: data.data.costs.total });
//       dispatch({ type: UPDATE_SUBTOTAL_VALUE, payload: data.data.costs.items_total });
//       dispatch({ type: UPDATE_TOTAL_VALUE, payload: data.data.costs.total });
//       dispatch({ type: UPDATE_DISCOUNT_AMOUNT, payload: data.data.costs.discount_amount });
//       dispatch({ type: UPDATE_DISCOUNT_REASON, payload: data.data.costs.discount_reason });
//       dispatch({ type: UPDATE_SHIPPING, payload: data.data.costs.shipping });
//       dispatch({ type: UPDATE_REFERRAL_DISCOUNT_MODE, payload: false });
//       dispatch({ type: UPDATE_DISCOUNTED_PRICE, payload: { discountType: "", discountValue: 0.0 } });
//     } else if (data.status !== "SUCCESS") {
//       dispatch({ type: UPDATE_DISCOUNT_ERROR, payload: data.message });
//     }
//   } else {
//     dispatch({ type: UPDATE_DISCOUNT_ERROR, payload: "Something went wrong. Please try again later." });
//   }
// };

export const updateDiscount =
  (discountPayload, hideLoader = null) =>
  async (dispatch) => {
    // const discountCode = discountPayload.discount_code;
    // let data;
    // let statusCode;
    // let freeProductFlow = false;
    // try {
    //   window.Moengage.track_event("Discount Attempted", { discount_code: discountCode, attempted: true });
    // } catch (err) {
    //   console.log(err);
    // }

    // if (discountCode && discountCode.replaceAll(" ", "").startsWith("FREE")) {
    //   // ({ data, statusCode } = await cartAPI.post("/v2/checkout/eligible_free_products", discountPayload));
    //   ({ data, statusCode } = await promotionsAPI.post("/v2/apply/discount", discountPayload));
    //   freeProductFlow = true;
    // } else {
    //   // ({ data, statusCode } = await cartAPI.post("/v2/checkout/discount", discountPayload));
    //   ({ data, statusCode } = await promotionsAPI.post("/v2/apply/discount", discountPayload));
    // }
    dispatch({ type: UPDATE_IS_LOADING, payload: true });
    // const { data, statusCode } = await promotionsAPI.post("/v2/apply/discount", discountPayload);
    // const { data, statusCode } = await promotionsAPI.post("/v3/apply/discount", discountPayload);
    const { data, statusCode } = await cartAPI.post("/api/v1/discount/apply/discount", discountPayload);
    if (statusCode === 200) {
      dispatch({ type: UPDATE_IS_LOADING, payload: false });
      if (data.status === "SUCCESS") {
        dispatch({ type: UPDATE_PRODUCT_VALUE, payload: data.data.costs.total });
        dispatch({ type: UPDATE_SUBTOTAL_VALUE, payload: data.data.costs.items_total });
        dispatch({ type: UPDATE_TOTAL_VALUE, payload: data.data.costs.total });
        dispatch({ type: UPDATE_DISCOUNT_AMOUNT, payload: data.data.costs.discount_amount });
        dispatch({ type: UPDATE_DISCOUNT_REASON, payload: data.data.costs.discount_reason });
        dispatch({ type: UPDATE_SHIPPING, payload: data.data.costs.shipping });
        dispatch({ type: UPDATE_PAYMENT_CHARGES, payload: data.data.costs.payment_charges });
        dispatch({ type: UPDATE_REDEEMED_NAT_CASH, payload: data.data.costs.redeemed_natcash });
        dispatch({ type: UPDATE_ADDITIONAL_OFF, payload: data.data.costs.additional_off });

        // const orderAmount = data.data.order_amount;
        // sessionStorage.setItem("discount", discountPayload.discount_code);
        dispatch({
          type: UPDATE_DISCOUNT,
          payload: discountPayload.discount_code,
        });
        dispatch({
          type: UPDATE_REFERRAL_DISCOUNT_MODE,
          payload: data.data.referral_discount_flow,
        });
        // if (freeProductFlow) {
        //   dispatch({ type: UPDATE_FREE_PRODUCT, payload: data.data.checkout_items });
        // }
        dispatch({
          type: UPDATE_DISCOUNTED_PRICE,
          payload: {
            // discountAmount: round(Math.abs(parseFloat(data.data.total_discount)), 2),
            // discountAmount: round(Math.abs(parseFloat(data.data.temp_discount)), 2),
            // discountAmount: data.data.costings.discount_amount,
            discountType: data.data.value_type,
            discountValue: data.data.value,
          },
        });

        if (data.data.free_items?.length > 0) {
          dispatch({
            type: UPDATE_FREE_ITEMS,
            payload: data.data?.free_items,
          });
        }

        // if (data.value_type === "percentage") {
        //   console.log(data.value_type);
        //   dispatch({
        //     type: UPDATE_DISCOUNT_PERCENTAGE,
        //     // payload: Math.abs(parseFloat(data.data.total_discount)),
        //     // payload: Math.abs(parseFloat(data.data.temp_discount)),
        //     payload: data.data.costings.discount_amount,
        //   });
        // } else {
        //   console.log("first");
        //   // if (freeProductFlow & data.data.provide_discount) {
        //   //   console.log("h?");
        //   //   dispatch({
        //   //     type: UPDATE_DISCOUNTED_PRICE,
        //   //     payload: {
        //   //       // discountAmount: round(parseFloat(data.data.total_discount), 2),
        //   //       // discountAmount: round(parseFloat(data.data.temp_discount), 2),
        //   //       discountAmount: data.data.costings.discount_amount,
        //   //       discountType: data.data.value_type,
        //   //       discountValue: Math.abs(parseFloat(data.data.value)),
        //   //     },
        //   //   });
        //   // }
        //   //  else if (discountCode.replaceAll(" ", "").startsWith("PUJA")) {
        //   //   dispatch({
        //   //     type: UPDATE_DISCOUNTED_PRICE,
        //   //     payload: {
        //   //       // discountAmount: round(parseFloat(data.data.total_discount), 2),
        //   //       // discountAmount: round(parseFloat(data.data.temp_discount), 2),
        //   //       discountAmount: data.data.costings.discount_amount,
        //   //       discountType: data.data.value_type,
        //   //       discountValue: Math.abs(parseFloat(data.data.value)),
        //   //     },
        //   //   });
        //   // }
        //   // else {
        //     dispatch({
        //       type: UPDATE_DISCOUNTED_PRICE,
        //       payload: {
        //         // discountAmount: round(Math.abs(parseFloat(data.data.total_discount)), 2),
        //         // discountAmount: round(Math.abs(parseFloat(data.data.temp_discount)), 2),
        //         discountAmount: data.data.costings.discount_amount,
        //         discountType: data.data.value_type,
        //         discountValue: Math.abs(parseFloat(data.data.value)),
        //       },
        //     });
        //   // }

        //   // dispatch(getShippingAmount(orderAmount));
        //   console.log(orderAmount);
        //   dispatch({ type: UPDATE_SHIPPING, payload: orderAmount });
        // }

        // dispatch(fetchCartContent(discountPayload.checkout_token, true));
      } else if (data.status !== "SUCCESS") {
        // sessionStorage.removeItem("discount");
        // try {
        //   window.Moengage.track_event("Discount Failed", {
        //     discount_code: discountPayload.discount_code,
        //     failure_reason: data.message,
        //   });
        // } catch (err) {
        //   console.log(err);
        // }
        dispatch({ type: UPDATE_DISCOUNT_ERROR, payload: data.message });

        // if (alreadyApplied) {
        //   dispatch(removeAlreadyAppliedDiscount(discountPayload.checkout_token, pageInitialLoaded));
        // }
      }
      hideLoader && hideLoader();
    } else if (statusCode === 200) {
      // sessionStorage.removeItem("discount");
      hideLoader && hideLoader();
      dispatch({ type: UPDATE_DISCOUNT_ERROR, payload: "Something went wrong. Please try again later." });
    }
  };

export const getOrderAgainstCheckout = (checkout_id) => async (dispatch) => {
  const { data } = await orderAPI.get(`/api/v1/order/get/order?checkout_id=${checkout_id}`);
};

export const removeBulkCartItem = (products, cartID) => async (dispatch, getState) => {
  const formData = {
    quantity: 0,
    price: 0,
  };
  let productRemoved = 0;
  let lenOfProductToBeRemoved = 0;

  for (let product of products) {
    if (product.productDeliverabilityError) {
      lenOfProductToBeRemoved += 1;
      const { data, statusCode } = await cartAPI.patch(`/v1/checkout/add_cart_item/${product.id}/`, formData);
      if (statusCode === 200) {
        productRemoved += 1;
      }
    }
  }
  if (lenOfProductToBeRemoved === productRemoved) {
    dispatch(fetchCartContent(cartID, true, getState().address.interestedAddress.city));
  }
};

export const updateCartInfo = (formData, cartID) => async (dispatch) => {
  // await cartAPI.patch(`/new/checkout/add/${cartID}/`, formData);
  await cartAPI.patch(`/api/v1/checkout/add/${cartID}/`, formData);
  // dispatch(updateCosting(cartID, formData));
};

export const updateCartInfoV1 = (cartID, source_ref) => async (dispatch) => {
  // await cartAPI.patch(`/new/checkout/add/${cartID}/`, { referrer: source_ref });
  await cartAPI.patch(`/api/v1/checkout/add/${cartID}/`, { referrer: source_ref });
  // dispatch(updateCosting(cartID, { referrer: source_ref }));
  // console.log(data);
};

export const createGiftInfo = (formData) => async (dispatch, getState) => {
  // const { data, statusCode } = await cartAPI.post(`/v2/checkout/gift/note/`, formData);
  const { data, statusCode } = await cartAPI.post(`/api/v1/checkout/gift/note/`, formData);
};

export const updateGiftInfo = (cart, formData) => async (dispatch, getState) => {
  // const { data, statusCode } = await cartAPI.patch(`/v2/checkout/gift/note/${cart}/`, formData);
  const { data, statusCode } = await cartAPI.patch(`/api/v1/checkout/gift/note/${cart}/`, formData);
};

export const deleteGiftInfo = (cart) => async (dispatch, getState) => {
  // const { data, statusCode } = await cartAPI.remove(`/v2/checkout/gift/note/${cart}/`);
  const { data, statusCode } = await cartAPI.remove(`/api/v1/checkout/gift/note/${cart}/`);
};

export const updateGiftText = (formData) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_GIFT_TEXT, payload: formData });
};
