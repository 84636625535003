// import React, { useEffect } from "react";
import { useCookies } from "react-cookie";

import { useSelector, useDispatch } from "react-redux";
// import { addClass, getTotal, makePublicURL, removeClass } from "../../helpers";
// import { COD, CARD_PAYMENT } from "../../helpers/constants";
import ProductEntry from "./ProductEntry";
import "./scss/cart.scss";
import { PHONE_NUMBER } from "../../helpers/constants";
// import { RssFeed } from "@material-ui/icons";
import { WEBSITE_URL, NEW_WEBSITE_URL } from "../../helpers/constants";

export default function SuccessCartSidebar() {
  const [cookies, setCookie] = useCookies();

  const {
    productDetails,
    // discountPrice,
    shipping,
    currency,
    // finalSubtotal,
    // finalTotal,
    // finalShipping,
    // finalDiscount,
    // MrpTotal,
    subTotalPrice,
    // PaymentCharges,
    discountCode,
    additionalOff,
    tempNatCashApplied,
    eligibleNatCash,
    finalTotalPrice,
    paymentCharges,
    discountAmount,
    discountReason,
    discountCodeErrorMessage,
    promotionCosts,
    // promotionDiscountAmount,
  } = useSelector((state) => state.cart);

  const paymentSelector = useSelector((state) => state.payment);
  // const [mrpDiscount, setMrpDiscount] = useState(0);
  // const [total, setTotal] = useState(finalTotalPrice);
  // const [isShipping, setIsShipping] = useState(false);

  // useEffect(() => {
  //   setTotal(getTotal(productDetails, discountPrice, shipping));
  // });

  // useEffect(() => {
  //   let discountPriceOnMrp = MrpTotal - finalSubtotal;
  //   setMrpDiscount(discountPriceOnMrp);
  // }, [finalSubtotal, discountPrice, MrpTotal, shipping]);

  // useEffect(() => {
  //   if (shipping > 0) {
  //     setIsShipping(true);
  //   }
  // }, [shipping]);

  return (
    <div className="right-sidebar cart-items successSidebarJs">
      <div className="sidebar-cart">
        <span className="cart-title">
          Your Items <span className="itemCountJs" />
        </span>
        <ul className="product-list">
          {productDetails.map((product, index) => {
            return (
              <li key={index} className="product productItemJs">
                <ProductEntry
                  productDetails={product}
                  currency={currency}
                  showQuantityBesideName={true}
                  isDiscountCode={discountCode}
                  isDiscountApplied={discountReason ? true : false}
                />
              </li>
            );
          })}
        </ul>
        {/* <div className="order-summary-section">
					<span className="price-title">Order Details</span>
					<div className="summary-row subtotal-row">
						<span className="subtotal-name">Payable Total :</span>
						<span className="product-price">
							{currency} {total}
						</span>
					</div>
					<div className="summary-row subtotal-row">
						<span className="subtotal-name">Amount Paid :</span>
						<span className="product-price">
							{currency} {total}
						</span>
					</div>
					<div className="summary-row subtotal-row">
						<span className="total-name">Delivery by :</span>
						<span className="product-price">23 October 2019</span>
					</div>
				</div> */}
        <div className="order-summary-section">
          <span className="price-title">Bill Details</span>

          <div className="summary-row subtotal-row">
            <span className="subtotal-name">Total MRP</span>
            <span className="product-price">
              {/* {currency} <span className="priceItemsTotalJs">{MrpTotal}</span> */}
              {currency} <span className="priceItemsTotalJs">{subTotalPrice}</span>
            </span>
          </div>

          <div className="summary-row mrpdiscount-row payment-section">
            <span className="total-name total-discount"> Total Discount </span>
            <p className="toltip-wrapper"> i</p>

            <div className="discount-wrapper ">
              <div className="discount-tooltip">
                <div className="discount-tooltip-header">Total discount breakup:</div>

                {discountReason && !discountCodeErrorMessage ? (
                  <div className="summary-row subtotal-row payment-section">
                    <span className="subtotal-name">Coupon Discount</span>
                    <span className="product-price">
                      <span className="shippingJs ">- Rs. {discountAmount}</span>
                    </span>
                  </div>
                ) : (
                  <div className="summary-row subtotal-row payment-section">
                    <span className="subtotal-name">Discount on MRP</span>
                    <span className="product-price">
                      <span className="shippingJs ">- Rs. {discountAmount}</span>
                    </span>
                  </div>
                )}

                {/* {paymentSelector.paymentMode === "online" && paymentCharges && paymentCharges !== 0 ? (
                  <div className="summary-row subtotal-row payment-section">
                    <span className="subtotal-name">Online Payment Discount</span>
                    <span className="product-price">
                      <span className="shippingJs ">- Rs. {paymentCharges * -1}</span>
                    </span>
                  </div>
                ) : null} */}

                {/* {finalDiscount ? (
                  <div className="summary-row subtotal-row payment-section">
                    <span className="subtotal-name">Coupon Discount</span>
                    <span className="product-price">
                      <span className="shippingJs ">- Rs. {finalDiscount}</span>
                    </span>
                  </div>
                ) : null} */}

                {promotionCosts && promotionCosts.length > 0
                  ? promotionCosts.map((item, index) => (
                      <div key={index} className="summary-row subtotal-row payment-section ">
                        <span className="subtotal-name">
                          {item.promotion_quantity} {item.promotion_quantity > 1 ? `items` : `item`} at {currency}{" "}
                          {item.promotion_price} applied
                        </span>
                        <span className="product-price">
                          - {currency} <span className="priceItemsTotalJs">{item.promotion_discount}</span>
                        </span>
                      </div>
                    ))
                  : null}

                {additionalOff > 0 ? (
                  <div className="summary-row subtotal-row payment-section ">
                    <span className="subtotal-name">Additional Discount</span>
                    <span className="product-price">
                      - {currency} <span className="priceItemsTotalJs">{additionalOff}</span>
                    </span>
                  </div>
                ) : null}

                {tempNatCashApplied > 0 ? (
                  <div className="summary-row subtotal-row payment-section ">
                    <span className="subtotal-name">Nat Cash Discount</span>
                    <span className="product-price">
                      - {currency} <span className="priceItemsTotalJs">{tempNatCashApplied}</span>
                    </span>
                  </div>
                ) : null}
              </div>
            </div>

            <span className="product-price discount-color">
              - {currency}{" "}
              <span className="priceItemsTotalJs">
                {discountAmount +
                  additionalOff +
                  tempNatCashApplied +
                  promotionCosts.reduce((sum, item) => (sum += item.promotion_discount), 0)}
                {/* {paymentSelector.paymentMode === CARD_PAYMENT
                  ? mrpDiscount + finalDiscount - PaymentCharges?.online_charges
                  : mrpDiscount + finalDiscount} */}
              </span>
            </span>
          </div>

          {eligibleNatCash > 0 ? (
            <div className="summary-row subtotal-row payment-section ">
              <span className="subtotal-name">You Will Get Nat Cash Worth</span>
              <span className="product-price">
                {currency} <span className="priceItemsTotalJs">{eligibleNatCash}</span>
              </span>
            </div>
          ) : null}

          {/* {additionalOff > 0 ? (
            <div className="summary-row subtotal-row payment-section ">
              <span className="subtotal-name">Additional Discount</span>
              <span className="product-price">
                - {currency} <span className="priceItemsTotalJs">{additionalOff}</span>
              </span>
            </div>
          ) : null} */}

          {/* {tempNatCashApplied > 0 ? (
            <div className="summary-row subtotal-row payment-section ">
              <span className="subtotal-name">Nat Cash Discount</span>
              <span className="product-price">
                - {currency} <span className="priceItemsTotalJs">{tempNatCashApplied}</span>
              </span>
            </div>
          ) : null} */}

          <div className="summary-row subtotal-row payment-section ">
            <span className="subtotal-name">
              {paymentSelector.paymentMode === "cod" ? "Shipping & COD Charges" : "Shipping Charges"}
              <div className="toltip-wrapper">
                {" "}
                i
                {!paymentSelector.paymentMode || paymentSelector.paymentMode === "online" ? (
                  <p className="toltip">Shipping & payment charges are based on address and payment method.</p>
                ) : (
                  <span className="toltip">
                    <div className="discount-tooltip-header">Shipping & COD breakup:</div>

                    <div className="summary-row subtotal-row payment-section">
                      <span className="subtotal-name">Shipping Charge</span>
                      <span className="product-price">
                        <span className="shippingJs ">Rs. {shipping}</span>
                      </span>
                    </div>
                    <div className="summary-row subtotal-row payment-section">
                      <span className="subtotal-name">COD Charge</span>
                      <span className="product-price">
                        <span className="shippingJs ">Rs. {paymentCharges}</span>
                      </span>
                    </div>
                  </span>
                )}
              </div>
            </span>

            {shipping || paymentCharges ? (
              <span className="product-price">
                {currency}
                <span className="shippingJs">
                  {shipping + paymentCharges}
                  {/* {paymentSelector.paymentMode === COD ? finalShipping + PaymentCharges?.cod_charges : finalShipping} */}
                </span>
              </span>
            ) : (
              <span className="product-price">
                <span className="shippingJs strikeshipping">Rs. 50</span> <span className="discount-color">FREE</span>
              </span>
            )}
          </div>

          {/* <div className="summary-row mrpdiscount-row">
            <span className="total-name">Discount on MRP</span>
            <span className="product-price discount-color">
              - {currency} <span className="priceItemsTotalJs">{mrpDiscount}</span>
            </span>
          </div> */}

          {/* {finalDiscount ? (
            <div className="summary-row discount-row discountValueJs active">
              <span className="total-name">Coupon Discount</span>
              <span className="product-price">
                {currency} <span className="discountJs">{finalDiscount}</span>
              </span>
            </div>
          ) : null} */}

          {/* <div className="summary-row subtotal-row">
            <span className="subtotal-name">
              Shipping Charges
            </span>
            {isShipping ?
              <span className="product-price">
                {currency}
                <span className="shippingJs">{finalShipping}</span>
              </span>
              :
              <span className="product-price">
                <span className="shippingJs strikeshipping">Rs. 50</span> <span className='discount-color'>FREE</span>
              </span>
            }

          </div> */}

          <div className="summary-row total-row">
            <span className="total-name">
              {paymentSelector.paymentMode === "cod" ? "Cash On Delivery" : "Amount Paid"}
            </span>
            <span className="total-price">
              {currency}
              {paymentSelector.paymentMode === "cod" ? (
                <span className="priceTotalJs">
                  {finalTotalPrice}
                  {/* {finalSubtotal +
                    finalShipping -
                    finalDiscount +
                    PaymentCharges?.cod_charges -
                    additionalOff -
                    tempNatCashApplied} */}
                </span>
              ) : (
                <span className="priceTotalJs">
                  {finalTotalPrice}
                  {/* {finalSubtotal +
                    finalShipping -
                    finalDiscount +
                    PaymentCharges?.online_charges -
                    additionalOff -
                    tempNatCashApplied} */}
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="wrap-btn">
        <div className="wa-contact-block">
          <span className="subtitle">For updates, reach out to us on this number.</span>

          <p className="tel">
            <i
              className="fa fa-whatsapp"
              aria-hidden="true"
              style={{ marginRight: 10, fontSize: 20, color: "#075e54" }}
            ></i>
            {PHONE_NUMBER}
          </p>
        </div>
        <button
          className="form-btn green-btn"
          onClick={() => {
            window.location.assign(cookies.homeUrl === "new.nathabit.in" ? NEW_WEBSITE_URL : WEBSITE_URL);
            // window.location.href = cookies.website_type === "B" ? NEW_WEBSITE_URL : WEBSITE_URL;
          }}
        >
          Continue Shopping
        </button>
      </div>
    </div>
  );
}
