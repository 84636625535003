export const VERIFIED_USER = "verifiedUser";
export const GUEST_USER = "guestUser";
export const EMPTY = "empty";
export const VALID = "valid";
export const INVALID = "invalid";
export const COD = "cashOnDelivery";
export const CARD_PAYMENT = "cardPayment";
export const WEBSITE_URL = "https://nathabit.in";
// export const NEW_WEBSITE_URL = "https://new.nathabit.in";
export const NEW_WEBSITE_URL = "https://nathabit.in";

export const PHONE_NUMBER = "+91 8527 000 777";

export const INCORRECT_MOBILE_NO = "incorrectMobileNumber";
export const CONTACT_SUPPORT = "contactSupport";
