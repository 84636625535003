import { authAPI, cartAPI, customerAPI } from "../../helpers/api";
import { ACTIVATE_ADDRESS, REMOVE_OVERLAY } from "../checkout/actions";
import { GET_EXISTING_ADDRESS, GET_LOCAL_ADDRESS } from "../address/actions";
import { sendEvent, sendCheckoutOptionGoogleAnalytics, sendSubCheckoutOptionGA } from "../../helpers/ga";
import { VERIFIED_USER } from "../../helpers/constants";
import { getSavedAddressFromLocalStorage } from "../../helpers/addressStorage";
import { UPDATE_NAT_CASH, updateCosting } from "../cart/actions";

export const UPDATE_LOGIN = "updateLogin";
export const ADD_CONTACT = "addContact";
export const SUCCESSFULL_LOGIN = "successfullLogin";
export const TOGGLE_LOADING = "toggleLoginLoading";
export const TOGGLE_LOGIN_STEP_LOADING = "toggleLoginStepLoading";
export const UPDATE_OTP_ERROR_MESSAGE = "updateOTPErrorMessage";
export const UPDATE_OPTIONAL_EMAIL = "updateOptionalEmail";
export const UPDATE_VERIFICATION_ERROR = "updateVerificationError";
export const UPDATE_GUEST_USER_ID = "updateGuestUserId";

export const sentVerificationCode = async (number, send_whatsapp = false) => {
  const reqData = {
    phone: number,
    send_on_whatsapp: send_whatsapp,
  };
  const { statusCode } = await authAPI.post(`/v2/auth/otp/`, reqData);
  if (statusCode === 200) {
    sendSubCheckoutOptionGA(2, "otpSent");
    try {
      window.Moengage.track_event("OTP Requested", { requested: true });
    } catch (err) {
      console.log(err);
    }
  } else {
    sendSubCheckoutOptionGA(2, "otpSendingFailure");
  }

  //   dispatch({ type: SUCCESSFULL_LOGIN, payload: data });
  //   dispatch({
  //     type: GET_EXISTING_ADDRESS,
  //     payload: data.addresses,
  //   });

  //   dispatch({
  //     type: ACTIVATE_ADDRESS,
  //   });

  //   dispatch({
  //     type: REMOVE_OVERLAY,
  //   });
  // }
};

export const updateCartInfo = async (formData, cartID) => {
  // const { data, statusCode } = await cartAPI.patch(`/new/checkout/add/${cartID}/`, formData);
  const { data, statusCode } = await cartAPI.patch(`/api/v1/checkout/add/${cartID}/`, formData);
  if (statusCode === 200 && formData.customer_email) {
    const Data = {
      token: data.token,
    };
    cartAPI.post(`/v1/checkout/verify/email`, Data);
  }
};

export const verifyCode = (phone, otp, checkoutId, email) => async (dispatch) => {
  dispatch({ type: TOGGLE_LOADING });
  const reqData = {
    phone,
    otp,
  };
  const { data, statusCode } = await authAPI.post("/v2/auth/verify/", reqData);
  if (statusCode === 200) {
    if (data.is_verified) {
      localStorage.removeItem("guestAddressesID");
      sendSubCheckoutOptionGA(2, "otpVerfied");
      dispatch(getCustomerDataV2(phone));
      let formData = { customer_mobile: phone, verified_cart: true, customer_id: data.nh_id, login_attempted: true };
      if (email && email !== "") {
        formData["customer_email"] = email;
      }

      // updateCartInfo(formData, checkoutId);
      dispatch(updateCosting(checkoutId, formData));
      sendEvent("loginOTPVerify", "Login OTP Verification", "Submit", {
        result: "success",
      });
      try {
        window.Moengage.add_unique_user_id(data.nh_id);
        window.Moengage.add_email(email);
        window.Moengage.add_mobile(phone);
        window.Moengage.track_event("Login", {
          phone: phone,
          guest_mode: false,
          referrer_section: "checkout",
          referrer_page: "checkout",
        });
      } catch (err) {
        console.log(err);
      }
      dispatch({ type: UPDATE_OTP_ERROR_MESSAGE, payload: "" });
    } else {
      sendSubCheckoutOptionGA(2, "otpVerficationFailure");
      dispatch({
        type: UPDATE_OTP_ERROR_MESSAGE,
        payload: "Wrong OTP Entered",
      });
    }
    dispatch({ type: TOGGLE_LOADING });
  }
};

export const getCustomerData = (phone) => async (dispatch) => {
  dispatch({ type: TOGGLE_LOGIN_STEP_LOADING });
  const { data, statusCode } = await authAPI.get(`/v1/auth/get/customer?phone=${phone}`);

  if (statusCode === 200) {
    dispatch({
      type: SUCCESSFULL_LOGIN,
      payload: { ...data, phoneNumber: phone },
    });
    sendSubCheckoutOptionGA(2, "existingDataFetched");

    const localAddress = getSavedAddressFromLocalStorage();
    dispatch({
      type: GET_LOCAL_ADDRESS,
      payload: localAddress,
    });
    dispatch({
      type: GET_EXISTING_ADDRESS,
      payload: data.addresses,
    });

    dispatch({
      type: ACTIVATE_ADDRESS,
    });
  } else {
    sendSubCheckoutOptionGA(2, "existingDataFetchFailure");
  }
  dispatch({ type: TOGGLE_LOGIN_STEP_LOADING });
  window.clarity("identify", "phoneNo", phone);
  // window.__insp.push(["identify", phone]);
  sendCheckoutOptionGoogleAnalytics(2, "verifiedUser");
};

export const getCustomerDataV2 = (phone) => async (dispatch) => {
  dispatch({ type: TOGGLE_LOGIN_STEP_LOADING });
  const { data, statusCode } = await authAPI.get(`/v1/user/addresses/`);

  if (statusCode === 200) {
    dispatch({
      type: SUCCESSFULL_LOGIN,
      payload: { ...data, phoneNumber: phone },
    });
    sendSubCheckoutOptionGA(2, "existingDataFetched");

    const localAddress = [data.addresses[0]] || [];
    dispatch({
      type: GET_LOCAL_ADDRESS,
      payload: [],
    });
    dispatch({
      type: GET_EXISTING_ADDRESS,
      payload: data.addresses,
    });

    dispatch({
      type: ACTIVATE_ADDRESS,
    });
  } else {
    dispatch({
      type: GET_EXISTING_ADDRESS,
      payload: [],
    });

    dispatch({
      type: ACTIVATE_ADDRESS,
    });
    dispatch({
      type: SUCCESSFULL_LOGIN,
      payload: { ...data, phoneNumber: phone },
    });
    sendSubCheckoutOptionGA(2, "existingDataFetchFailure");
  }
  dispatch({ type: TOGGLE_LOGIN_STEP_LOADING });
  // window.__insp.push(["identify", phone]);
  sendCheckoutOptionGoogleAnalytics(2, "verifiedUser");
};

export const verifyCodeGuest = (phone, otp, orderName) => async (dispatch) => {
  dispatch({ type: TOGGLE_LOADING });
  const reqData = {
    phone,
    otp,
  };
  const { data, statusCode } = await authAPI.post("/v2/auth/verify/", reqData);
  const { nh_id, email } = data;
  if (statusCode === 200) {
    if (data.is_verified) {
      sendEvent("loginOTPVerify", "Login OTP Verification", "Submit", {
        result: "success",
      });
      try {
        window.Moengage.add_unique_user_id(nh_id);
        window.Moengage.add_email(email);
        window.Moengage.add_mobile(phone);
        window.Moengage.track_event("Login", {
          phone: phone,
          guest_mode: false,
          referrer_section: "verify_order",
          referrer_page: "thank_you",
        });
      } catch (err) {
        console.log(err);
      }
      const formData = {
        order_name: orderName,
        phone,
      };
      // const { data, statusCode } = await cartAPI.post("/new/checkout/verify/order/", formData);
      const { data, statusCode } = await cartAPI.post("/api/v1/checkout/verify/order/", formData);
      if (statusCode === 200) {
        dispatch({ type: UPDATE_OTP_ERROR_MESSAGE, payload: "" });
        dispatch({
          type: UPDATE_LOGIN,
          payload: {
            mode: VERIFIED_USER,
            phoneNumber: phone,
            OTP: otp,
          },
        });
      }

      // TODO : When order verification goes wrong
      // Send response with "error" field as "contactSupport" or "incorrectMobileNumber" and "errorMessage" field
      // The above fields can be empty if no error occurs
      dispatch({
        type: UPDATE_VERIFICATION_ERROR,
        payload: {
          verificationError: data.error,
          verificationErrorMessage: data.errorMessage,
        },
      });
    } else {
      sendEvent("loginOTPVerify", "Login OTP Verification", "Submit", {
        result: "failure",
      });
      dispatch({
        type: UPDATE_OTP_ERROR_MESSAGE,
        payload: "Wrong OTP Entered",
      });
    }

    dispatch({ type: TOGGLE_LOADING });
  }
};

export const getGuestAddresses = (user_id) => async (dispatch) => {
  dispatch({ type: TOGGLE_LOGIN_STEP_LOADING });
  let addresses_id = JSON.parse(localStorage.getItem("guestAddressesID")) || [];
  const reqData = {
    user_id,
    addresses_id,
  };
  const { data, statusCode } = await authAPI.post(`/v1/guest/get_addresses/`, reqData);

  if (statusCode === 200) {
    // dispatch({
    //   type: SUCCESSFULL_LOGIN,
    //   payload: { ...data, phoneNumber: phone },
    // });
    // sendSubCheckoutOptionGA(2, "existingDataFetched");

    const localAddress = [data.addresses[0]] || [];
    dispatch({
      type: GET_LOCAL_ADDRESS,
      payload: [],
    });
    dispatch({
      type: GET_EXISTING_ADDRESS,
      payload: data.addresses,
    });

    dispatch({
      type: ACTIVATE_ADDRESS,
    });
  } else {
    // sendSubCheckoutOptionGA(2, "existingDataFetchFailure");
  }
  dispatch({ type: TOGGLE_LOGIN_STEP_LOADING });
  // window.__insp.push(["identify", phone]);
  // sendCheckoutOptionGoogleAnalytics(2, "verifiedUser");
};

export const guestUserData = (phone, email, checkoutId) => async (dispatch) => {
  dispatch({ type: TOGGLE_LOADING });
  const reqData = {
    phone,
    // email,
  };
  const { data, statusCode } = await authAPI.post("/v1/guest/profile/", reqData);
  if (statusCode === 200) {
    if (data.user_id) {
      dispatch(getGuestAddresses(data.user_id));

      dispatch({ type: UPDATE_GUEST_USER_ID, payload: data.user_id });

      let updateData = {
        customer_mobile: phone,
        verified_cart: false,
        customer_id: data.user_id,
        login_attempted: true,
      };
      if (email && email !== "") {
        dispatch({ type: UPDATE_OPTIONAL_EMAIL, payload: { email } });
        updateData["customer_email"] = email;
      }
      // updateCartInfo(updateData, checkoutId);
      dispatch(updateCosting(checkoutId, updateData));

      //   sendSubCheckoutOptionGA(2, "otpVerfied");
      //   dispatch(getCustomerDataV2(phone));
      //   let formData = { customer_mobile: phone };
      //   if (email && email !== "") formData.customer_email = email;
      //   updateCartInfo(formData, checkoutId);
      //   sendEvent("loginOTPVerify", "Login OTP Verification", "Submit", {
      //     result: "success",
      //   });
      //   try {
      //     window.Moengage.add_unique_user_id(data.nh_id);
      //     window.Moengage.add_email(email);
      //     window.Moengage.add_mobile(phone);
      //     window.Moengage.track_event("Login", {
      //       phone: phone,
      //       guest_mode: false,
      //       referrer_section: "checkout",
      //       referrer_page: "checkout",
      //     });
      //   } catch (err) {
      //     console.log(err);
      //   }
      //   dispatch({ type: UPDATE_OTP_ERROR_MESSAGE, payload: "" });
    }
    // else {
    //   //   sendSubCheckoutOptionGA(2, "otpVerficationFailure");
    //   //   dispatch({
    //   //     type: UPDATE_OTP_ERROR_MESSAGE,
    //   //     payload: "Wrong OTP Entered",
    //   //   });
    // }
    dispatch({ type: TOGGLE_LOADING });
  }
};

export const getCustomerNatCash = () => async (dispatch) => {
  const { data, statusCode } = await authAPI.get(`/v1/user/natcash/`);
  if (statusCode === 200) {
    dispatch({
      type: UPDATE_NAT_CASH,
      payload: { natCash: data.usable_amount },
    });
  } else {
  }
};

export const checkNatCashApplicable = (amount, checkout_token) => {
  // return authAPI.post(`/v1/user/natcash/v2/check/`, { amount, checkout_token });
  return authAPI.post(`/v1/user/natcash/v3/check/`, { amount, checkout_token });
};
