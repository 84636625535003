import React from "react";

export default function ProductEntry(props) {
  // const { productName, productCategory, productQuantity, productPrice, productThumbnail } = props.productDetails;

  return (
    <div style={{ display: "flex", flex: 1, height: "20vh", flexDirection: "column" }}>
      <div className="" style={{ display: "flex", flex: 0.8, justifyContent: "space-between" }}>
        <div className="loading" style={{ display: "flex", flex: 0.15, height: "50%" }}></div>
        <div style={{ display: "flex", flex: 0.65, flexDirection: "column" }}>
          <div className="loading" style={{ display: "flex", flex: 0.4 }}></div>
          <div className="loading" style={{ display: "flex", flex: 0.3, marginTop: 5 }}></div>
        </div>
        <div className="loading" style={{ display: "flex", flex: 0.1, height: "30%" }}></div>
      </div>
      <div className="loading" style={{ display: "flex", flex: 0.1 }}></div>
    </div>
  );
}
