export function setDomainCookie(cookie_name, cookie_value, expires) {
  let existingCookie = getPhoneCookie();
  if (!existingCookie) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expires);
    var cookieName = cookie_name.trim();
    var cValue =
      escape(cookie_value) +
      (expires == null ? "" : "; expires=" + exdate.toUTCString()) +
      ";path='/';domain=.nathabit.in";
    document.cookie = cookieName + "=" + cValue;
  }
}

export function getPhoneCookie() {
  let userPhoneCookie = document.cookie.split("; ").find((row) => row.startsWith("userPhone="));
  if (userPhoneCookie && userPhoneCookie !== "") {
    userPhoneCookie = userPhoneCookie.split("=")[1];
  }

  if (typeof userPhoneCookie !== "undefined" && userPhoneCookie.trim() !== "") {
    return userPhoneCookie;
  }
}

export function deleteCookie(name) {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=nathabit.in; path=/";
}

export function getCookieFromName(cName) {
  return document.cookie
  .split('; ')
  .find(row => row.startsWith(cName))
  ?.split('=')[1] || null;
}
