export const ACTIVATE_ADDRESS = "activateAddress";
export const ACTIVATE_PAYMENT = "activatePayment";
export const ACTIVATE_LOGIN = "activateLogin";
export const REMOVE_OVERLAY = "removeOverlay";
export const SHOW_MODAL = "showModal";
export const CHANGE_MODAL_KEY = "changeModalKey";
export const CLOSE_MODAL = "closeModal";
export const DELIVERY_ADDRESS_STEP = "deliveryAddressStep";
export const PROCEED_PAY_STEP = "proceedPayStep";
export const DISABLE_ADDRESS = "disableAddress";
export const ENABLE_ADDRESS = "enableAddress";
export const CHANGE_ADDRESS = "changeAddress";
export const CHANGE_ADDRESS_BACK_BUTTON = "changeAddressBackButton";
export const PAYMENT_DONE = "paymentDone";
export const SUCCESS_CHECKOUT = "successCheckout";
export const BACK_TO_ADDRESS = "backToAddress";
export const TOGGLE_PAGE_LOADING_STATE = "togglePageLoadingState";
export const UPDATE_VALID_CHECKOUT = "updateValidCheckout";
export const SET_ORDER_ID = "setOrderId";
export const DISABLE_ADDRESS_SELECTION = "disableAddressSelection";
export const INABLE_ADDRESS_SELECTION = "inableAddressSelection";
