export default function ErrorMessage({ message, setOrderErrorMessage }) {
  return (
    <div className={`error-message ${message === "" ? "" : "is-active"}`}>
      <div className="error-text-container">
        <img src="/assets/img/error-warning.svg" alt="close primary" height={20} width={20} />
        <div className="error-text">{message}</div>
      </div>

      <div
        onClick={() => setOrderErrorMessage("")}
        className={`error-message-cross-icon ${message === "" ? "" : "is-active"}`}
      >
        <img src="/assets/img/error-cross-icon.svg" alt="close primary" height={15} width={15} />
      </div>
    </div>
  );
}
