import { PAYMENT_MODE } from "./actions";

const paymentInitialState = {
	paymentMode: "",
};

export const paymentReducer = (state = paymentInitialState, action) => {
	switch (action.type) {
		case PAYMENT_MODE:
			return {
				...state,
				paymentMode: action.payload,
			};
		default:
			return state;
	}
};
