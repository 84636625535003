import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addClass, makePublicURL, removeClass } from "../../helpers";
import { cartAPI } from "../../helpers/api";
import {
  sendSubCheckoutOptionGA,
  sendCheckoutOptionGoogleAnalytics,
  sendShippingDataGoogleAnalytics,
  sendAddressSelectionToMoengage,
} from "../../helpers/ga";
import {
  CANDIDATE_ADDRESS,
  SELECTED_ADDRESS,
  SET_FIRST_MOUNT_ADDRESS,
  UPDATE_ADDRESS_LOADING,
} from "../../store/address/actions";
import {
  ACTIVATE_ADDRESS,
  CHANGE_ADDRESS,
  CHANGE_ADDRESS_BACK_BUTTON,
  PROCEED_PAY_STEP,
  REMOVE_OVERLAY,
  SHOW_MODAL,
  DISABLE_ADDRESS,
  ACTIVATE_PAYMENT,
} from "../../store/checkout/actions";
import {
  fetchCartContent,
  UPDATE_ADDRESS_DELIVERABILTY_ERROR,
  removeBulkCartItem,
  updateCosting,
} from "../../store/cart/actions";
import AddressEntry from "./AddressEntry";
import "./scss/address.scss";
import LoadingBlock from "../checkout/LoadingBlock";
import { GUEST_USER, CARD_PAYMENT, COD } from "../../helpers/constants";
export default function AddAddressStepComponent() {
  const loginSelector = useSelector((state) => state.login);
  const addressSelector = useSelector((state) => state.address);
  const checkoutSelector = useSelector((state) => state.checkout);
  const cartSelector = useSelector((state) => state.cart);
  // const { productDetails, shipping, currency, discountPrice, itemWithError, PaymentCharges, finalTotalPrice } =
  //   useSelector((state) => state.cart);
  // const paymentSelector = useSelector((state) => state.payment);
  const [isMobile, setIsMobile] = useState(true);

  // const [total, setTotal] = useState(finalTotalPrice);
  // getTotal(productDetails, discountPrice, shipping, paymentSelector.paymentMode, PaymentCharges)
  const dispatch = useDispatch();

  const {
    outstation,
    cartId,
    checkoutId,
    addressDeliverabilityError,
    // additionalOff,
    // natCashApplied,
    productDetails,
    // shipping,
    currency,
    // discountPrice,
    itemWithError,
    // PaymentCharges,
    finalTotalPrice,
    paymentCharges,
    // orderName,
  } = cartSelector;

  const { addressDetails, canAddressBeSelected } = addressSelector;
  // const [loading, setLoading] = useState(addressSelector.loading);
  // const [addressType, setAddressType] = useState("");
  // const [addressLocation, setAddressLocation] = useState("");
  // const [deliveryInfo, setDeliveryInfo] = useState("");
  const stepAddressJsMobileRef = useRef();
  const stepChooseAddressJsClassRef = useRef();
  const selectAddressBtnSectionRef = useRef();
  const selectAddressSelectionSectionRef = useRef();
  const deliverAddressJsRef = useRef();
  const changeAddressRef = useRef();
  const showModalMobileRef = useRef();
  const showModalDesktopRef = useRef();
  const changeAddressBackBtnRef = useRef();
  const [firstMount, setFirstMount] = useState(true);
  const [firstReviewCartMount, setFirstReviewCartMount] = useState(true);
  const [addressString, setAddressString] = useState("");
  const [firstMountAddressIsSetOnce, setFirstMountAddressIsSetOnce] = useState(false);
  const [removeProductLoading, setRemoveProductLoading] = useState(false);
  // const [tempPaymentCharge, setTempPaymentCharge] = useState(0);

  const [showAddAddressPane, setShowAddAddressPane] = useState(true);

  const changeAddressRefHandleClick = (e) => {
    dispatch({ type: ACTIVATE_ADDRESS });
    dispatch({
      type: REMOVE_OVERLAY,
      payload: { removeOverlay: false },
    });
    removeClass(stepAddressJsMobileRef, "hide-mobile");
    // Always show list on clicking change
    selectAddressSelectionSectionRef.current.classList.remove("d-none");
    selectAddressBtnSectionRef.current.classList.add("d-none");
    dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: false } });
  };

  const handleProceedToPayClick = (e) => {
    dispatch({ type: PROCEED_PAY_STEP });
  };

  const handleChangeAddressBackBtnClick = (e) => {
    dispatch({ type: CHANGE_ADDRESS_BACK_BUTTON });
  };

  const showModal = (e) => {
    e.preventDefault();
    sendSubCheckoutOptionGA(3, "openedAddAddressModal");
    dispatch({ type: CANDIDATE_ADDRESS, payload: null });
    dispatch({ type: SHOW_MODAL });
  };

  const handleResize = (e) => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setIsMobile(true);
      if (checkoutSelector.currentState == ACTIVATE_ADDRESS || checkoutSelector.currentState == CHANGE_ADDRESS) {
        removeClass(stepAddressJsMobileRef, "hide-mobile");
      } else {
        addClass(stepAddressJsMobileRef, "hide-mobile");
      }
    } else {
      setIsMobile(false);
      removeClass(stepAddressJsMobileRef, "hide-mobile");
    }
  };

  const showAddressButtonSection = () => {
    selectAddressSelectionSectionRef.current.classList.add("d-none");
    selectAddressBtnSectionRef.current.classList.remove("d-none");
    setRemoveProductLoading(false);
    dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: true } });
  };

  const hideAddressButtonSection = () => {
    selectAddressSelectionSectionRef.current.classList.remove("d-none");
    selectAddressBtnSectionRef.current.classList.add("d-none");
    dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: false } });
  };

  useEffect(() => {
    if (!firstMount && !checkoutSelector.showModal) {
      if (window.matchMedia("(max-width: 767px)").matches) {
        showModalMobileRef.current.click();
      } else {
        showModalDesktopRef.current.click();
      }
    }
    setFirstMount(false);
  }, [checkoutSelector.showModal, addressSelector.candidateAddress]);

  // useEffect(() => {
  //   // if (paymentSelector.paymentMode === COD) {
  //   //   setTempPaymentCharge(PaymentCharges.cod_charges);
  //   // } else if (paymentSelector.paymentMode === CARD_PAYMENT) {
  //   //   setTempPaymentCharge(PaymentCharges.online_charges);
  //   // }
  //   if (checkoutId) {
  //     dispatch(updatePaymentMode(checkoutId, paymentSelector.paymentMode));
  //   }
  // }, [checkoutId]);

  useLayoutEffect(() => {
    let addresToDisplay = "";
    if (addressSelector.selectedAddress) {
      if (addressSelector.selectedAddress.apartmentName)
        addresToDisplay += addressSelector.selectedAddress.apartmentName + ", ";
      if (addressSelector.selectedAddress.addressStreet)
        addresToDisplay += addressSelector.selectedAddress.addressStreet + ", ";
      if (addressSelector.selectedAddress.addressLocation)
        addresToDisplay += addressSelector.selectedAddress.addressLocation + ", ";

      addresToDisplay += addressSelector.selectedAddress.city + " - " + addressSelector.selectedAddress.pincode;
      setAddressString(addresToDisplay);
    }

    // if (addressSelector.selectedAddress && loginSelector.mode && loginSelector.phoneNumber) {
    if (addressSelector.selectedAddress && cartId) {
      let formData = {
        checkout_id: cartId,
        address: addressSelector.selectedAddress,
      };

      // let payload = {
      //   user_address_id: addressSelector.selectedAddress.addressID,
      //   customer_id: loginSelector.mode === "verifiedUser" ? loginSelector.shpID : loginSelector.guestUserId,
      //   verified_cart: loginSelector.mode === "verifiedUser",
      //   customer_mobile: loginSelector.phoneNumber,
      // };

      try {
        // cartAPI.post("/v2/checkout/store/checkout/address", formData);
        cartAPI.post("/api/v1/checkout/store/checkout/address", formData);
        // cartAPI.patch(`/new/checkout/add/${checkoutId}/`, payload);
        // dispatch(updateCosting(checkoutId, payload));
      } catch (e) {
        // console.log(e);
      }
    }
  }, [
    addressSelector.selectedAddress,
    // checkoutId,
    cartId,
    // loginSelector.mode,
    // loginSelector.shpID,
    // loginSelector.guestUserId,
    // loginSelector.phoneNumber,
  ]);

  useEffect(() => {
    if (addressSelector.selectedAddress && loginSelector.mode && loginSelector.phoneNumber && checkoutId) {
      let payload = {
        user_address_id: addressSelector.selectedAddress.addressID,
        customer_id: loginSelector.mode === "verifiedUser" ? loginSelector.shpID : loginSelector.guestUserId,
        verified_cart: loginSelector.mode === "verifiedUser",
        customer_mobile: loginSelector.phoneNumber,
        login_attempted: true,
      };
      if (!window.location.href.includes("/order/placed")) {
        dispatch(updateCosting(checkoutId, payload));
      }
    }
  }, [
    addressSelector.selectedAddress,
    checkoutId,
    loginSelector.mode,
    loginSelector.shpID,
    loginSelector.guestUserId,
    loginSelector.phoneNumber,
    dispatch,
  ]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (!firstReviewCartMount) {
      showAddressButtonSection();
    }
    setFirstReviewCartMount(false);
  }, [addressSelector.reviewCart]);

  useEffect(() => {
    if (checkoutSelector.currentState == ACTIVATE_ADDRESS) {
      removeClass(stepChooseAddressJsClassRef, "disable");
      removeClass(stepAddressJsMobileRef, "hide-mobile");
    }
  }, [checkoutSelector]);

  // useEffect(() => {
  //   setTotal(getTotal(productDetails, discountPrice, shipping, paymentSelector.paymentMode, PaymentCharges));
  // }, [productDetails, discountPrice, shipping, paymentSelector.paymentMode, PaymentCharges, isMobile]);

  const triggerAddressSelection = () => {
    if (addressSelector.firstMountAddress) {
      // if (addressSelector.firstMountAddress.addressID == addressDetails[0].addressID) {
      if (!addressDeliverabilityError) {
        dispatch({ type: ACTIVATE_PAYMENT });
        dispatch({ type: DISABLE_ADDRESS });
        dispatch({ type: SELECTED_ADDRESS, payload: addressSelector.firstMountAddress });
        sendCheckoutOptionGoogleAnalytics(4, "noDeliveryErrorInDefault", productDetails);
        sendShippingDataGoogleAnalytics(finalTotalPrice, productDetails);
        sendAddressSelectionToMoengage(addressSelector.firstMountAddress, "pre-selected");
        dispatch({ type: SET_FIRST_MOUNT_ADDRESS, payload: null });
        dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: true } });
      } else {
        dispatch({ type: UPDATE_ADDRESS_DELIVERABILTY_ERROR, payload: false });
        dispatch({ type: SET_FIRST_MOUNT_ADDRESS, payload: null });
      }
      // }
      dispatch({ type: UPDATE_ADDRESS_LOADING, payload: false });
    }
  };

  const onRemoveProducts = () => {
    sendSubCheckoutOptionGA(4, "removedProductsWithDeliverabilityError");
    setRemoveProductLoading(true);
    dispatch(removeBulkCartItem(productDetails, cartId));
  };

  useEffect(() => {
    // if (firstMount) {
    if (!firstMount && !firstMountAddressIsSetOnce) {
      if (addressDetails.length) {
        let defaultAddress = addressDetails[0];
        addressDetails.map((item) => {
          item.default && (defaultAddress = item);
        });
        // dispatch(fetchCartContent(cartId, true, defaultAddress.city));
        sendCheckoutOptionGoogleAnalytics(3, "defaultAddress", productDetails);
        sendShippingDataGoogleAnalytics(finalTotalPrice, productDetails);
        dispatch({ type: SET_FIRST_MOUNT_ADDRESS, payload: defaultAddress });
        setFirstMountAddressIsSetOnce(true);
      } else {
        dispatch({ type: UPDATE_ADDRESS_LOADING, payload: false });
      }
    }
    // }
  }, [addressDetails]);

  useEffect(() => {
    if (canAddressBeSelected) {
      triggerAddressSelection();
    }
  }, [addressSelector.firstMountAddress, canAddressBeSelected]);

  useEffect(() => {
    if (loginSelector.mode == GUEST_USER) {
      // setLoading(false);
      dispatch({ type: UPDATE_ADDRESS_LOADING, payload: false });
    }
  }, [loginSelector.mode]);

  if (
    !showAddAddressPane &&
    addressDetails.length === 0 &&
    loginSelector.phoneNumber &&
    loginSelector.phoneNumber !== ""
  ) {
    return (
      <div className="proceed-to-add-address-pane">
        <button className="price-btn">
          <span className="price-text">
            {currency} {finalTotalPrice - paymentCharges}
          </span>
          <span className="price-desc">Total bill amount</span>
        </button>
        <button
          onClick={() => {
            setShowAddAddressPane(true);
            dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: false } });
          }}
          className="proceed-to-add-address-btn"
        >
          Proceed to add address
        </button>
      </div>
    );
  }

  return (
    <>
      {addressDetails.length === 0 && loginSelector.phoneNumber && loginSelector.phoneNumber !== "" ? (
        <div
          className="add-address-modal-backdrop"
          onClick={() => {
            setShowAddAddressPane(false);
            dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: true } });
          }}
        ></div>
      ) : null}

      <div className="step-item step-add-address wrapAddAddressJs">
        {/* <div class="step-number">STEP <span class="stepNumberJs">2</span></div> */}
        <div className="step-number">STEP 2</div>
        {addressSelector.loading && checkoutSelector.currentState == ACTIVATE_ADDRESS ? <LoadingBlock /> : <></>}

        <div
          className={`step-body ${
            addressSelector.loading && checkoutSelector.currentState == ACTIVATE_ADDRESS ? "d-none" : ""
          }`}
        >
          {/* for Desktop */}
          <div className="wrap-choose-address">
            <span className="step-title">Choose a delivery address</span>
            <div
              ref={stepChooseAddressJsClassRef}
              className="wrap-add-address stepChooseAddressJs stepAddAddressJs disable"
            >
              <span className="subtitle">Please select a delivery address for the shipment.</span>
              <div className="step-content selected-addresses">
                {addressDetails.map((address, index) => {
                  return <AddressEntry key={index} addressDetails={address} />;
                })}
                <div className="notify-wrap add-address-section wrap-new-address">
                  <div className="conatainer p-0">
                    <div className="row">
                      <div className="col-3">
                        {/* <img src={makePublicURL("img/address.png")} alt="" className="address-img" /> */}
                        <i
                          className="fa fa-map-marker"
                          aria-hidden="true"
                          style={{ fontSize: 40, color: "#344254" }}
                        ></i>
                      </div>
                      <div className="col-9 pl-0">
                        <span className="title">Add New Address</span>
                        <span className="subtitle">You can add a new address here.</span>
                        <span className="cart-title back-btn change-address-btn changeAddressBtn">
                          <img src={makePublicURL("img/back-arrow.png")} alt="" className="back-arrow" />
                          Change address
                        </span>
                        <button
                          ref={showModalDesktopRef}
                          type="button"
                          className="form-btn green-btn modalBtn"
                          data-toggle="modal"
                          data-direction="left"
                          data-target="#mapModal"
                          onClick={showModal}
                        >
                          Add Address
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="img-wrap add-new">
									<img
										src={makePublicURL("img/address.png")}
										alt=""
										className="address-img"
									/>
								</div>
								<div className="description">
									<span className="title">Add New Address</span>
									<span className="subtitle">
										You can add a new address here.
									</span>
									<span className="cart-title back-btn change-address-btn changeAddressBtn">
										<img
											src={makePublicURL("img/back-arrow.png")}
											alt=""
											className="back-arrow"
										/>
										Change address
									</span>
									<button
										ref={showModalDesktopRef}
										type="button"
										className="form-btn green-btn modalBtn"
										data-toggle="modal"
										data-direction="left"
										data-target="#mapModal"
										onClick={showModal}
									>
										Add new
									</button>
								</div> */}
                </div>
              </div>
            </div>
          </div>

          {/* for Mobile */}
          <div ref={stepAddressJsMobileRef} className="stepAddAddressJs wrap-add-address wrap-new-user hide-mobile">
            <div
              ref={selectAddressSelectionSectionRef}
              id="selectAddressContentSection"
              style={{
                background: "white",
              }}
            >
              {addressDetails.length === 0 && loginSelector.phoneNumber && loginSelector.phoneNumber !== "" ? (
                <div style={{ height: "16px" }}>
                  <span
                    className="close-btn"
                    onClick={() => {
                      setShowAddAddressPane(false);
                      dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: true } });
                    }}
                  >
                    <img src={makePublicURL("img/close-button.svg")} alt="close btn" />
                  </span>
                </div>
              ) : null}

              <span className="step-title">
                {loginSelector.firstName && loginSelector.firstName !== "Mrs" && loginSelector.firstName !== "Mr."
                  ? `Hi ${/\d/.test(loginSelector.firstName) ? "" : loginSelector.firstName}!`
                  : "Hi!"}
                <a
                  ref={showModalMobileRef}
                  className="subtitle"
                  data-toggle="modal"
                  data-direction="left"
                  data-target="#mapModal"
                  onClick={showModal}
                  style={{ right: 20, position: "absolute", color: "#38a176" }}
                >
                  + Add Address
                </a>
              </span>

              <span className="subtitle">
                Please {addressDetails.length ? "select" : "add"} your address and proceed to payment
              </span>
              <div className="step-content">
                <div style={{ maxHeight: "35vh", overflowY: "scroll", width: "98%" }}>
                  {addressDetails.map((address, index) => {
                    return <AddressEntry key={index} addressDetails={address} />;
                  })}
                </div>
                <div className="notify-wrap add-address-section wrap-new-address" style={{ padding: "0px" }}>
                  <div className="img-wrap add-new">
                    {/* <img src={makePublicURL("img/address.png")} alt="" className="address-img" /> */}
                    <i className="fa fa-map-marker" aria-hidden="true" style={{ fontSize: 40, color: "#344254" }}></i>
                  </div>
                  <div className="description">
                    <span className="title">Add New Address</span>
                    <span className="subtitle">You can add a new address here.</span>
                    <span
                      onClick={handleChangeAddressBackBtnClick}
                      ref={changeAddressBackBtnRef}
                      className="cart-title back-btn change-address-btn changeAddressBtn"
                    >
                      <img src={makePublicURL("img/back-arrow.png")} alt="" className="back-arrow" />
                      Change address
                    </span>
                    {/* <button
                    ref={showModalMobileRef}
                    type="button"
                    className="form-btn green-btn modalBtn"
                    data-toggle="modal"
                    data-direction="left"
                    data-target="#mapModal"
                    onClick={showModal}
                  >
                    Add Address
                  </button> */}
                  </div>
                </div>
              </div>
              <div className="wrap-btn">
                <button ref={deliverAddressJsRef} type="button" className="form-btn deliverAddressJs">
                  Deliver to this address
                </button>
              </div>
            </div>
            <div ref={selectAddressBtnSectionRef} id="selectAddressButtonSection" className="d-none my-2">
              <p>
                <i
                  className="fa fa-exclamation"
                  aria-hidden="true"
                  style={{ fontSize: 20, paddingRight: 4, color: "rgb(242, 86, 86)" }}
                ></i>
                Highlighted items are outside of servicable area.
              </p>
              <div className="wrap-btn btn-total totalBtn">
                <button
                  className="form-btn green-btn proceed-btn proceedPayJs"
                  style={{ marginRight: "3%" }}
                  onClick={hideAddressButtonSection}
                  // style={{ paddingRight: 2 }}
                >
                  Change Address
                </button>

                <button
                  className="form-btn green-btn proceed-btn proceedPayJs"
                  style={{ backgroundColor: "#38a176" }}
                  onClick={onRemoveProducts}
                  disabled={removeProductLoading}
                >
                  {removeProductLoading ? (
                    <i className="fa fa-circle-o-notch fa-spin ml-2" style={{ marginRight: 10 }}></i>
                  ) : (
                    `Remove Items (${itemWithError})`
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="stepChooseDeliveryJs step-delivery">
            <span className="step-content">
              {/* <img src={makePublicURL("img/address.png")} alt="" /> */}
              <i className="fa fa-map-marker" aria-hidden="true" style={{ fontSize: 40, color: "#344254" }}></i>

              <span className="address-wrap">
                <span className="address-title chooseTitleJs">
                  {addressSelector.selectedAddress ? addressSelector.selectedAddress.fullName : "Address not selected"}
                </span>
                <span className="subtitle chooseSubtitleJs">
                  {addressSelector.selectedAddress ? addressString : "Address not selected"}
                </span>
                <span className="delivery-description chooseDeliveryJs">
                  {outstation ? "Ships in 2 days. Delivery in 4-5 days." : "Delivery in 1-3 days."}
                </span>
              </span>
              <a ref={changeAddressRef} className="change-btn changeAddressJs" onClick={changeAddressRefHandleClick}>
                Change
              </a>
            </span>
            <div className="wrap-btn btn-total totalBtn">
              <button className="form-btn total-btn">
                <span className="price text-left">
                  {currency} {isMobile ? finalTotalPrice - paymentCharges : finalTotalPrice}
                </span>
                <span className="price-description text-left">Total bill amount</span>
              </button>
              <button onClick={handleProceedToPayClick} className="form-btn green-btn proceed-btn proceedPayJs">
                Proceed to pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
