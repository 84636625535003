import {
  ADD_ADDRESS,
  SELECTED_ADDRESS,
  GET_EXISTING_ADDRESS,
  CANDIDATE_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  REVIEW_CART,
  TOGGLE_ADDRESS_SELECTION_TRUE,
  TOGGLE_ADDRESS_SELECTION_FALSE,
  INTERESTED_ADDRESS,
  SET_FIRST_MOUNT_ADDRESS,
  UPDATE_ADDRESS_LOADING,
  GET_LOCAL_ADDRESS,
  ADD_ADDRESS_LOADING,
} from "./actions";

const addressInitialState = {
  addressDetails: [
    // {
    // 	fullName: "Mah",
    // 	mobileNumber: "9932272424",
    // 	pincode: "456123",
    // 	addressType: "Home",
    // 	apartmentName: "XYZ",
    // 	addressLocation: "XYZ",
    // 	addressLandmark: "XYZ",
    // 	deliveryInfo: "",
    // 	city: "Delhi",
    // 	state: "Delhi",
    // 	lat: "",
    // 	lng: "",
    // },
  ],
  selectedAddress: null,
  candidateAddress: null,
  interestedAddress: null,
  reviewCart: 0,
  loading: true,
  loadingAddAddress: false,
  canAddressBeSelected: true,
  firstMountAddress: null,
};

const formatAddress = (addressData) => {
  const addresses = addressData.map((item) => {
    if (item.address2 && item.address2 !== "null" && item.address2.split("|").length > 1) {
      const newAddressData = item.address2.split("|");
      let addressSplitData = {};
      newAddressData.forEach((item) => {
        let tempData = item.trim().split("=");
        addressSplitData[tempData[0]] = tempData[1].trim();
      });

      addressSplitData.addressID = item.id;
      addressSplitData.default = item.default;
      addressSplitData.country = "India";
      addressSplitData.pincode = item.zip;
      addressSplitData.mobileNumber = item.phone;
      addressSplitData.fullName = item.name;
      addressSplitData.state = item.province ? item.province : "";
      addressSplitData.city = item.city;
      addressSplitData.apartmentName = item.address1;
      addressSplitData.zip = item.zip;
      addressSplitData.editAddressData = item.edit_address_data;
      return addressSplitData;
    }
    return {
      // addressName: item.name,
      default: item.default,
      alternateMobileNumber: item.alternate_phone_number !== "+91" && item.alternate_phone_number || '',
      addressStreet:  '',
      addressType: item.is_home_address ? "Home" : "Work",
      addressLocation: `${item.address1} ${item.address2}`,
      zip: item.zip,
      city: item.city,
      country: "India",
      pincode: item.zip,
      landmark: item.landmark,
      // deliveryInfo: "Out of service area",
      fullName: item.name,
      mobileNumber: item.phone,
      apartmentName: '',
      state: item.province ? item.province : "",
      addressID: item.id,
      editAddressData: item.edit_address_data,
    };
  });
  return addresses;
};

export const addressReducer = (state = addressInitialState, action) => {
  switch (action.type) {
    case ADD_ADDRESS:
      return {
        ...state,
        addressDetails: [...state.addressDetails, action.payload],
      };
    case ADD_ADDRESS_LOADING:
      return {
        ...state,
        loadingAddAddress: action.payload.loading,
      }
    case UPDATE_ADDRESS:
      let updatedAddressDetails = state.addressDetails.map((address) => {
        if (address.addressID == action.payload.addressID) {
          return action.payload;
        }
        return address;
      });
      return {
        ...state,
        addressDetails: updatedAddressDetails,
      };
    case DELETE_ADDRESS:
      let addressesAfterDelete = state.addressDetails.filter((address) =>
        address.addressID != action.payload.addressID
      );
      return {
        ...state,
        addressDetails: addressesAfterDelete,
      };
    case SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: action.payload,
      };
    case INTERESTED_ADDRESS:
      return {
        ...state,
        interestedAddress: action.payload,
      };
    case CANDIDATE_ADDRESS:
      return {
        ...state,
        candidateAddress: action.payload,
      };
    case SET_FIRST_MOUNT_ADDRESS:
      return {
        ...state,
        firstMountAddress: action.payload,
      };
    case GET_EXISTING_ADDRESS:
      return {
        ...state,
        addressDetails: [...state.addressDetails, ...formatAddress(action.payload)],
      };
    case GET_LOCAL_ADDRESS:
      return {
        ...state,
        addressDetails: [...state.addressDetails, ...action.payload],
      };
    case REVIEW_CART:
      return {
        ...state,
        reviewCart: Math.random(5),
      };
    case TOGGLE_ADDRESS_SELECTION_FALSE:
      return {
        ...state,
        canAddressBeSelected: false,
      };
    case TOGGLE_ADDRESS_SELECTION_TRUE:
      return {
        ...state,
        canAddressBeSelected: true,
      };
    case UPDATE_ADDRESS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
