import React, { useCallback, useEffect, useRef } from "react";
import Header from "./Header";

export default function Layout(props) {


	return (
		<div>
			<div>
				<Header />
			</div>
			<div>{props.children}</div>
		</div >
	);
}
